import ActionTypes from '../../constants/ActionTypes';
import type { Profession } from '../../types/Profession';

export interface ProjectCreateFormState {
  readonly data: {
    readonly name: string;
    readonly subtitle: string;
    readonly professions: readonly Profession[];
  };
  readonly error: any;
  readonly isSubmitting: boolean;
}

const initialState: ProjectCreateFormState = {
  data: {
    name: '',
    subtitle: '',
    professions: [],
  },
  error: null,
  isSubmitting: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }): ProjectCreateFormState => {
  switch (type) {
    case ActionTypes.PROJECT_CREATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
        error: null,
      };

    case ActionTypes.PROJECT_CREATE__SUCCESS:
      return initialState;
    case ActionTypes.PROJECT_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
