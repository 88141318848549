import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api/index';
import request from '../../request';

export function* getScheduledToBeDeletedProjects() {
  yield put(actions.getScheduledToBeDeletedProjects());
  try {
    const items = yield call(request, api.getScheduledToBeDeletedProjects);

    yield put(actions.getScheduledToBeDeletedProjects.success(items));
  } catch (error) {
    yield put(actions.getScheduledToBeDeletedProjects.failure(error));
  }
}
