import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Card from '../components/Card';
import entryActions from '../entry-actions';
import selectors from '../selectors';

const makeMapStateToProps = () => {
  const selectCardById = selectors.makeSelectCardById();
  const selectUsersByCardId = selectors.makeSelectUsersByCardId();

  return (state, { id, index }) => {
    const { projectId } = selectors.selectPath(state);
    const allProjectsToLists = selectors.selectProjectsToListsForCurrentUser(state);
    const allBoardMemberships = selectors.selectMembershipsForCurrentBoard(state);
    const {
      name,
      boardId,
      listId,
      isPersisted,
      description,
      embedUrl,
      ogTitle,
      ogDescription,
      ogImage,
      mediaUrl,
      backgroundColor,
      coverUrl,
      scheduledToBeVisibleAt,
    } = selectCardById(state, id);

    const users = selectUsersByCardId(state, id);
    const canEdit = selectors.selectCanEditBoard(state);
    return {
      id,
      index,
      name,
      description,
      boardId,
      listId,
      projectId,
      isPersisted,
      users,
      allProjectsToLists,
      allBoardMemberships,
      canEdit,
      ogTitle,
      ogDescription,
      ogImage,
      embedUrl,
      backgroundColor,
      mediaUrl,
      mediaPreviewUrl: coverUrl || mediaUrl || embedUrl,
      mediaLinkUrl: mediaUrl,
      scheduledToBeVisible:
        scheduledToBeVisibleAt && Date.now() < Date.parse(scheduledToBeVisibleAt),
    };
  };
};

const mapDispatchToProps = (dispatch, { id }) =>
  bindActionCreators(
    {
      onUpdate: (data) => entryActions.updateCard(id, data),
      onMove: (listId, index) => entryActions.moveCard(id, listId, index),
      onTransfer: (boardId, listId) => entryActions.transferCard(id, boardId, listId),
      onDelete: () => entryActions.deleteCard(id),
      onBoardFetch: entryActions.fetchBoard,
      openMediaPreviewModal: entryActions.openMediaPreviewModal,
    },
    dispatch,
  );

export default connect(makeMapStateToProps, mapDispatchToProps)(Card);
