import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

function BookmarkButton({
  id,
  favorite,
  addBookmark,
  removeBookmark,
  className,
}: {
  favorite: boolean;
  id: string;
  addBookmark: (id: string) => void;
  removeBookmark: (id: string) => void;
  className?: string;
}) {
  const [t] = useTranslation();
  const handleBookmarkClick = (event: any) => {
    event.preventDefault();
    const fn = favorite ? removeBookmark : addBookmark;
    fn(id);
  };
  const title = t(favorite ? 'common.dontBookmarkBoard' : 'common.bookmarkBoard');
  return (
    <button
      className={classNames('w-8 h-8 flex items-center justify-center', className)}
      onClick={handleBookmarkClick}
      type="button"
      title={title}
      aria-label={title}
    >
      <img
        src={
          favorite ? '/assets/images/icon-bookmark-active.svg' : '/assets/images/icon-bookmark.svg'
        }
        alt=""
      />
    </button>
  );
}

export default React.memo(BookmarkButton);
