/* eslint-disable jsx-a11y/media-has-caption */

function MediaPreviewVideo({ url, interactive = true }: { url: string; interactive?: boolean }) {
  return (
    <div className="contain-content" data-testid="MediaPreviewVideo">
      <video src={url} controls={interactive} />
    </div>
  );
}

export default MediaPreviewVideo;
