function ContextMenuItemIcon({ icon }: { icon: string }) {
  const maskUrl = `url("${icon}")`;
  return (
    <span className="w-6 h-6 contain-strict text-blue100">
      <div
        style={{
          maskImage: maskUrl,
          WebkitMaskImage: maskUrl,
          background: 'currentColor',
          maskRepeat: 'no-repeat',
          maskPosition: '50% 50%',
          maskSize: '100% 100%',
        }}
        className="w-full h-full "
      />
    </span>
  );
}

export default ContextMenuItemIcon;
