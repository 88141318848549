import { call, select } from 'redux-saga/effects';
import { goToSlideshow } from './router';
import selectors from '../../../selectors';

// eslint-disable-next-line import/prefer-default-export
export function* handleSlideshowIndexUpdate(index) {
  const board = yield select(selectors.selectCurrentBoard);
  const project = yield select(selectors.selectCurrentProject);

  yield call(goToSlideshow, board.id, project.name, index);
}

export default { handleSlideshowIndexUpdate };
