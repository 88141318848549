/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import React from 'react';
import { Loader } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType';

const buttonStyles: Record<ButtonType, string> = {
  [ButtonType.Green]: 'bg-green100 text-white',
  [ButtonType.Orange]: 'bg-orange text-white',
  [ButtonType.Blue]: 'bg-blue100 text-white',
  [ButtonType.LightGreen]: 'bg-green30 text-green100',
  [ButtonType.LightBlue]: 'bg-blue10 text-white',
  [ButtonType.OutlineGreen]: 'text-green100 border-2 border-green100',
  [ButtonType.LightOutlineGreen]: 'bg-white text-green50 border-2 border-green30',
};

const buttonHoverStyles: Record<ButtonType, string> = {
  [ButtonType.Green]: 'hover:bg-green50',
  [ButtonType.Orange]: 'hover:bg-orangeLight',
  [ButtonType.Blue]: 'hover:bg-blue50',
  [ButtonType.LightGreen]: 'hover:bg-green10 hover:text-green100',
  [ButtonType.LightBlue]: 'hover:bg-blue30 text-white',
  [ButtonType.OutlineGreen]: 'hover:text-green50',
  [ButtonType.LightOutlineGreen]: 'hover:text-green10',
};

const buttonDisabledStyles: Record<ButtonType, string> = {
  [ButtonType.Green]: 'bg-green50 text-white',
  [ButtonType.Orange]: 'bg-orange text-white',
  [ButtonType.Blue]: 'bg-blue50 text-white',
  [ButtonType.LightGreen]: 'bg-green10 text-green100',
  [ButtonType.LightBlue]: 'bg-blue30 text-white',
  [ButtonType.OutlineGreen]: 'text-green50',
  [ButtonType.LightOutlineGreen]: 'text-green10',
};

const invertedLoader: Record<ButtonType, boolean> = {
  [ButtonType.Green]: true,
  [ButtonType.Orange]: true,
  [ButtonType.Blue]: true,
  [ButtonType.LightGreen]: false,
  [ButtonType.LightBlue]: false,
  [ButtonType.OutlineGreen]: false,
  [ButtonType.LightOutlineGreen]: false,
};

function Button({
  variant,
  disabled,
  children,
  onClick,
  onMouseOver,
  onMouseOut,
  type,
  ariaLabel,
  className,
  loading,
  paddingSmall,
  dataTestId,
}: {
  type?: 'button' | 'submit';
  variant: ButtonType;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: (event: any) => void;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  ariaLabel?: string;
  className?: string;
  loading?: boolean;
  paddingSmall?: boolean;
  dataTestId?: string;
}) {
  return (
    <button
      type={type}
      className={classNames(
        'flex justify-center items-center rounded-sm font-semibold relative',
        disabled ? buttonDisabledStyles[variant] : buttonStyles[variant],
        disabled ? buttonDisabledStyles[variant] : buttonHoverStyles[variant],
        paddingSmall ? 'p-2' : 'px-8 py-3.5',
        className,
      )}
      onClick={onClick}
      data-testid={dataTestId || 'Button'}
      disabled={disabled}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      aria-label={ariaLabel}
    >
      {loading ? (
        <>
          <Loader active size="tiny" inverted={invertedLoader[variant]} />
          {/* add zero width space to keep height in loading state */}
          &#8203;
        </>
      ) : (
        children
      )}
    </button>
  );
}

export default Button;
