import { getPathName } from './getPathName';

const getFileExtension = (pathName: string) => {
  const dotIndex = pathName.lastIndexOf('.');
  if (dotIndex === -1) {
    return '';
  }
  return pathName.slice(dotIndex).toLowerCase();
};

/**
 *
 * @param url - the url
 * @param fileExtensions - a list of file extensions. Must include dot.
 * @returns {boolean}
 */
export const hasFileExtension = (url: string, fileExtensions: readonly string[]): boolean => {
  const pathName = getPathName(url);
  if (!pathName) {
    return false;
  }
  const extension = getFileExtension(pathName);
  return fileExtensions.includes(extension);
};
