import attachments from './attachments';
import boardMemberships from './board-memberships';
import boards from './boards';
import cards from './cards';
import consent from './consent';
import core from './core';
import error from './error';
import lists from './lists';
import modals from './modals';
import projectManagers from './project-managers';
import projects from './projects';
import router from './router';
import search from './search';
import slideshow from './slideshow';
import socket from './socket';
import users from './users';

export default [
  router,
  socket,
  core,
  modals,
  users,
  projects,
  projectManagers,
  boards,
  boardMemberships,
  lists,
  cards,
  attachments,
  slideshow,
  search,
  consent,
  error,
];
