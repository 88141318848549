import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* deleteProjectThumbnail(id, data) {
  yield put(actions.deleteProjectThumbnail(id));
  let project;
  try {
    ({ item: project } = yield call(request, api.deleteProjectThumbnail, id, data));
  } catch (error) {
    yield put(actions.deleteProjectThumbnail.failure(id, error));
    return;
  }
  yield put(actions.deleteProjectThumbnail.success(project));
}
