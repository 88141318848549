import { all, takeEvery } from 'redux-saga/effects';
import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

export default function* coreWatchers() {
  yield all([
    takeEvery(EntryActionTypes.LOGOUT, () => services.logout()),
    takeEvery(EntryActionTypes.HANDLE_RESIZE, () => services.handleResize()),
  ]);
}
