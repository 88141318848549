import ActionTypes from '../../constants/ActionTypes';

export const createBookmark = (id) => ({
  type: ActionTypes.PROJECT_BOOKMARK_CREATE,
  payload: {
    id,
  },
});

createBookmark.success = (bookmark) => ({
  type: ActionTypes.PROJECT_BOOKMARK_CREATE__SUCCESS,
  payload: {
    bookmark,
  },
});

createBookmark.failure = (error) => ({
  type: ActionTypes.PROJECT_BOOKMARK_CREATE__FAILURE,
  payload: {
    error,
  },
});
