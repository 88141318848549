import ActionTypes from '../constants/ActionTypes';

const initializeCore = (
  user,
  board,
  users,
  projects,
  projectManagers,
  boards,
  boardMemberships,
  lists,
  cards,
  attachments,
  professions,
  communityProjects,
  projectProfessions,
  canLoadMoreCommunityProjects,
  projectUserFavorites,
  isMobile,
) => ({
  type: ActionTypes.CORE_INITIALIZE,
  payload: {
    user,
    board,
    users,
    projects,
    projectManagers,
    boards,
    boardMemberships,
    lists,
    cards,
    attachments,
    professions,
    communityProjects,
    projectProfessions,
    canLoadMoreCommunityProjects,
    projectUserFavorites,
    isMobile,
  },
});

// TODO: with success?
initializeCore.fetchConfig = (config) => ({
  type: ActionTypes.CORE_INITIALIZE__CONFIG_FETCH,
  payload: {
    config,
  },
});

initializeCore.globalError = (error) => ({
  type: ActionTypes.GLOBAL_ERROR,
  payload: {
    error,
  },
});

const logout = () => ({
  type: ActionTypes.LOGOUT,
  payload: {},
});

logout.invalidateAccessToken = () => ({
  type: ActionTypes.LOGOUT__ACCESS_TOKEN_INVALIDATE,
  payload: {},
});

const handleResize = (isMobile) => ({
  type: ActionTypes.HANDLE_RESIZE,
  payload: {
    isMobile,
  },
});

export default {
  initializeCore,
  logout,
  handleResize,
};
