import classNames from 'classnames';

function ProjectButton({
  onClick,
  text,
  icon,
  wrapTextOnDesktop,
}: {
  onClick: () => void;
  text: string;
  icon?: string;
  wrapTextOnDesktop?: boolean;
}) {
  return (
    <div className="flex-1 flex">
      <button
        type="button"
        onClick={onClick}
        className="flex-1 flex md:flex-col items-center p-4 md:p-8 justify-center border border-px border-blue10 hover:bg-blue10"
      >
        {icon && <img src={icon} alt="" className="w-6 h-6 contain-strict" />}
        <span className="ml-2 md:mt-2" />
        <span
          className={classNames(
            'font-sans text-green100 font-bold',
            wrapTextOnDesktop ? 'sm:[word-spacing:99999px]' : '',
          )}
        >
          {text}
        </span>
      </button>
    </div>
  );
}

export default ProjectButton;
