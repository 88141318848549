import { MenuItemSmallText } from '../../types/MenuItemType.js';

function ContextMenuItemSmallText({ text }: MenuItemSmallText) {
  return (
    <div className="item !pb-0 truncate" data-testid="ContextMenuItemSmallText">
      <span className="text-sm text-blue100 w-full font-normal">{text}</span>
    </div>
  );
}

export default ContextMenuItemSmallText;
