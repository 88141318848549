/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Profession } from '../../types/Profession';
import { sortProfessions } from '../../utils/sortProfessions.js';
import MultiSelectComboBox from '../MultiSelectComboBox/MultiSelectComboBox.js';

const getLabel = (option: Profession) => {
  return option.name ?? 'n/a';
};

const getId = (option: Profession) => {
  return option.id ?? '';
};

function ProfessionsComboBox({
  data,
  handleFieldChange,
  availableProfessions,
  showPlaceholder = false,
}: {
  data: any;
  handleFieldChange: (event: any, args: any) => void;
  availableProfessions: readonly Profession[];
  showPlaceholder?: boolean;
}) {
  const { t } = useTranslation();
  const setSelectedOptions = (options: readonly Profession[]) => {
    handleFieldChange(null, {
      name: 'professions',
      value: options,
    });
    handleFieldChange(null, {
      name: 'professionsValue',
      value: '',
    });
  };

  const sortedProfessions = sortProfessions(availableProfessions);
  const placeholder = showPlaceholder && data.professions.length === 0 && t('common.all');
  return (
    <MultiSelectComboBox
      options={sortedProfessions}
      selectedOptions={data.professions}
      getId={getId}
      getLabel={getLabel}
      setSelectedOptions={setSelectedOptions}
      value={data.professionsValue}
      setValue={(value) => {
        handleFieldChange(null, {
          name: 'professionsValue',
          value,
        });
      }}
      placeholder={placeholder || undefined}
    />
  );
}

export default React.memo(ProfessionsComboBox);
