const PREFIX = '@entry';

const EntryActionTypes = {
  PREFIX,

  /* Consent */

  CONSENT_ALLOW_MEDIA_PREVIEW: `${PREFIX}/CONSENT_ALLOW_MEDIA_PREVIEW`,

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: `${PREFIX}/SOCKET_DISCONNECT_HANDLE`,
  SOCKET_RECONNECT_HANDLE: `${PREFIX}/SOCKET_RECONNECT_HANDLE`,

  /* Login */

  AUTHENTICATE: `${PREFIX}/AUTHENTICATE`,
  USING_OIDC_AUTHENTICATE: `${PREFIX}/USING_OIDC_AUTHENTICATE`,
  USING_OIDC_REGISTER: `${PREFIX}/USING_OIDC_REGISTER`,
  AUTHENTICATE_ERROR_CLEAR: `${PREFIX}/AUTHENTICATE_ERROR_CLEAR`,

  /* Core */

  LOGOUT: `${PREFIX}/LOGOUT`,

  /* Modals */

  MODAL_OPEN: `${PREFIX}/MODAL_OPEN`,
  MODAL_CLOSE: `${PREFIX}/MODAL_CLOSE`,

  /* Resize */
  HANDLE_RESIZE: `${PREFIX}/HANDLE_RESIZE`,

  /* Users */

  USER_CREATE: `${PREFIX}/USER_CREATE`,
  USER_CREATE_HANDLE: `${PREFIX}/USER_CREATE_HANDLE`,
  USER_CREATE_ERROR_CLEAR: `${PREFIX}/USER_CREATE_ERROR_CLEAR`,
  USER_UPDATE: `${PREFIX}/USER_UPDATE`,
  CURRENT_USER_UPDATE: `${PREFIX}/CURRENT_USER_UPDATE`,
  USER_UPDATE_HANDLE: `${PREFIX}/USER_UPDATE_HANDLE`,
  CURRENT_USER_LANGUAGE_UPDATE: `${PREFIX}/CURRENT_USER_LANGUAGE_UPDATE`,
  USER_EMAIL_UPDATE: `${PREFIX}/USER_EMAIL_UPDATE`,
  CURRENT_USER_EMAIL_UPDATE: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE`,
  USER_EMAIL_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_EMAIL_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_EMAIL_UPDATE_ERROR_CLEAR`,
  USER_PASSWORD_UPDATE: `${PREFIX}/USER_PASSWORD_UPDATE`,
  CURRENT_USER_PASSWORD_UPDATE: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE`,
  USER_PASSWORD_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_PASSWORD_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_PASSWORD_UPDATE_ERROR_CLEAR`,
  USER_USERNAME_UPDATE: `${PREFIX}/USER_USERNAME_UPDATE`,
  CURRENT_USER_USERNAME_UPDATE: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE`,
  USER_USERNAME_UPDATE_ERROR_CLEAR: `${PREFIX}/USER_USERNAME_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR: `${PREFIX}/CURRENT_USER_USERNAME_UPDATE_ERROR_CLEAR`,
  CURRENT_USER_AVATAR_UPDATE: `${PREFIX}/CURRENT_USER_AVATAR_UPDATE`,
  USER_DELETE: `${PREFIX}/USER_DELETE`,
  USER_DELETE_HANDLE: `${PREFIX}/USER_DELETE_HANDLE`,
  USER_TO_CARD_ADD: `${PREFIX}/USER_TO_CARD_ADD`,
  USER_TO_CURRENT_CARD_ADD: `${PREFIX}/USER_TO_CURRENT_CARD_ADD`,
  USER_TO_CARD_ADD_HANDLE: `${PREFIX}/USER_TO_CARD_ADD_HANDLE`,
  USER_FROM_CARD_REMOVE: `${PREFIX}/USER_FROM_CARD_REMOVE`,
  USER_FROM_CURRENT_CARD_REMOVE: `${PREFIX}/USER_FROM_CURRENT_CARD_REMOVE`,
  USER_FROM_CARD_REMOVE_HANDLE: `${PREFIX}/USER_FROM_CARD_REMOVE_HANDLE`,
  USER_TO_FILTER_IN_CURRENT_BOARD_ADD: `${PREFIX}/USER_TO_FILTER_IN_CURRENT_BOARD_ADD`,
  USER_FROM_FILTER_IN_CURRENT_BOARD_REMOVE: `${PREFIX}/USER_FROM_FILTER_IN_CURRENT_BOARD_REMOVE`,

  /* Projects */

  PROJECT_CREATE: `${PREFIX}/PROJECT_CREATE`,
  PROJECT_CREATE_HANDLE: `${PREFIX}/PROJECT_CREATE_HANDLE`,
  CURRENT_PROJECT_UPDATE: `${PREFIX}/CURRENT_PROJECT_UPDATE`,
  CURRENT_PROJECT_DUPLICATE: `${PREFIX}/CURRENT_PROJECT_DUPLICATE`,
  PROJECT_UPDATE_HANDLE: `${PREFIX}/PROJECT_UPDATE_HANDLE`,
  CURRENT_PROJECT_BACKGROUND_IMAGE_UPDATE: `${PREFIX}/CURRENT_PROJECT_BACKGROUND_IMAGE_UPDATE`,
  CURRENT_PROJECT_THUMBNAIL_UPDATE: `${PREFIX}/CURRENT_PROJECT_THUMBNAIL_UPDATE`,
  CURRENT_PROJECT_THUMBNAIL_DELETE: `${PREFIX}/CURRENT_PROJECT_THUMBNAIL_DELETE`,
  CURRENT_PROJECT_DELETE: `${PREFIX}/CURRENT_PROJECT_DELETE`,
  CURRENT_PROJECT_SCHEDULE_FOR_DELETION: `${PREFIX}/CURRENT_PROJECT_SCHEDULE_FOR_DELETION`,
  PROJECT_DELETE_HANDLE: `${PREFIX}/PROJECT_DELETE_HANDLE`,
  LOAD_COMMUNITY_PROJECTS: `${PREFIX}/LOAD_COMMUNITY_PROJECTS`,
  PROJECT_IMPORT_FROM_PADLET: `${PREFIX}/PROJECT_IMPORT_FROM_PADLET`,
  PROJECT_IMPORT_CANCEL: `${PREFIX}/PROJECT_IMPORT_CANCEL`,
  PROJECT_BOOKMARK_CREATE: `${PREFIX}/PROJECT_BOOKMARK_CREATE`,
  PROJECT_BOOKMARK_DELETE: `${PREFIX}/PROJECT_BOOKMARK_DELETE`,
  PROJECT_TRY_ACCESS_PASSWORD: `${PREFIX}/PROJECT_TRY_ACCESS_PASSWORD`,
  PROJECT_GET_SCHEDULED_TO_BE_DELETED: `${PREFIX}/PROJECT_GET_SCHEDULED_TO_BE_DELETED`,
  PROJECT_DELETE: `${PREFIX}/PROJECT_DELETE`,
  PROJECT_RESTORE_FROM_TRASH: `${PREFIX}/PROJECT_RESTORE_FROM_TRASH`,

  /* Project managers */

  MANAGER_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/MANAGER_IN_CURRENT_PROJECT_CREATE`,
  PROJECT_MANAGER_CREATE_HANDLE: `${PREFIX}/PROJECT_MANAGER_CREATE_HANDLE`,
  PROJECT_MANAGER_DELETE: `${PREFIX}/PROJECT_MANAGER_DELETE`,
  PROJECT_MANAGER_DELETE_HANDLE: `${PREFIX}/PROJECT_MANAGER_DELETE_HANDLE`,

  /* Boards */

  BOARD_IN_CURRENT_PROJECT_CREATE: `${PREFIX}/BOARD_IN_CURRENT_PROJECT_CREATE`,
  BOARD_CREATE_HANDLE: `${PREFIX}/BOARD_CREATE_HANDLE`,
  BOARD_FETCH: `${PREFIX}/BOARD_FETCH`,
  BOARD_UPDATE: `${PREFIX}/BOARD_UPDATE`,
  BOARD_UPDATE_HANDLE: `${PREFIX}/BOARD_UPDATE_HANDLE`,
  BOARD_MOVE: `${PREFIX}/BOARD_MOVE`,
  BOARD_DELETE: `${PREFIX}/BOARD_DELETE`,
  BOARD_DELETE_HANDLE: `${PREFIX}/BOARD_DELETE_HANDLE`,
  BOARD_TOGGLE_SCROLL_MODE: `${PREFIX}/BOARD_TOGGLE_SCROLL_MODE`,

  /* Board memberships */

  MEMBERSHIP_IN_CURRENT_BOARD_CREATE: `${PREFIX}/MEMBERSHIP_IN_CURRENT_BOARD_CREATE`,
  BOARD_MEMBERSHIP_CREATE_HANDLE: `${PREFIX}/BOARD_MEMBERSHIP_CREATE_HANDLE`,
  BOARD_MEMBERSHIP_UPDATE: `${PREFIX}/BOARD_MEMBERSHIP_UPDATE`,
  BOARD_MEMBERSHIP_UPDATE_HANDLE: `${PREFIX}/BOARD_MEMBERSHIP_UPDATE_HANDLE`,
  BOARD_MEMBERSHIP_DELETE: `${PREFIX}/BOARD_MEMBERSHIP_DELETE`,
  BOARD_MEMBERSHIP_DELETE_HANDLE: `${PREFIX}/BOARD_MEMBERSHIP_DELETE_HANDLE`,

  /* Lists */

  LIST_IN_CURRENT_BOARD_CREATE: `${PREFIX}/LIST_IN_CURRENT_BOARD_CREATE`,
  LIST_CREATE_HANDLE: `${PREFIX}/LIST_CREATE_HANDLE`,
  LIST_UPDATE: `${PREFIX}/LIST_UPDATE`,
  LIST_UPDATE_HANDLE: `${PREFIX}/LIST_UPDATE_HANDLE`,
  LIST_MOVE: `${PREFIX}/LIST_MOVE`,
  LIST_DELETE: `${PREFIX}/LIST_DELETE`,
  LIST_DELETE_HANDLE: `${PREFIX}/LIST_DELETE_HANDLE`,

  /* Cards */

  CARD_CREATE: `${PREFIX}/CARD_CREATE`,
  CARD_CREATE_HANDLE: `${PREFIX}/CARD_CREATE_HANDLE`,
  CARD_UPDATE: `${PREFIX}/CARD_UPDATE`,
  CURRENT_CARD_UPDATE: `${PREFIX}/CURRENT_CARD_UPDATE`,
  CARD_MODAL_CLOSE: `${PREFIX}/CARD_MODAL_CLOSE`,
  CARD_MODAL_DISCARD_AND_CLOSE: `${PREFIX}/CARD_MODAL_DISCARD_AND_CLOSE`,
  CARD_UPDATE_HANDLE: `${PREFIX}/CARD_UPDATE_HANDLE`,
  CARD_MOVE: `${PREFIX}/CARD_MOVE`,
  CURRENT_CARD_MOVE: `${PREFIX}/CURRENT_CARD_MOVE`,
  CARD_TRANSFER: `${PREFIX}/CARD_TRANSFER`,
  CURRENT_CARD_TRANSFER: `${PREFIX}/CURRENT_CARD_TRANSFER`,
  CARD_DELETE: `${PREFIX}/CARD_DELETE`,
  CURRENT_CARD_DELETE: `${PREFIX}/CURRENT_CARD_DELETE`,
  CARD_DELETE_HANDLE: `${PREFIX}/CARD_DELETE_HANDLE`,
  CARD_DUPLICATE: `${PREFIX}/CARD_DUPLICATE`,

  /* Attachments */

  ATTACHMENT_IN_CURRENT_CARD_CREATE: `${PREFIX}/ATTACHMENT_IN_CURRENT_CARD_CREATE`,
  ATTACHMENT_CREATE_HANDLE: `${PREFIX}/ATTACHMENT_CREATE_HANDLE`,
  ATTACHMENT_UPDATE: `${PREFIX}/ATTACHMENT_UPDATE`,
  ATTACHMENT_UPDATE_HANDLE: `${PREFIX}/ATTACHMENT_UPDATE_HANDLE`,
  ATTACHMENT_DELETE: `${PREFIX}/ATTACHMENT_DELETE`,
  ATTACHMENT_DELETE_HANDLE: `${PREFIX}/ATTACHMENT_DELETE_HANDLE`,

  /* Professions */

  PROFESSION_TO_PROJECT_ADD: `${PREFIX}/PROFESSION_TO_PROJECT_ADD`,
  PROFESSION_FROM_PROJECT_REMOVE: `${PREFIX}/PROFESSION_FROM_PROJECT_REMOVE`,

  /* Home Page */
  PROJECT_FILTER_UPDATE: `${PREFIX}/PROJECT_FILTER_UPDATE`,

  /* Slideshow */
  SLIDESHOW_INDEX_UPDATE: `${PREFIX}/SLIDESHOW_INDEX_UPDATE`,

  SEARCH: `${PREFIX}/SEARCH`,
  SEARCH_LOAD_MORE: `${PREFIX}/SEARCH_LOAD_MORE`,
  SEARCH_VALUE_UPDATE: `${PREFIX}/SEARCH_VALUE_UPDATE`,
  SEARCH_EXPAND: `${PREFIX}/SEARCH_EXPAND`,
  SEARCH_COLLAPSE: `${PREFIX}/SEARCH_COLLAPSE`,
} as const;

export default EntryActionTypes;

export interface SlideShowIndexUpdate {
  type: typeof EntryActionTypes.SLIDESHOW_INDEX_UPDATE;
  payload: { index: number };
}
