/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import type { IProjectListItem } from '../../types/IProjectListItem';
import Heading from '../Heading/Heading';
import ProjectsList from '../ProjectsList/ProjectsList';

function FavoriteProjects({
  items,
  addBookmark,
  removeBookmark,
}: {
  items: readonly IProjectListItem[];
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
}) {
  const [t] = useTranslation();
  return (
    <section className="mb-4">
      <Heading text={t('common.savedBoards')} />

      <div className="mt-2" />
      <ProjectsList
        items={items}
        addBookmark={addBookmark}
        removeBookmark={removeBookmark}
        showBookmarks
      />
    </section>
  );
}

export default FavoriteProjects;
