import { attr } from 'redux-orm';
import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

export default class extends BaseModel {
  static modelName = 'Profession';

  static fields = {
    id: attr(),
    name: attr(),
  };

  static reducer({ type, payload }, Profession) {
    switch (type) {
      case ActionTypes.CORE_INITIALIZE:
        if (payload.professions) {
          payload.professions.forEach((profession) => {
            Profession.upsert(profession);
          });
        }
        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Profession.all().delete();
        if (payload.professions) {
          payload.professions.forEach((profession) => {
            Profession.upsert(profession);
          });
        }
        break;
      default:
        break;
    }
  }
}
