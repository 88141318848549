import attachments from './attachments';
import boardMemberships from './board-memberships';
import * as boards from './boards';
import * as cards from './cards';
import core from './core';
import lists from './lists';
import * as modals from './modals';
import professions from './professions';
import projectManagers from './project-managers';
import * as projects from './projects';
import * as root from './root';
import * as router from './router';
import * as slideshow from './slideshow';
import socket from './socket';
import * as users from './users';

export default {
  ...router,
  ...socket,
  ...root,
  ...core,
  ...modals,
  ...users,
  ...projects,
  ...projectManagers,
  ...boards,
  ...boardMemberships,
  ...lists,
  ...cards,
  ...attachments,
  ...professions,
  ...slideshow,
};
