import { call, select } from 'redux-saga/effects';
import { deleteProjectNoConfirm } from './deleteProject';
import { scheduleProjectForDeletion } from './scheduleProjectForDeletion';
import core from '../../../../locales/de/core';
import selectors from '../../../../selectors';
import { simpleConfirm } from '../../../../utils/simpleAlert';

const ActionType = {
  ScheduleForDeletion: 1,
  FinallyDelete: 2,
};

const getAction = (user: any, project: any) => {
  if (user && user.isAdmin && project.creatorUserId !== user.id) {
    return ActionType.FinallyDelete;
  }
  return ActionType.ScheduleForDeletion;
};

const getMessage = (actionType: number) => {
  switch (actionType) {
    case ActionType.FinallyDelete:
      return core.translation.common.areYouSureYouWantToDeleteThisBoardAsAdmin;
    case ActionType.ScheduleForDeletion:
      return core.translation.common.areYouSureYouWantToScheduleThisBoardForDeletion;
    default:
      throw new Error('unknown action');
  }
};

const getFn = (actionType: number) => {
  switch (actionType) {
    case ActionType.FinallyDelete:
      return deleteProjectNoConfirm;
    case ActionType.ScheduleForDeletion:
      return scheduleProjectForDeletion;
    default:
      throw new Error('unknown action');
  }
};
export function* scheduleCurrentProjectForDeletion() {
  const user = yield select(selectors.selectCurrentUser);
  const project = yield select(selectors.selectCurrentProject);
  const actionType = getAction(user, project);
  const message = getMessage(actionType);
  const response = simpleConfirm(message);
  if (!response) {
    return;
  }
  const { projectId } = yield select(selectors.selectPath);
  const fn = getFn(actionType);
  yield call(fn, projectId);
}
