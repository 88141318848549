import { useTranslation } from 'react-i18next';
import type { IProjectListItem } from '../../types/IProjectListItem';
import ProjectCanLoadMoreButton from '../ProjectCanLoadMore/ProjectCanLoadMoreButton';
import ProjectCreateButton from '../ProjectCreateButton/ProjectCreateButton';
import ProjectListItem from '../ProjectListItem/ProjectListItem';

function ProjectsList({
  items,
  canAdd,
  onAdd,
  canLoadMore,
  onLoadMore,
  loadMoreError,
  addBookmark,
  removeBookmark,
  showBookmarks,
  menuItems,
}: {
  items: readonly IProjectListItem[];
  canAdd?: boolean;
  onAdd?: () => void;
  canLoadMore?: boolean;
  onLoadMore?: () => void;
  loadMoreError?: boolean;
  addBookmark?: (projectId: string) => void;
  removeBookmark?: (projectId: string) => void;
  showBookmarks?: boolean;
  menuItems?: { label: string; action: (projectId: string) => void }[];
}) {
  const [t] = useTranslation();

  return (
    <div className="grid gap-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:gap-4 xl:grid-cols-4 xl:gap-6">
      {items.map((item) => {
        return (
          <ProjectListItem
            key={item.id}
            item={item}
            showBookmark={showBookmarks}
            addBookmark={addBookmark}
            removeBookmark={removeBookmark}
            menuItems={menuItems}
          />
        );
      })}
      {canAdd && <ProjectCreateButton onClick={onAdd} />}
      {loadMoreError ? (
        <div className="bg-orange text-white p-2 mt-2 flex text-center items-center">
          {t('action.loadMoreError')}
        </div>
      ) : (
        canLoadMore && <ProjectCanLoadMoreButton onClick={onLoadMore} />
      )}
    </div>
  );
}

export default ProjectsList;
