import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoardDuplicateModal from '../components/BoardDuplicateModal/BoardDuplicateModal';
import Paths from '../constants/Paths';
import entryActions from '../entry-actions/index';
import { push } from '../lib/redux-router/index';
import selectors from '../selectors/index';

const mapStateToProps = (state) => {
  const {
    ui: {
      projectDuplicateForm: { isSubmitting, error },
    },
  } = state;
  const { projectId, boardId, projectName } = selectors.selectPath(state);
  const defaultData = {
    name: projectName,
  };
  return {
    projectId,
    boardId,
    isBoardAccess: true,
    defaultData,
    isSubmitting,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      onDuplicate: entryActions.duplicateCurrentProject,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onClose: () => dispatchProps.push(Paths.BOARDS.replace(':id', stateProps.boardId)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BoardDuplicateModal);
