import ActionTypes from '../../constants/ActionTypes';

export const loadCommunityProjects = (page: number, professionIds: string[]) => ({
  type: ActionTypes.LOAD_COMMUNITY_PROJECTS,
  payload: {
    page,
    professionIds,
  },
});

loadCommunityProjects.success = (
  communityProjects: any[],
  canLoadMoreCommunityProjects: boolean,
  boards: any[],
  projectManagers: any[],
  boardMemberships: any[],
  page: number,
) => ({
  type: ActionTypes.LOAD_COMMUNITY_PROJECTS__SUCCESS,
  payload: {
    communityProjects,
    canLoadMoreCommunityProjects,
    boards,
    projectManagers,
    boardMemberships,
    page,
  },
});

loadCommunityProjects.failure = (error) => ({
  type: ActionTypes.LOAD_COMMUNITY_PROJECTS__FAILURE,
  payload: {
    error,
  },
});
