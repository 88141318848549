import * as Sentry from '@sentry/react';
import Flags from './constants/Flags.js';
import { eventBus } from './utils/eventBus.js';

if (Flags.SENTRY_ENABLED) {
  Sentry.init({
    dsn: 'https://9ab9206b6f925fdcb1f8c82ad7e890b9@sentry.sprylab.dev/3',
    integrations: [Sentry.browserTracingIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  eventBus.addEventListener('mikado:error', (event) => {
    if (event instanceof ErrorEvent) {
      Sentry.captureException(event.error);
    }
  });
}
