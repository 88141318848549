import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem';

function TrashActionsStep({
  projectId,
  menuItems,
  onClose,
}: {
  projectId: string;
  menuItems: { label: string; action: (projectId: string) => void }[];
  onClose: () => void;
}) {
  return (
    <ContextMenu>
      {menuItems.map((item) => (
        <ContextMenuItem
          key={item.label}
          onClick={() => {
            item.action(projectId);
            onClose();
          }}
        >
          {item.label}
        </ContextMenuItem>
      ))}
    </ContextMenu>
  );
}

export default TrashActionsStep;
