import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  canLoadMore: false,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CORE_INITIALIZE:
    case ActionTypes.LOAD_COMMUNITY_PROJECTS__SUCCESS:
      return {
        ...state,
        canLoadMore: payload.canLoadMoreCommunityProjects,
      };
    case ActionTypes.LOAD_COMMUNITY_PROJECTS__FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    case ActionTypes.PROJECT_FILTER_UPDATE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
