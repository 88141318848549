import ActionTypes from '../../constants/ActionTypes';
import { getBoardPath } from '../../constants/Paths.js';

const initialState = {
  data: {
    url: '',
    requestId: '',
  },
  isSubmitting: false,
  isSuccess: false,
  successUrl: '',
  error: null,
};

const getSuccessUrl = (payload) => {
  const { boards, project } = payload;
  const [board] = boards;
  const url = getBoardPath(project.name, board.id);
  return url;
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.LOCATION_CHANGE_HANDLE:
      return initialState;
    case ActionTypes.PROJECT_IMPORT:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        isSubmitting: true,
        error: null,
      };
    case ActionTypes.PROJECT_IMPORT__SUCCESS:
      return {
        ...state,
        isSuccess: true,
        isSubmitting: false,
        error: null,
        successUrl: getSuccessUrl(payload),
      };
    case ActionTypes.PROJECT_IMPORT__FAILURE:
    case ActionTypes.PROJECT_IMPORT_CANCEL__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    case ActionTypes.PROJECT_IMPORT_CANCEL__SUCCESS:
    case ActionTypes.PROJECT_IMPORT_CANCEL:
      return initialState;
    default:
      return state;
  }
};
