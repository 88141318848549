import { merge } from 'lodash-es';
import core from './core';
import login from './login';

export default {
  language: 'en',
  country: 'gb',
  name: 'English',
  embeddedLocale: merge(login, core),
};
