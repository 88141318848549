import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoardPasswordModal from '../components/BoardPasswordModal/BoardPasswordModal';
import Paths from '../constants/Paths';
import entryActions from '../entry-actions/index';
import { push } from '../lib/redux-router/index';
import selectors from '../selectors/index';
import { getPlainTextPassword } from '../utils/plainTextPassword.js';

const mapStateToProps = (state) => {
  const {
    ui: {
      projectPasswordForm: { isSubmitting, error },
    },
  } = state;
  const { projectId, boardId } = selectors.selectPath(state);
  const defaultData = {
    plainTextPassword: getPlainTextPassword(boardId),
  };
  return {
    projectId,
    boardId,
    defaultData,
    isSubmitting,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      tryAccess: entryActions.tryAccessPassword,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onClose: () => dispatchProps.push(Paths.BOARDS.replace(':id', stateProps.boardId)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BoardPasswordModal);
