import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoardAccessModal from '../components/BoardAccessModal/BoardAccessModal';
import Paths from '../constants/Paths';
import entryActions from '../entry-actions/index.js';
import { push } from '../lib/redux-router';
import selectors from '../selectors';

const mapStateToProps = (state) => {
  const {
    ui: {
      projectAccessForm: { isSubmitting, error },
    },
  } = state;
  const { projectId, boardId } = selectors.selectPath(state);
  const project = selectors.selectCurrentProject(state);
  const managers = selectors.selectManagersForCurrentProject(state);
  const managerEmails = managers.map((manager) => manager.user.email);
  const defaultData = {
    isPublic: project.isPublic,
    hasPassword: Boolean(project.plainTextPassword),
    plainTextPassword: project.plainTextPassword || null,
    emails: managerEmails,
  };
  return {
    projectId,
    boardId,
    isBoardAccess: true,
    defaultData,
    isSubmitting,
    error,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      onUpdate: entryActions.updateCurrentProject,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onClose: () => dispatchProps.push(Paths.BOARDS.replace(':id', stateProps.boardId)),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BoardAccessModal);
