import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { JOINER } from '../../constants/MaxProfessions.js';
import { getBoardPath } from '../../constants/Paths';
import { getProjectThumbnailUrl } from '../../utils/getProjectThumbnailUrl';
import SearchResultHighlighter from '../SearchResultHighlighter/SearchResultHighlighter';
import TruncatedProfessions from '../TruncatedProfessions/TruncatedProfessions.js';

function SearchResult({
  item,
}: {
  item: {
    id: string;
    firstBoardId: string;
    name: string;
    highlightedName: string;
    highlightedSubtitle: string;
    highlightedProfessions: string;
    highlightedContent: string;
  };
}) {
  const [t] = useTranslation();
  const thumbnailUrl = getProjectThumbnailUrl(item);

  const professionsArray = item.highlightedProfessions.split(JOINER);

  return (
    <Link
      className="flex flex-col md:flex-row gap-4 bg-white p-2 pb-4 contain-content"
      to={getBoardPath(item.name, item.firstBoardId)}
    >
      <div className="md:w-[300px] flex-shrink-0">
        {thumbnailUrl ? (
          <img
            src={thumbnailUrl}
            alt=""
            className="aspect-video w-full overflow-hidden object-cover"
          />
        ) : (
          <div className="bg-bgGray w-full aspect-video flex items-center">
            <img src="/assets/images/logo-gray.svg" alt="" className="mx-auto" />
          </div>
        )}
      </div>
      <div className="flex flex-col contain-content overflow-hidden">
        <h1 className="font-bold text-2xl truncate">
          <SearchResultHighlighter>{item.highlightedName}</SearchResultHighlighter>
        </h1>
        <h3 className="font-medium text-blue50 text-base tracking-[-0.4px] text-ellipsis">
          <SearchResultHighlighter>{item.highlightedSubtitle}</SearchResultHighlighter>
        </h3>
        <div className="text-sm leading-6 font-medium text-blue100 tracking-[-0.4px] text-ellipsis">
          {t('common.professionOrProfessions')}:{' '}
          <TruncatedProfessions
            professionNames={professionsArray}
            Component={SearchResultHighlighter}
          />
        </div>
        <div className="text-sm leading-4 text-blue70 pt-4 font-normal flex-grow text-ellipsis">
          <SearchResultHighlighter>{item.highlightedContent}</SearchResultHighlighter>
        </div>
      </div>
    </Link>
  );
}

export default React.memo(SearchResult);
