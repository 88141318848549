import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem';

function BoardInfoActionsStep({
  onSettingsOpen,
  onClose,
  handleDelete,
  canDelete,
}: {
  onSettingsOpen: () => void;
  onClose: () => void;
  handleDelete: () => void;
  canDelete: boolean;
}) {
  const [t] = useTranslation();

  const handleClickSettingsOpen = useCallback(() => {
    onSettingsOpen();
    onClose();
  }, [onSettingsOpen, onClose]);

  return (
    <ContextMenu>
      <ContextMenuItem onClick={handleClickSettingsOpen}>
        {t('action.settings', {
          context: 'title',
        })}
      </ContextMenuItem>
      {canDelete && <ContextMenuItem onClick={handleDelete}>{t('action.delete')}</ContextMenuItem>}
    </ContextMenu>
  );
}

export default BoardInfoActionsStep;
