import { useTranslation } from 'react-i18next';
import ProjectButton from '../ProjectButton/ProjectButton.js';

function ProjectCreateButton({ onClick }: { onClick: () => void }) {
  const [t] = useTranslation();
  return (
    <ProjectButton
      text={t('action.createBoard')}
      icon="/assets/images/icon-plus-filled.svg"
      onClick={onClick}
    />
  );
}

export default ProjectCreateButton;
