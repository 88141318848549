import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getFormErrorMessage } from '../../utils/getFormErrorMessage.js';
import { simpleAlert } from '../../utils/simpleAlert';

function SimpleAlert({ error }: { error: any }) {
  const [t] = useTranslation();
  useEffect(() => {
    const message = getFormErrorMessage(error, t);
    simpleAlert(message);
  }, [error, t]);

  return null;
}

export default SimpleAlert;
