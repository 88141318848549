import katex from 'katex';
import Quill, { QuillOptions } from 'quill';
import { Delta } from 'quill/core.js';
import { useEffect, useRef } from 'react';
import 'quill/dist/quill.snow.css';
import 'katex/dist/katex.css';
import './quill.css';
import { isQuillEmptyContents } from '../../utils/isQuillEmptyContents.js';

// @ts-ignore
globalThis.katex = katex;

const tryParse = (value: string) => {
  try {
    if (!value) {
      return [];
    }
    return JSON.parse(value);
  } catch {
    return [
      {
        insert: `${value}\n`,
      },
    ];
  }
};

const clipBoardMatcher = (node, delta) => {
  return delta.compose(
    new Delta().retain(delta.length(), {
      color: '',
      background: '',
      header: 0,
    }),
  );
};

const getQuillOptions = ({ isPreview }: { isPreview: boolean }): QuillOptions => {
  return {
    readOnly: isPreview,
    modules: {
      clipboard: {
        matchers: [['*', clipBoardMatcher]],
      },
      toolbar: isPreview
        ? false
        : [
            ['bold', 'italic', 'underline', 'strike'],
            [{ background: [] }],
            [{ script: 'super' }, { script: 'sub' }],
            ['blockquote', 'code-block'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'formula'],
            ['clean'],
          ],
    },
    theme: 'snow',
  };
};

function QuillWrapper({
  value,
  onChange,
  isPreview,
  initialForceOpen,
}: {
  value: string;
  onChange?: (value: string) => void;
  isPreview?: boolean;
  initialForceOpen?: boolean;
}) {
  const ref = useRef(null);
  const wrapper = useRef(null);
  const quillRef = useRef(null);
  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return () => {};
    }
    const options = getQuillOptions({
      isPreview,
    });
    const quill = new Quill(element, options);
    quillRef.current = quill;
    const handleTextChange = (event) => {
      if (!onChange) {
        return;
      }
      const contents = quill.getContents();
      if (isQuillEmptyContents(contents)) {
        onChange('');
        return;
      }
      onChange(JSON.stringify(contents));
    };
    quill.on('text-change', handleTextChange);

    const handleMouseDown = (event) => {
      event.preventDefault();
    };
    const toolbar = wrapper.current.querySelector('.ql-toolbar');
    if (toolbar) {
      // https://github.com/zenoamaro/react-quill/issues/360
      toolbar.addEventListener('mousedown', handleMouseDown);
    }
    return () => {
      if (toolbar) {
        toolbar.removeEventListener('mousedown', handleMouseDown);
      }
      quill.off('text-change', handleTextChange);
      quill.container.remove();
    };
  }, [isPreview, onChange]);

  useEffect(() => {
    const quill = quillRef.current;
    if (!quill) {
      return;
    }
    if (initialForceOpen) {
      quill.focus();
    }
  }, [initialForceOpen]);

  useEffect(() => {
    const quill = quillRef.current;
    if (!quill) {
      return;
    }
    const existing = quill.getContents();
    if (JSON.stringify(existing) === value) {
      return;
    }
    const parsed = tryParse(value);
    quill.setContents(parsed);
  }, [value]);

  return (
    <div data-readonly={isPreview} ref={wrapper}>
      <div id="quill" ref={ref} />
    </div>
  );
}

export default QuillWrapper;
