import ActionTypes from '../../constants/ActionTypes';
import { SortOrder } from '../../types/SortOrder.js';

const initialState: {
  professionIds: string[];
  sortOrder: SortOrder;
  page: number;
} = {
  professionIds: [],
  sortOrder: 'created_desc',
  page: 1,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PROJECT_FILTER_UPDATE:
      return {
        ...state,
        ...payload,
        page: 1,
      };
    case ActionTypes.LOAD_COMMUNITY_PROJECTS__SUCCESS:
      return {
        ...state,
        page: payload.page,
      };
    default:
      return state;
  }
};
