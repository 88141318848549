import SearchResults from '../SearchResults/SearchResults';

function Search({
  items,
  totalItemCount,
  canLoadMore,
  error,
  loadMore,
}: {
  items: any[];
  totalItemCount: number;
  error?: any;
  canLoadMore: boolean;
  loadMore: () => void;
}) {
  return (
    <div className="flex flex-col flex-1 contain-strict">
      <SearchResults
        items={items}
        totalItemCount={totalItemCount}
        error={error}
        canLoadMore={canLoadMore}
        onLoadMore={loadMore}
      />
    </div>
  );
}

export default Search;
