import ActionTypes from '../../constants/ActionTypes';

export const updateProjectBackgroundImage = (id) => ({
  type: ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE,
  payload: {
    id,
  },
});

updateProjectBackgroundImage.success = (project) => ({
  type: ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS,
  payload: {
    project,
  },
});

updateProjectBackgroundImage.failure = (id, error) => ({
  type: ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE,
  payload: {
    id,
    error,
  },
});
