/* eslint-disable react/require-default-props */
import React from 'react';
import * as Semantic from 'semantic-ui-react';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton.js';
import ModalMobile from '../ModalMobile/ModalMobile.js';

function Modal({
  size,
  children,
  basic,
  open,
  onClose,
  isMobile,
  showCloseButton = true,
}: {
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
  children: React.ReactNode;
  basic?: boolean;
  open?: boolean;
  onClose?: () => void;
  isMobile: boolean;
  showCloseButton?: boolean;
}) {
  if (isMobile) {
    return <ModalMobile onClose={onClose}>{children}</ModalMobile>;
  }
  return (
    <Semantic.Modal size={size} basic={basic} open={open} onClose={onClose} className="top-0">
      <Semantic.Modal.Content>
        <div className="relative">
          {showCloseButton && (
            <div
              className="absolute right-0 top-0 z-10"
              style={{
                transform: 'translate(50%, -50%)',
              }}
            >
              <ModalCloseButton onClick={onClose} />
            </div>
          )}
          <div className="rounded">{children}</div>
        </div>
      </Semantic.Modal.Content>
    </Semantic.Modal>
  );
}

export default Modal;
