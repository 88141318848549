/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownItemProps, DropdownMenu } from 'semantic-ui-react';

function SelectBox<T>({
  options,
  selectedOptionId,
  setSelectedOptionId,
  getLabel,
  getId,
  className,
}: {
  options: readonly T[];
  selectedOptionId: string;
  setSelectedOptionId: (id: string) => void;
  getLabel: (option: T) => string;
  getId: (option: T) => string;
  className?: string;
}) {
  const dropDownOptions: DropdownItemProps[] = options.map((option) => {
    return {
      text: getLabel(option),
      key: getId(option),
    };
  });

  const selectedOption = options.find((option) => getId(option) === selectedOptionId);
  const [t] = useTranslation();
  const label = selectedOption ? getLabel(selectedOption) : t('common.choose');
  const handleChange = (event: any) => {
    const { target } = event;
    const option = target.closest('.item');
    if (!option) {
      return;
    }
    const { id } = option.dataset;
    if (!id) {
      return;
    }
    setSelectedOptionId(id);
  };
  return (
    <div className={classNames(className)}>
      <Dropdown text={label} onClick={handleChange} fluid basic className="selection">
        <DropdownMenu>
          {dropDownOptions.map((option) => {
            return <DropdownItem key={option.key} text={option.text} data-id={option.key} />;
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default SelectBox;
