import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import { MenuItemLink } from '../../types/MenuItemType';
import ContextMenuItemIcon from '../ContextMenuItemIcon/ContextMenuItemIcon';

function ContextMenuItemLink({ translationKey, url, icon }: MenuItemLink) {
  const [t] = useTranslation();
  return (
    <Menu.Item as={Link} to={url} data-testid="ContextMenuItemLink">
      <div className="flex gap-2 items-center">
        {icon && <ContextMenuItemIcon icon={icon} />}
        <span className={classNames('text-blue100', 'w-full font-semibold')}>
          {t(translationKey)}
        </span>
      </div>
    </Menu.Item>
  );
}

export default ContextMenuItemLink;
