import rehypeRaw from 'rehype-raw';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import Markdown from '../../lib/custom-ui/components/Markdown/Markdown';

function MarkdownPage({
  title,
  content,
  children,
  __dangerouslyAllowHtml,
}: {
  title: string;
  content: string;
  children?: React.ReactNode;
  __dangerouslyAllowHtml?: boolean;
}) {
  const scrollRef = useScrollRestoration('markdown');
  return (
    <div className="flex flex-col flex-1 contain-strict">
      <main
        className="flex flex-col flex-1 overflow-hidden overflow-y-auto bg-bgGray w-full p-5"
        ref={scrollRef}
      >
        <div className="mx-auto max-w-screen-lg w-full max-w-[600]">
          <Markdown
            className=""
            linkStopPropagation={false}
            rehypePlugins={__dangerouslyAllowHtml ? [rehypeRaw] : []}
          >
            {content}
          </Markdown>
          {children}
        </div>
      </main>
    </div>
  );
}

export default MarkdownPage;
