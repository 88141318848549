import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoardImportModal from '../components/BoardImportModal/BoardImportModal';
import Paths from '../constants/Paths';
import entryActions from '../entry-actions/index';
import { push } from '../lib/redux-router/index';

const mapStateToProps = (state) => {
  const {
    ui: {
      projectImportForm: { data, isSubmitting, error, isSuccess, successUrl },
    },
  } = state;
  const defaultData = {
    url: '',
  };
  return {
    isBoardAccess: true,
    defaultData,
    isSubmitting,
    isSuccess,
    error,
    successUrl,
    requestId: data.requestId,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      onUpdate: entryActions.importProjectFromPadlet,
      onCancel: entryActions.cancelProjectImport,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onClose: () => dispatchProps.push(Paths.ROOT),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BoardImportModal);
