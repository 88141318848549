import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import { push } from '../../../../lib/redux-router';
import request from '../../request';

export function* updateProject(id, data, redirectTo) {
  yield put(actions.updateProject(id, data));

  let project;
  try {
    ({ item: project } = yield call(request, api.updateProject, id, data));
  } catch (error) {
    yield put(actions.updateProject.failure(id, error));
    return;
  }

  yield put(actions.updateProject.success(project));

  if (redirectTo) {
    yield put(push(redirectTo));
  }
}
