function ContentEditorMde({ value }: { value: string }) {
  return (
    <div>
      <strong>Note: The markdown editor is not supported anymore.</strong>
      <pre>{value}</pre>
    </div>
  );
}

export default ContentEditorMde;
