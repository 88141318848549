import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../containers/ModalContainer';
import { useForm } from '../../hooks/index';
import BoardDuplicateModalContent from '../BoardDuplicateModalContent/BoardDuplicateModalContent';

function BoardDuplicateModal({
  defaultData,
  isSubmitting,
  onDuplicate,
  onClose,
  error,
}: {
  defaultData: {
    name: string;
  };
  isSubmitting: boolean;
  onDuplicate: (data: any, redirectTo?: string) => void;
  onClose: () => void;
  error?: any;
}) {
  const [t] = useTranslation();
  const [data, handleFieldChange] = useForm(() => ({
    ...defaultData,
    name: `${defaultData.name} - ${t('common.copyProjectSuffix')}`,
  }));

  const handleSubmit = useCallback(() => {
    const cleanData = data;
    onDuplicate(cleanData);
  }, [onDuplicate, data]);

  const nameField = useRef(null);

  return (
    <ModalContainer open basic size="tiny" onClose={onClose}>
      <BoardDuplicateModalContent
        data={data}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handleFieldChange={handleFieldChange}
        error={error}
        nameField={nameField}
      />
    </ModalContainer>
  );
}

export default React.memo(BoardDuplicateModal);
