import DefaultMediaPreviewContainer from '../../containers/MediaPreviewContainer';
import MediaUploadFilePicker from '../MediaUploadFilePicker/MediaUploadFilePicker';
import MediaUploadLoader from '../MediaUploadLoader/MediaUploadLoader';
import MediaUploadPreview from '../MediaUploadPreview/MediaUploadPreview';

function MediaUpload({
  handleFileSelect,
  handleFileDelete,
  media,
  isMediaUpdating,
  canEdit,
  mediaPreviewUrl,
  mediaLinkUrl,
  MediaPreviewContainer = DefaultMediaPreviewContainer,
}: {
  handleFileSelect: ({ file }: { file: File }) => void;
  handleFileDelete: () => void;
  media: string;
  isMediaUpdating: boolean;
  canEdit: boolean;
  mediaPreviewUrl?: string;
  mediaLinkUrl?: string;
  MediaPreviewContainer?: any;
}) {
  if (isMediaUpdating) {
    return <MediaUploadLoader />;
  }
  if (media) {
    return (
      <MediaUploadPreview
        mediaLinkUrl={mediaLinkUrl}
        mediaPreviewUrl={mediaPreviewUrl}
        media={media}
        handleFileDelete={handleFileDelete}
        canEdit={canEdit}
        MediaPreviewContainer={MediaPreviewContainer}
      />
    );
  }
  if (!canEdit) {
    return null;
  }
  return <MediaUploadFilePicker handleFileSelect={handleFileSelect} />;
}

export default MediaUpload;
