/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './AddAnotherCard.module.scss';
import AddAnother from '../AddAnother/AddAnother';

function AddAnotherCard({
  isPersisted,
  cardIds,
  handleAddCardClick,
  background,
}: {
  isPersisted?: boolean;
  cardIds: string[];
  handleAddCardClick: () => void;
  background?: string;
}) {
  const [t] = useTranslation();
  return (
    <AddAnother
      className={styles.addCardButton}
      disabled={!isPersisted}
      onClick={handleAddCardClick}
      ariaLabel={cardIds.length > 0 ? t('action.addAnotherCard') : t('action.addCard')}
      background={background}
    >
      <span className="absolute w-3 h-3 bg-white mt-0.25 ml-0.25 z-10 hidden group-hover:block" />
      <img
        src="/assets/images/icon-plus-filled.svg"
        className={classNames(styles.addCardButtonIcon, 'z-10')}
        alt=""
      />
    </AddAnother>
  );
}

export default AddAnotherCard;
