import ActionTypes from '../constants/ActionTypes';

export const handleSocketDisconnect = () => ({
  type: ActionTypes.SOCKET_DISCONNECT_HANDLE,
  payload: {},
});

export const handleSocketReconnect = (
  user,
  board,
  users,
  projects,
  projectManagers,
  boards,
  boardMemberships,
  lists,
  cards,
  attachments,
  projectProfessions,
  communityProjects,
  professions,
) => ({
  type: ActionTypes.SOCKET_RECONNECT_HANDLE,
  payload: {
    user,
    board,
    users,
    projects,
    projectManagers,
    boards,
    boardMemberships,
    lists,
    cards,
    attachments,
    projectProfessions,
    communityProjects,
    professions,
  },
});

handleSocketReconnect.fetchCore = (currentUserId, currentBoardId) => ({
  type: ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH,
  payload: {
    currentUserId,
    currentBoardId,
  },
});
