import ModalContainer from '../../containers/ModalContainer';
import DescriptionEditConfirmModalContent from '../DescriptionEditConfirmModalContent/DescriptionEditConfirmModalContent';

function DescriptionEditConfirmModal({
  onDiscardAndClose,
  onCancel,
  onClose,
}: {
  onDiscardAndClose: () => void;
  onCancel: () => void;
  onClose: () => void;
}) {
  return (
    <ModalContainer open basic size="small" onClose={onClose}>
      <DescriptionEditConfirmModalContent
        onCancel={onCancel}
        onDiscardAndClose={onDiscardAndClose}
      />
    </ModalContainer>
  );
}

export default DescriptionEditConfirmModal;
