import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import HeaderDesktop from '../HeaderDesktop/HeaderDesktop';
import HeaderMobile from '../HeaderMobile/HeaderMobile';

function Header({
  onLogout,
  openAbout,
  addBookmark,
  removeBookmark,
  isLoggedIn,
  onAuthenticateUsingOidc,
  expandSearch,
  collapseSearch,
  searchExpanded,
  user,
  canEdit,
  showScrollChange,
  toggleScrollMode,
  boardScrollFull,
  handleDelete,
  canDelete,
  project = undefined,
  board = undefined,
  showBackButton = false,
  showSearch = false,
  showBookmarks = true,
  isMobile = false,
  isPasswordProtected = false,
}: InferProps<typeof Header.propTypes>) {
  if (isMobile) {
    return (
      <HeaderMobile
        expandSearch={expandSearch}
        collapseSearch={collapseSearch}
        searchExpanded={searchExpanded}
        openAbout={openAbout}
        onLogout={onLogout}
        onAuthenticateUsingOidc={onAuthenticateUsingOidc}
        isLoggedIn={isLoggedIn}
        board={board}
        showSearch={showSearch}
        project={project}
        addBookmark={addBookmark}
        removeBookmark={removeBookmark}
        user={user}
        canEdit={canEdit}
        showScrollChange={showScrollChange}
        toggleScrollMode={toggleScrollMode}
        boardScrollFull={boardScrollFull}
        handleDelete={handleDelete}
        canDelete={canDelete}
        isPasswordProtected={isPasswordProtected}
      />
    );
  }
  return (
    <HeaderDesktop
      project={project}
      board={board}
      onLogout={onLogout}
      showBackButton={showBackButton}
      showSearch={showSearch}
      openAbout={openAbout}
      showBookmarks={showBookmarks}
      addBookmark={addBookmark}
      removeBookmark={removeBookmark}
      isLoggedIn={isLoggedIn}
      onAuthenticateUsingOidc={onAuthenticateUsingOidc}
      user={user}
      canEdit={canEdit}
      showScrollChange={showScrollChange}
      toggleScrollMode={toggleScrollMode}
      boardScrollFull={boardScrollFull}
      canDelete={canDelete}
      isPasswordProtected={isPasswordProtected}
    />
  );
}

Header.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  project: PropTypes.shape({
    name: PropTypes.string,
    favorite: PropTypes.bool,
    id: PropTypes.string,
  }),
  board: PropTypes.shape({ id: PropTypes.string }),
  /* eslint-enable react/forbid-prop-types */
  showSearch: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
  showBookmarks: PropTypes.bool,
  openAbout: PropTypes.func.isRequired,
  addBookmark: PropTypes.func,
  removeBookmark: PropTypes.func,
  onAuthenticateUsingOidc: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  expandSearch: PropTypes.func.isRequired,
  collapseSearch: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  searchExpanded: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
  canEdit: PropTypes.bool.isRequired,
  showScrollChange: PropTypes.bool.isRequired,
  toggleScrollMode: PropTypes.func.isRequired,
  boardScrollFull: PropTypes.bool.isRequired,
  isPasswordProtected: PropTypes.bool.isRequired,
};

export default React.memo(Header);
