import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {
    name: '',
    subtitle: '',
    professions: [],
    background: '',
  },
  isSubmitting: false,
  error: null,
  isThumbnailUpdating: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PROJECT_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data,
        },
        error: null,
        isSubmitting: true,
      };
    case ActionTypes.PROJECT_THUMBNAIL_UPDATE:
      return {
        ...state,
        isThumbnailUpdating: true,
      };
    case ActionTypes.PROJECT_THUMBNAIL_UPDATE__SUCCESS:
      return {
        ...state,
        isThumbnailUpdating: false,
      };
    case ActionTypes.PROJECT_UPDATE__SUCCESS:
      return initialState;
    case ActionTypes.PROJECT_UPDATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    case ActionTypes.PROJECT_THUMBNAIL_UPDATE__FAILURE:
    case ActionTypes.PROJECT_THUMBNAIL_DELETE__FAILURE:
      return {
        ...state,
        isThumbnailUpdating: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
