/* eslint-disable jsx-a11y/click-events-have-key-events */
import BoardPasswordModalContainer from '../../containers/BoardPasswordModalContainer.js';

function BoardPasswordPage() {
  return (
    <div className="h-full">
      {/* @ts-ignore */}
      <BoardPasswordModalContainer />
    </div>
  );
}

export default BoardPasswordPage;
