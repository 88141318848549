/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDocumentTitle, useOnWindowResize } from 'rooks';
import { Loader } from 'semantic-ui-react';
import styles from './Core.module.scss';
import FixedContainer from '../../containers/FixedContainer';
import SlideshowContainer from '../../containers/SlideshowContainer';
import StaticContainer from '../../containers/StaticContainer';
import Footer from '../Footer/Footer';
import GlobalError from '../GlobalError';
import Modals from '../Modals/Modals.js';

const getChildren = ({
  isSlideshow,
  showBackground,
}: {
  isSlideshow?: boolean;
  showBackground: boolean;
}) => {
  if (!showBackground) {
    return null;
  }
  if (isSlideshow) {
    return <SlideshowContainer />;
  }
  return (
    <>
      <FixedContainer />
      <StaticContainer />
      <Footer className="hidden md:block" />
    </>
  );
};

function Core({
  isInitializing,
  isSocketDisconnected,
  handleResize,
  modals,
  isMobile = false,
  globalError = undefined,
  isSlideshow = false,
  documentTitle = undefined,
}: InferProps<typeof Core.propTypes>) {
  const [t] = useTranslation();

  useDocumentTitle(documentTitle, { resetOnUnmount: true });
  useOnWindowResize(handleResize);

  if (globalError) {
    return <GlobalError globalError={globalError} />;
  }

  const hasModal = modals.length > 0;
  const showBackground = !(hasModal && isMobile);

  return (
    <>
      {isInitializing ? (
        <Loader active size="massive" />
      ) : (
        <>
          {getChildren({
            isSlideshow,
            showBackground,
          })}

          <Modals modals={modals} />
        </>
      )}
      {isSocketDisconnected && (
        <div className="bg-errorRed rounded bottom-5 shadow-[_0_1px_0] shadow-errorRedDark px-3 py-[18px] fixed left-5 sm:left-auto sm:m-0 sm:max-w-[390px] right-5 z-[10001]">
          <div className={styles.messageHeader}>{t('common.noConnectionToServer')}</div>
          <div className={styles.messageContent}>
            <Trans i18nKey="common.allChangesWillBeAutomaticallySavedAfterConnectionRestored">
              All changes will be automatically saved
              <br />
              after connection restored
            </Trans>
          </div>
        </div>
      )}
    </>
  );
}

Core.propTypes = {
  isInitializing: PropTypes.bool.isRequired,
  isSocketDisconnected: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  globalError: PropTypes.any,
  isSlideshow: PropTypes.bool,
  isMobile: PropTypes.bool,
  documentTitle: PropTypes.string,
  handleResize: PropTypes.func.isRequired,
  modals: PropTypes.array.isRequired,
};

export default React.memo(Core);
