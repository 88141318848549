import EntryActionTypes from '../constants/EntryActionTypes';

const logout = () => ({
  type: EntryActionTypes.LOGOUT,
  payload: {},
});

const handleResize = () => ({
  type: EntryActionTypes.HANDLE_RESIZE,
  payload: {},
});

export default {
  logout,
  handleResize,
};
