import Markdown from '../../lib/custom-ui/components/Markdown/Markdown.jsx';

function ContentPreviewMde({ value }: { value: string }) {
  return (
    // @ts-ignore
    <Markdown linkStopPropagation linkTarget="_blank">
      {value}
    </Markdown>
  );
}

export default ContentPreviewMde;
