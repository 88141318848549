import { call, select } from 'redux-saga/effects';
import api from '../../../api';
import * as Paths from '../../../constants/Paths';
import selectors from '../../../selectors';
import { getPlainTextPassword } from '../../../utils/plainTextPassword';
import request from '../request';

export function* fetchBoardByCurrentPath() {
  const pathsMatch = yield select(selectors.selectPathsMatch);

  let board;
  let card;
  let users;
  let projects;
  let boardMemberships;
  let labels;
  let lists;
  let cards;
  let cardMemberships;
  let cardLabels;
  let tasks;
  let attachments;
  let projectProfessions;

  if (pathsMatch) {
    let boardId;
    if (Paths.needsBoard(pathsMatch.pattern.path)) {
      boardId = pathsMatch.params.id;
    } else if (Paths.needsCard(pathsMatch.pattern.path)) {
      ({
        item: card,
        item: { boardId },
      } = yield call(request, api.getCard, pathsMatch.params.id));
    }

    if (boardId) {
      const plainTextPassword = getPlainTextPassword(boardId);
      ({
        item: board,
        included: {
          users,
          projects,
          boardMemberships,
          labels,
          lists,
          cards,
          cardMemberships,
          cardLabels,
          tasks,
          attachments,
          projectProfessions,
        },
      } = yield call(request, api.getBoard, boardId, true, plainTextPassword));
    }
  }

  return {
    board,
    card,
    users,
    boardMemberships,
    labels,
    lists,
    cards,
    cardMemberships,
    cardLabels,
    tasks,
    attachments,
    project: projects?.[0],
    projects,
    projectProfessions,
  };
}

export default {
  fetchBoardByCurrentPath,
};
