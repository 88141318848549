/* eslint-disable no-param-reassign */
import { eventBus } from '../../../utils/eventBus';

export const handleError = (payload) => {
  if (payload && payload.payload) {
    payload = payload.payload;
  }
  const { error } = payload;
  eventBus.dispatchEvent(
    new ErrorEvent('mikado:error', {
      error,
    }),
  );
};
