import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* updateProjectBackgroundImage(id, data) {
  yield put(actions.updateProjectBackgroundImage(id));

  let project;
  try {
    ({ item: project } = yield call(request, api.updateProjectBackgroundImage, id, data));
  } catch (error) {
    yield put(actions.updateProjectBackgroundImage.failure(id, error));
    return;
  }

  yield put(actions.updateProjectBackgroundImage.success(project));
}
