import { call, put } from 'redux-saga/effects';
import actions from '../../../actions';
import api from '../../../api/index';
import request from '../request';

export function* searchValueUpdate(searchValue) {
  yield put(actions.updateSearchValue(searchValue));
}

export function* search(searchValue) {
  yield put(actions.search(searchValue));
  let searchResults;
  let totalCount;
  try {
    ({ items: searchResults, totalCount } = yield call(request, api.search, { searchValue }));
  } catch (error) {
    yield put(actions.search.failure(error));
    return;
  }
  // we return the searchValue in order to update it in the ui/searchForm
  // this is needed when navigating via url e.g. when inserting <host>/?search=Test, our state does not get updated accordingly
  // otherwise we don't get consistent state when navigating via url and therefore loading more fails
  yield put(actions.search.success(searchResults, totalCount, searchValue));
}

export function* loadMoreSearchResults(searchValue: string, page: number) {
  yield put(actions.loadMoreSearchResults({ searchValue, page }));
  let searchResults;
  let totalCount;
  let currentPage;
  try {
    ({
      items: searchResults,
      totalCount,
      currentPage,
    } = yield call(request, api.search, { searchValue, page }));
  } catch (error) {
    yield put(actions.loadMoreSearchResults.failure(error));
    return;
  }
  yield put(actions.loadMoreSearchResults.success(searchResults, totalCount, currentPage));
}

export function* searchExpand() {
  yield put(actions.searchExpand());
}

export function* searchCollapse() {
  yield put(actions.searchCollapse());
}
