import { call, put, select, take } from 'redux-saga/effects';
import actions from '../../../actions';
import api from '../../../api';
import { BREAKPOINT_MOBILE } from '../../../constants/BreakPoints';
import i18n from '../../../i18n';
import selectors from '../../../selectors';
import { removeAccessToken } from '../../../utils/access-token-storage';
import * as Matomo from '../../../utils/matomo.js';
import request from '../request';
import requests from '../requests';

export function* initializeCore() {
  try {
    globalThis.global = globalThis;
    const isMobile = window.innerWidth < BREAKPOINT_MOBILE;
    const currentConfig = yield select(selectors.selectConfig); // TODO: add boolean selector?

    let config;
    if (!currentConfig) {
      ({ item: config } = yield call(api.getConfig));

      yield put(actions.initializeLoginSSO(config));
      yield put(actions.initializeCore.fetchConfig(config));
    }

    const {
      user,
      board,
      users,
      lists,
      cards,
      attachments,
      professions,
      communityProjects,
      projectProfessions,
      canLoadMoreCommunityProjects,
      projects,
      projectManagers,
      boards,
      boardMemberships,
      projectUserFavorites,
    } = yield call(requests.fetchCore);

    if (user) {
      yield call(i18n.changeLanguage, user.language);
    }
    yield call(i18n.loadCoreLocale);

    yield put(
      actions.initializeCore(
        user,
        board,
        users,
        projects,
        projectManagers,
        boards,
        boardMemberships,
        lists,
        cards,
        attachments,
        professions,
        communityProjects,
        projectProfessions,
        canLoadMoreCommunityProjects,
        projectUserFavorites,
        isMobile,
      ),
    );
  } catch (error) {
    yield put(actions.initializeCore.globalError(error));
  } finally {
    Matomo.load();
  }
}

export function* changeCoreLanguage(language) {
  if (language === null) {
    yield call(i18n.loadCoreLocale);
    yield call(i18n.changeLanguage, i18n.resolvedLanguage);
  } else {
    yield call(i18n.loadCoreLocale, language);
    yield call(i18n.changeLanguage, language);
  }
}

export function* logout(invalidateAccessToken = true) {
  yield call(removeAccessToken);

  if (invalidateAccessToken) {
    yield put(actions.logout.invalidateAccessToken());

    try {
      yield call(request, api.deleteCurrentAccessToken);
    } catch (error) {} // eslint-disable-line no-empty
  }

  yield put(actions.logout());
  yield take();
}

export function* handleResize() {
  const isMobile = window.innerWidth < BREAKPOINT_MOBILE;
  yield put(actions.handleResize(isMobile));
}

export default {
  initializeCore,
  changeCoreLanguage,
  logout,
  handleResize,
};
