import { useTranslation } from 'react-i18next';
import Markdown from '../../lib/custom-ui/components/Markdown/index.js';
import { getTextSync } from '../../utils/getTextSync';
import MarkdownPage from '../MarkdownPage/MarkdownPage';

function LicensesPage() {
  const [t] = useTranslation();
  const content = getTextSync('/assets/markdown/ThirdPartyNotices.txt');
  const noticeContent = getTextSync('/assets/markdown/source-code-notice-de.md');
  return (
    <MarkdownPage title={t('common.thirdPartyNotices')} content={content}>
      <div className="markdown-body mt-4">
        <h2
          style={{
            marginBottom: '4px',
          }}
        >
          {t('common.sourceCode')}
        </h2>

        <div>
          <Markdown className="" linkStopPropagation={false}>
            {noticeContent}
          </Markdown>
        </div>
      </div>
    </MarkdownPage>
  );
}

export default LicensesPage;
