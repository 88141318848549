/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import BoardImportModalContentDefault from '../BoardImportModalContentDefault/BoardImportModalContentDefault';
import BoardImportModalContentInProgress from '../BoardImportModalContentInProgress/BoardImportModalContentInProgress';
import BoardImportModalContentSuccess from '../BoardImportModalContentSuccess/BoardImportModalContentSuccess';

function BoardImportModalContent({
  isSubmitting,
  isSuccess,
  handleSubmit,
  data,
  handleFieldChange,
  error,
  onCancel,
  onClose,
  successUrl,
}: {
  isSubmitting: boolean;
  isSuccess: boolean;
  handleSubmit: () => void;
  data: { url: string };
  handleFieldChange: (...args: any[]) => void;
  error?: any;
  onCancel: () => void;
  onClose: () => void;
  successUrl: string;
}) {
  const [t] = useTranslation();
  const showDefault = error || (!isSubmitting && !isSuccess);
  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <span className="text-2xl text-blue100 font-bold">{t('common.padletBoardImport')}</span>
      </header>

      <div className="min-h-[220px] flex flex-col bg-white text-blue100 ">
        {isSubmitting && <BoardImportModalContentInProgress onCancel={onCancel} />}

        {isSuccess && <BoardImportModalContentSuccess boardUrl={successUrl} />}

        {showDefault && (
          <BoardImportModalContentDefault
            handleFieldChange={handleFieldChange}
            handleSubmit={handleSubmit}
            data={data}
            error={error}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(BoardImportModalContent);
