import React from 'react';
import { useTranslation } from 'react-i18next';
import FormEmbedUpload from '../FormEmbedUpload/FormEmbedUpload.js';
import MediaUpload from '../MediaUpload/MediaUpload.js';

function FormEmbedOrMediaUpload({
  embedUrl,
  setUrl,
  mediaUrl,
  onMediaUpdate,
  onImageDelete,
  isMediaUpdating,
  canEdit,
  ogImage,
  ogTitle,
  ogDescription,
  mediaPreviewUrl,
  mediaLinkUrl,
}: {
  embedUrl: string;
  setUrl: (url: string) => void;
  mediaUrl: string;
  onMediaUpdate: ({ file }: { file: File | null }) => void;
  onImageDelete: () => void;
  isMediaUpdating: boolean;
  canEdit: boolean;
  ogImage: string;
  ogTitle: string;
  ogDescription: string;
  mediaPreviewUrl?: string;
  mediaLinkUrl?: string;
}) {
  const [t] = useTranslation();
  const shouldShowMediaUpload = !embedUrl;
  const shouldShowEmbed = !mediaUrl;
  const shouldShowOr = !mediaUrl && !embedUrl;
  return (
    <div
      className="bg-bgGray p-2.5 text-blue100 flex flex-col flex-gap-2 border border-px border-blue10 rounded-sm"
      data-test-id="FormEmbedOrMediaUpload"
    >
      {shouldShowMediaUpload && (
        <MediaUpload
          media={mediaUrl}
          handleFileSelect={onMediaUpdate}
          handleFileDelete={onImageDelete}
          isMediaUpdating={isMediaUpdating}
          canEdit={canEdit}
          mediaPreviewUrl={mediaPreviewUrl}
          mediaLinkUrl={mediaLinkUrl}
        />
      )}
      {shouldShowOr && (
        <div className="flex items-center justify-center">
          <span className="uppercase text-blue50 text-sm">{t('common.or')}</span>
        </div>
      )}
      {shouldShowEmbed && (
        <FormEmbedUpload
          url={embedUrl}
          setUrl={setUrl}
          canEdit={canEdit}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
        />
      )}
    </div>
  );
}

export default React.memo(FormEmbedOrMediaUpload);
