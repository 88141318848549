/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as MetaData from '../../constants/MetaData';
import Logos from '../Logos/Logos';

function AboutModalContent() {
  const [t] = useTranslation();
  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <span className="text-2xl text-blue100 font-bold">{t('common.aboutMikado')}</span>
      </header>

      <div>
        {t('common.version')} {MetaData.version}
      </div>

      <section className="mt-10">
        <Logos />
      </section>

      <section className="flex items-center gap-5 mt-7">
        <a href="/impressum" target="_blank" className="text-blue100 hover:text-blue70">
          {t('common.imprint')}
        </a>
        <a href="/datenschutz" target="_blank" className="text-blue100 hover:text-blue70">
          {t('common.dataSecurity')}
        </a>
        <a href="/licenses" target="_blank" className="text-blue100 hover:text-blue70">
          {t('common.thirdPartyNotices')}
        </a>
      </section>
    </div>
  );
}

export default React.memo(AboutModalContent);
