import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType';
import Button from '../Button/Button';

function DatePickStep({
  dateTime,
  handleSave,
  onClose,
}: {
  dateTime: string;
  handleSave: (dateTime: string) => void;
  onClose: () => void;
}) {
  const [t] = useTranslation();

  const [value, setValue] = useState(dateTime);
  const wrappedSetDateTime = (event: any) => {
    const { value } = event.target;
    setValue(value);
  };
  const wrappedHandleSave = () => {
    handleSave(value);
    onClose();
  };
  const handleDateTimeReset = () => {
    setValue('');
    handleSave('');
    onClose();
  };
  return (
    <div className="p-3">
      <div className="flex items-center gap-0.5">
        {t('common.pickADate')}
        <input type="datetime-local" value={value} onChange={wrappedSetDateTime} />
      </div>
      <div className="flex gap-2">
        <Button
          variant={ButtonType.Green}
          type="button"
          onClick={wrappedHandleSave}
          className="mt-2 ml-auto w-full"
        >
          {t('common.save')}
        </Button>
        <Button
          variant={ButtonType.Blue}
          type="button"
          onClick={handleDateTimeReset}
          className="mt-2 ml-auto w-full"
        >
          {t('common.reset')}
        </Button>
      </div>
    </div>
  );
}

export default DatePickStep;
