/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import ColorChooserBlock from '../ColorChooserBlock/ColorChooserBlock.js';

function ColorChooser({
  currentColor,
  colors,
  setCurrentColor,
  label,
}: {
  colors: readonly string[];
  currentColor: string;
  setCurrentColor: (color: string) => void;
  label: string;
}) {
  return (
    <div className="flex flex-col max-w-[400px] flex-1 mb-4">
      <div className="mb-1">{label}</div>
      <div className="flex gap-3">
        {colors.map((color) => {
          const isSelected = color === currentColor;
          return (
            <ColorChooserBlock
              key={color}
              color={color}
              isSelected={isSelected}
              setColor={setCurrentColor}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ColorChooser;
