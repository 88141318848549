import EntryActionTypes from '../constants/EntryActionTypes';
import { SortOrder } from '../types/SortOrder.js';

export const createProject = (data) => ({
  type: EntryActionTypes.PROJECT_CREATE,
  payload: {
    data,
  },
});

export const getScheduledToBeDeletedProjects = () => ({
  type: EntryActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED,
  payload: {},
});

export const handleProjectCreate = (project) => ({
  type: EntryActionTypes.PROJECT_CREATE_HANDLE,
  payload: {
    project,
  },
});

export const updateCurrentProject = (data, redirectTo) => ({
  type: EntryActionTypes.CURRENT_PROJECT_UPDATE,
  payload: {
    data,
    redirectTo,
  },
});

export const duplicateCurrentProject = (data) => ({
  type: EntryActionTypes.CURRENT_PROJECT_DUPLICATE,
  payload: {
    data,
  },
});

export const handleProjectUpdate = (project, projectProfessions) => ({
  type: EntryActionTypes.PROJECT_UPDATE_HANDLE,
  payload: {
    project,
    projectProfessions,
  },
});

export const updateCurrentProjectBackgroundImage = (data) => ({
  type: EntryActionTypes.CURRENT_PROJECT_BACKGROUND_IMAGE_UPDATE,
  payload: {
    data,
  },
});

export const updateCurrentProjectThumbnail = (data) => ({
  type: EntryActionTypes.CURRENT_PROJECT_THUMBNAIL_UPDATE,
  payload: {
    data,
  },
});

export const deleteCurrentProjectThumbnail = (data) => ({
  type: EntryActionTypes.CURRENT_PROJECT_THUMBNAIL_DELETE,
  payload: {
    data,
  },
});

export const deleteCurrentProject = () => ({
  type: EntryActionTypes.CURRENT_PROJECT_DELETE,
  payload: {},
});

export const deleteProject = (projectId: string) => ({
  type: EntryActionTypes.PROJECT_DELETE,
  payload: {
    id: projectId,
  },
});

export const restoreProjectFromTrash = (projectId: string) => ({
  type: EntryActionTypes.PROJECT_RESTORE_FROM_TRASH,
  payload: {
    id: projectId,
  },
});

export const scheduleCurrentProjectForDeletion = () => ({
  type: EntryActionTypes.CURRENT_PROJECT_SCHEDULE_FOR_DELETION,
  payload: {},
});

export const handleProjectDelete = (project) => ({
  type: EntryActionTypes.PROJECT_DELETE_HANDLE,
  payload: {
    project,
  },
});

export const projectFilterUpdate = (
  professionIds: string[],
  sortOrder: SortOrder,
  page: number,
) => ({
  type: EntryActionTypes.PROJECT_FILTER_UPDATE,
  payload: {
    professionIds,
    sortOrder,
    page,
  },
});

export const importProjectFromPadlet = (data) => ({
  type: EntryActionTypes.PROJECT_IMPORT_FROM_PADLET,
  payload: {
    data,
  },
});

export const loadCommunityProjects = (
  professionIds: string[],
  sortOrder: SortOrder,
  page: number,
) => ({
  type: EntryActionTypes.LOAD_COMMUNITY_PROJECTS,
  payload: {
    page,
    sortOrder,
    professionIds,
  },
});

export const cancelProjectImport = (requestId: string) => ({
  type: EntryActionTypes.PROJECT_IMPORT_CANCEL,
  payload: {
    data: {
      requestId,
    },
  },
});

export const addBookmark = (projectId: string) => ({
  type: EntryActionTypes.PROJECT_BOOKMARK_CREATE,
  payload: {
    projectId,
  },
});

export const removeBookmark = (projectId: string) => ({
  type: EntryActionTypes.PROJECT_BOOKMARK_DELETE,
  payload: {
    projectId,
  },
});

export const tryAccessPassword = (url: string, projectId: string, plainTextPassword: string) => ({
  type: EntryActionTypes.PROJECT_TRY_ACCESS_PASSWORD,
  payload: {
    url,
    projectId,
    plainTextPassword,
  },
});
