import EntryActionTypes from '../constants/EntryActionTypes';

export const searchValueUpdate = (searchValue) => ({
  type: EntryActionTypes.SEARCH_VALUE_UPDATE,
  payload: {
    searchValue,
  },
});

export const search = (searchValue) => ({
  type: EntryActionTypes.SEARCH,
  payload: {
    searchValue,
  },
});

export const loadMoreSearchResults = (searchValue: string, page: number) => ({
  type: EntryActionTypes.SEARCH_LOAD_MORE,
  payload: {
    searchValue,
    page,
  },
});

export const expandSearch = () => ({
  type: EntryActionTypes.SEARCH_EXPAND,
  payload: {},
});

export const collapseSearch = () => ({
  type: EntryActionTypes.SEARCH_COLLAPSE,
  payload: {},
});
