export const None = 0;
export const Image = 1;
export const Video = 2;
export const Pdf = 3;
export const Link = 4;
export const OpenGraph = 6;
export const File = 8;
export const EmbedYoutube = 9;
export const EmbedInstagram = 10;
export const EmbedSoundcloud = 11;
export const EmbedTwitter = 12;
export const EmbedGoogleMaps = 13;
export const EmbedPdf = 14;
export const EmbedVimeo = 15;
export const EmbedYoutubeNoCookie = 16;
export const All = 99;
