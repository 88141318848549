/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import Flags from '../../constants/Flags.js';
import ExternalLink from '../ExternalLink/ExternalLink.js';

function OpenGraphInfo({
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl,
  isLarge,
}: {
  ogTitle: string;
  ogDescription: string;
  ogImage: string;
  ogUrl: string;
  isLarge?: boolean;
}) {
  return (
    <ExternalLink
      url={ogUrl}
      className="flex flex-col h-full max-w-full w-full max-h-full contain-content"
    >
      {ogImage && (
        <div
          className={classNames(
            'flex h-full flex-grow contain-content',
            isLarge ? 'min-w-[300px]' : 'min-w-[100px] min-h-[100px]',
          )}
        >
          <img src={ogImage} alt="" className="mb-2 object-contain w-full" />
        </div>
      )}
      <p className="text-sm">{ogTitle}</p>
      {Flags.OPEN_GRAPH_DESCRIPTION && ogDescription && (
        <p className="text-blue100">{ogDescription}</p>
      )}
    </ExternalLink>
  );
}

export default OpenGraphInfo;
