import classnames from 'classnames';
import React from 'react';

const thumbnailMaxWidth = 336;

function TitleSlide({ title, img }: { title: string; img?: string }) {
  return (
    <div className="flex flex-col justify-center items-center align-center h-screen text-5xl hyphens-auto px-8 md:p-24 lg:p-32 gap-2">
      <div className="flex flex-col lg:gap-3 items-center justify-center">
        {img && (
          <div
            className="contain-strict w-full ml-auto mr-auto lg:ml-0"
            style={{
              aspectRatio: '16 / 9',
              maxWidth: thumbnailMaxWidth,
            }}
          >
            <img src={img} alt="" className="" />
          </div>
        )}
        <h1
          className={classnames(
            'font-semibold text-center lg:text-start text-black tracking-tight',
            'md:text-6xl md:tracking-normal',
            'lg:text-8xl lg:tracking-normal',
            '',
          )}
        >
          {title}
        </h1>
      </div>
    </div>
  );
}

export default React.memo(TitleSlide);
