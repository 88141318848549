/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType';
import Button from '../Button/Button';

function BoardImportModalContentInProgress({ onCancel }: { onCancel: () => void }) {
  const [t] = useTranslation();

  return (
    <div className="flex flex-col flex-1">
      <div className="mt-6 flex flex-col">
        <div className="relative h-[100px]">
          <Loader active size="massive" />
        </div>
        <span className="text-center w-full">{t('common.boardIsBeingImported')}</span>
      </div>
      <div className="flex justify-end mt-5">
        <Button type="button" variant={ButtonType.Blue} onClick={onCancel}>
          {t('common.cancelImport')}
        </Button>
      </div>
    </div>
  );
}

export default React.memo(BoardImportModalContentInProgress);
