import { all, takeEvery } from 'redux-saga/effects';
import EntryActionTypes, { SlideShowIndexUpdate } from '../../../constants/EntryActionTypes';
import services from '../services';

export default function* slideshowWatchers() {
  yield all([
    takeEvery<SlideShowIndexUpdate>(
      EntryActionTypes.SLIDESHOW_INDEX_UPDATE,
      ({ payload: { index } }) => services.handleSlideshowIndexUpdate(index),
    ),
  ]);
}
