/* eslint-disable react/button-has-type */
import React, { useState } from 'react';
import { formatProfessions } from '../../utils/formatProfessions';

function DefaultComponent({ children }: { children: React.ReactNode }) {
  return <span>{children}</span>;
}

function TruncatedProfessions({
  professionNames,
  Component = DefaultComponent,
}: {
  professionNames: readonly string[];
  Component?: ({ children }: { children: string }) => React.ReactNode;
}) {
  const { professionsString, remainingProfessionsString } = formatProfessions(professionNames);
  const [showAllProfessions, setShowAllProfessions] = useState(false);
  const handleClickDots = (event: any) => {
    event.preventDefault();
    setShowAllProfessions(true);
  };
  return (
    <div>
      <Component>{professionsString}</Component>
      {remainingProfessionsString && !showAllProfessions && (
        <button
          title={remainingProfessionsString}
          onClick={handleClickDots}
          className="ml-1"
          data-testid="TruncatedProfessionsExpand"
        >
          {' (…)'}
        </button>
      )}
      {remainingProfessionsString && showAllProfessions && (
        <Component>{`, ${remainingProfessionsString}`}</Component>
      )}
    </div>
  );
}

export default TruncatedProfessions;
