import { kebabCase } from 'lodash-es';
import Config from './Config';

export const ROOT = `${Config.BASE_PATH}/`;
export const LOADING_PAGE = `${Config.BASE_PATH}/loading`;
export const ADMIN_LOGIN = `${Config.BASE_PATH}/admin-login`;
export const OIDC_CALLBACK = `${Config.BASE_PATH}/oidc-callback`;
export const PROJECTS = `${Config.BASE_PATH}/projects/:id`;
export const BOARDS = `${Config.BASE_PATH}/boards/:id`;
export const CARDS = `${Config.BASE_PATH}/cards/:id`;
export const SLIDESHOW = `${Config.BASE_PATH}/boards/:id/presentation/:slideNumber?`;
export const BOARD_ACCESS = `${Config.BASE_PATH}/boards/:id/access`;
export const BOARD_IMPORT = `${Config.BASE_PATH}/board-import`;
export const BOARD_SETTINGS = `${Config.BASE_PATH}/boards/:id/settings`;
export const BOARD_DUPLICATE = `${Config.BASE_PATH}/boards/:id/duplicate`;
export const TRASH = `${Config.BASE_PATH}/papierkorb`;
export const IMPRINT = `${Config.BASE_PATH}/impressum`;
export const DATA_PROTECTION = `${Config.BASE_PATH}/datenschutz`;
export const LICENSES = `${Config.BASE_PATH}/licenses`;
export const EXPORT = `${Config.BASE_PATH}/api/projects/:id/export`;
export const SOURCE_CODE = `${Config.BASE_PATH}/source-code.zip`;

export default {
  ROOT,
  LOADING_PAGE,
  ADMIN_LOGIN,
  OIDC_CALLBACK,
  PROJECTS,
  BOARDS,
  CARDS,
  SLIDESHOW,
  BOARD_ACCESS,
  BOARD_SETTINGS,
  IMPRINT,
  DATA_PROTECTION,
  BOARD_DUPLICATE,
  BOARD_IMPORT,
  LICENSES,
  TRASH,
  SOURCE_CODE,
};

export const getBoardPath = (projectName: string, boardId: string) => {
  return BOARDS.replace(':id', `${kebabCase(projectName)}-${boardId}`);
};

export const needsBoard = (path: string) => {
  return [BOARDS, BOARD_DUPLICATE, BOARD_ACCESS, BOARD_SETTINGS, SLIDESHOW].includes(path);
};

export const needsCard = (path: string) => {
  return path === CARDS;
};

export const duplicate = (id: string) => {
  return BOARD_DUPLICATE.replace(':id', id);
};

export const slideShow = (projectName: string, boardId: string) => {
  return SLIDESHOW.replace(':id', `${kebabCase(projectName)}-${boardId}`).replace(
    ':slideNumber',
    '1',
  );
};

export const boardAccess = (boardId: string) => {
  return BOARD_ACCESS.replace(':id', boardId);
};

export const boardSettings = (boardId: string) => {
  return BOARD_SETTINGS.replace(':id', boardId);
};

export const root = () => {
  return ROOT;
};

export const trash = () => {
  return TRASH;
};

export const getExportUrl = (projectId: string) => {
  return EXPORT.replace(':id', projectId);
};
