import * as Semantic from 'semantic-ui-react';

function Form({
  children,
  onSubmit,
  className,
}: {
  children: React.ReactNode;
  onSubmit: () => void;
  className?: string;
}) {
  return (
    <Semantic.Form className={className} onSubmit={onSubmit}>
      {children}
    </Semantic.Form>
  );
}

export default Form;
