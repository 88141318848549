import { selectListIdsForCurrentBoard } from './boards';
import { selectCardById } from './cards';
import { selectCardIdsByListId, selectListById } from './lists';
import { selectCurrentProject } from './projects';
import { selectPath } from './router';

export const selectSlideNumber = (state) => selectPath(state).slideNumber;

export const selectSlideTitles = (state): string[] => {
  const currentProject = selectCurrentProject(state);
  const listIds = selectListIdsForCurrentBoard(state);

  if (currentProject) {
    return [
      currentProject.name,
      ...(listIds.flatMap((listId: string) => {
        const cardIds = selectCardIdsByListId(state, listId);
        return [
          `${currentProject.name} | ${selectListById(state, listId).name}`,
          ...cardIds.map(
            (cardId: string) => `${currentProject.name} | ${selectCardById(state, cardId).name}`,
          ),
        ];
      }) || []),
    ];
  }

  return [];
};

export const selectSlideTitle = (state): string => {
  const slideTitles = selectSlideTitles(state);
  const slideNumber = selectSlideNumber(state);
  return slideTitles[slideNumber - 1];
};
