import { all, call, cancel, fork, select, take } from 'redux-saga/effects';
import services from './services';
import watchers from './watchers';
import ActionTypes from '../../constants/ActionTypes';
import Paths from '../../constants/Paths';
import selectors from '../../selectors/index.js';

export default function* loginSaga() {
  const pathsMatch = yield select(selectors.selectPathsMatch);
  if (
    pathsMatch?.pattern.path !== Paths.ADMIN_LOGIN &&
    pathsMatch?.pattern.path !== Paths.OIDC_CALLBACK
  ) {
    return;
  }

  const watcherTasks = yield all(watchers.map((watcher) => fork(watcher)));

  yield fork(services.initializeLogin);

  yield take([ActionTypes.AUTHENTICATE__SUCCESS, ActionTypes.USING_OIDC_AUTHENTICATE__SUCCESS]);

  yield cancel(watcherTasks);
  const returnTo = window.localStorage.getItem('returnTo');
  window.localStorage.removeItem('returnTo');
  yield call(services.goToUrl, returnTo || Paths.ROOT);
}
