import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Flags from '../../constants/Flags';
import { getTextSync } from '../../utils/getTextSync.js';
import MarkdownPage from '../MarkdownPage/MarkdownPage';

const MATOMO_URL =
  'https://webstats.bibb.de/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=0';

function DataProtectionPage() {
  const [t] = useTranslation();
  const content = getTextSync('/assets/markdown/data-security-de.md');

  useEffect(() => {
    if (Flags.MATOMO) {
      const script = document.createElement('script');

      script.src = MATOMO_URL;
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return undefined;
  }, []);

  return <MarkdownPage title={t('common.dataSecurity')} content={content} __dangerouslyAllowHtml />;
}

export default DataProtectionPage;
