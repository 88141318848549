import { useTranslation } from 'react-i18next';
import { getTextSync } from '../../utils/getTextSync.js';
import MarkdownPage from '../MarkdownPage/MarkdownPage';

function ImprintPage() {
  const [t] = useTranslation();
  const content = getTextSync('/assets/markdown/imprint-de.md');
  return <MarkdownPage title={t('common.imprint')} content={content} />;
}

export default ImprintPage;
