import ActionTypes from '../../constants/ActionTypes';

export const deleteBookmark = (id) => ({
  type: ActionTypes.PROJECT_BOOKMARK_DELETE,
  payload: {
    id,
  },
});

deleteBookmark.success = (bookmark) => ({
  type: ActionTypes.PROJECT_BOOKMARK_DELETE__SUCCESS,
  payload: {
    bookmark,
  },
});

deleteBookmark.failure = (error) => ({
  type: ActionTypes.PROJECT_BOOKMARK_DELETE__FAILURE,
  payload: {
    error,
  },
});
