import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slideshow from '../components/Slideshow/Slideshow';
import entryActions from '../entry-actions';
import selectors from '../selectors';

const mapStateToProps = (state) => {
  const project = selectors.selectCurrentProject(state);
  const board = selectors.selectCurrentBoard(state);
  const listIds = selectors.selectListIdsForCurrentBoard(state) || [];
  const lists = listIds.map((listId: string) => selectors.selectListById(state, listId));
  const cardIdsByListId = new Map<string, string[]>(
    listIds.map((listId: string) => [
      listId,
      selectors.selectNonFutureCardIdsByListId(state, listId),
    ]),
  );
  const cardContentByCardId = new Map(
    Array.from(cardIdsByListId.values())
      .flat()
      .map((cardId: string) => {
        const card = selectors.selectCardById(state, cardId);
        return [cardId, card];
      }),
  );
  const slideNumber = selectors.selectSlideNumber(state);

  return {
    project,
    board,
    lists,
    cardIdsByListId,
    cardContentByCardId,
    slideNumber,
    isMobile: state.root.isMobile,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      goToSlide: entryActions.goToSlide,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Slideshow);
