/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */

import { useTranslation } from 'react-i18next';
import { getFormErrorMessage } from '../../utils/getFormErrorMessage.js';

function FormError({ error }: { error: any }) {
  const [t] = useTranslation();
  const errorMessage = getFormErrorMessage(error, t);
  if (!errorMessage) {
    return null;
  }
  return <div className="bg-orange text-white p-2 mt-2">{errorMessage}</div>;
}

export default FormError;
