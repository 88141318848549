import React from 'react';
import MediaPreviewContainer from '../../containers/MediaPreviewContainer.js';
import { getMediaPreviewType } from '../../utils/getMediaPreviewType.js';
import MediaUploadRemoveButton from '../MediaUploadRemoveButton/MediaUploadRemoveButton.js';

function EmbedPreview({
  url,
  handleDelete,
  canEdit,
  ogImage,
  ogTitle,
  ogDescription,
}: {
  url: string;
  handleDelete: () => void;
  canEdit: boolean;
  ogImage: string;
  ogTitle: string;
  ogDescription: string;
}) {
  const previewType = getMediaPreviewType(url, ogTitle);
  return (
    <div className="flex flex-col relative">
      <div className="flex flex-col items-center flex-1">
        <div className="flex flex-col items-center p-4 w-full bg-white w-full contain-content">
          <MediaPreviewContainer
            url={url}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
            ogImage={ogImage}
          />
        </div>
      </div>
      {canEdit && <MediaUploadRemoveButton previewType={previewType} handleDelete={handleDelete} />}
    </div>
  );
}

export default React.memo(EmbedPreview);
