import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../lib/custom-ui';
import EmbedPreview from '../EmbedPreview/EmbedPreview';

function FormEmbedUpload({
  url,
  setUrl,
  canEdit,
  ogImage,
  ogTitle,
  ogDescription,
}: {
  url: string;
  setUrl: (url: string) => void;
  canEdit: boolean;
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
}) {
  const handleSubmit = (value) => {
    const trimmedUrl = value.trim();
    setUrl(trimmedUrl);
  };

  const handleBlur = (event: any) => {
    handleSubmit(event.target.value);
  };

  const handleEnter = (event: any) => {
    handleSubmit(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleEnter(event);
    }
  };

  const [t] = useTranslation();
  const [currentValue, setCurrentValue] = useState('');
  const handleChange = (event: any) => {
    setCurrentValue(event.target.value);
  };
  const handleDelete = () => {
    setUrl('');
  };
  return (
    <div className="bg-bgGray text-blue100 flex flex-col p-3" data-test-id="FormEmbedUpload">
      {url && (
        <EmbedPreview
          url={url}
          handleDelete={handleDelete}
          canEdit={canEdit}
          ogImage={ogImage}
          ogTitle={ogTitle}
          ogDescription={ogDescription}
        />
      )}
      {!url && canEdit && (
        <div className="flex gap-2">
          <Input
            fluid
            inverted
            name="name"
            value={currentValue}
            onChange={handleChange}
            className="grow"
            placeholder={t('common.insertUrl')}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            data-testid="url"
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(FormEmbedUpload);
