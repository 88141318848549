import React from 'react';
import { Menu } from 'semantic-ui-react';
import { Popup } from '../../lib/custom-ui';

function ContextMenu({ children }: { children: React.ReactNode }) {
  return (
    <Popup.Content>
      <Menu secondary vertical className="m-0 p-0 w-full max-w-[220px]">
        {children}
      </Menu>
    </Popup.Content>
  );
}

export default ContextMenu;
