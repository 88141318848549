import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType';
import { Popup } from '../../lib/custom-ui';
import Button from '../Button/Button';

const DeleteStep = React.memo(
  ({ title, content, buttonContent, onConfirm, onBack = undefined }) => {
    const [t] = useTranslation();

    return (
      <>
        <Popup.Header onBack={onBack}>
          <div className="pt-2.5 text-blue100">
            {t(title, {
              context: 'title',
            })}
          </div>
        </Popup.Header>
        <Popup.Content>
          <div className="p-2 pt-0">
            <div className="text-blue100 pt-0 pb-2">{t(content)}</div>
            <Button variant={ButtonType.Orange} onClick={onConfirm} paddingSmall className="w-full">
              {t(buttonContent)}
            </Button>
          </div>
        </Popup.Content>
      </>
    );
  },
);

DeleteStep.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  buttonContent: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

export default DeleteStep;
