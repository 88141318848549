import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Paths from '../../constants/Paths';

function HeaderLogo() {
  return (
    <Link to={Paths.ROOT} className={classNames('flex items-center')}>
      <img src="/assets/images/logo.svg" alt="MIKA-Do Logo" className="h-6" />
    </Link>
  );
}

export default HeaderLogo;
