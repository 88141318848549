/* eslint-disable no-script-url */

const emptyUrl = '#';

export const getSafeUrl = (url: string) => {
  if (!url) {
    return emptyUrl;
  }
  if (url.startsWith('#')) {
    return url;
  }
  if (url.startsWith('/')) {
    return url;
  }
  if (url.startsWith('javascript:')) {
    return emptyUrl;
  }
  if (url.startsWith('data:')) {
    return emptyUrl;
  }
  if (url.startsWith('https://') || url.startsWith('http://')) {
    return url;
  }
  return `https://${url}`;
};
