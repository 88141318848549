import FormError from '../FormError/FormError';

function SearchResultsError({ error }: { error: any }) {
  return (
    <div className="bg-bgGray flex-1 contain-content p-5 text-blue100 overflow-y-auto">
      <FormError error={error} />
    </div>
  );
}

export default SearchResultsError;
