/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType';
import * as MediaPreviewType from '../../constants/MediaPreviewType';
import Paths from '../../constants/Paths.js';
import { getMediaPreviewName } from '../../utils/getMediaPreviewName';
import Button from '../Button/Button';
import ExternalLink from '../ExternalLink/ExternalLink.js';

function MediaPreviewConsent({
  mediaPreviewType,
  onAllow,
}: {
  mediaPreviewType: number;
  onAllow: (mediaPreviewType: number) => void;
}) {
  const [t] = useTranslation();
  const name = getMediaPreviewName(mediaPreviewType);
  const message = t('common.hintThisWidgetIsExternal').replace('%s', name);
  const subMessage = t('common.forMoreInformationSee');
  const dataPrivacySection = t('common.dataPrivacySection');
  const textAllow = t('common.allowAccessFor').replace('%s', name);
  const textAllowAll = t('common.allowAccessForAll');

  const wrappedOnAllow = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onAllow(mediaPreviewType);
  };
  const wrappedOnAllowAll = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onAllow(MediaPreviewType.All);
  };

  return (
    <div
      data-test-id="MediaPreviewConsent"
      className="border-1 bg-white p-2 border border-black text-left text-xs"
    >
      <p>{message}</p>
      <br />
      <p>
        {subMessage}{' '}
        <ExternalLink url={Paths.DATA_PROTECTION} className="link">
          {dataPrivacySection}
        </ExternalLink>
        .
      </p>
      <br />
      <div className="flex flex-col items-start gap-2 md:flex-row flex-wrap">
        <Button
          variant={ButtonType.Blue}
          type="button"
          onClick={wrappedOnAllow}
          dataTestId="MediaPreviewConsentAllow"
          className="w-full"
          paddingSmall
        >
          {textAllow}
        </Button>
        <Button
          variant={ButtonType.Blue}
          type="button"
          onClick={wrappedOnAllowAll}
          dataTestId="MediaPreviewConsentAllowAll"
          className="w-full"
          paddingSmall
        >
          {textAllowAll}
        </Button>
      </div>
    </div>
  );
}

export default MediaPreviewConsent;
