import { useCallback, useEffect, useRef } from 'react';
import ModalContainer from '../../containers/ModalContainer';
import { useForm } from '../../hooks';
import ProjectAddModalContent from '../ProjectAddModalContent/ProjectAddModalContent';

function ProjectAddModal({
  defaultData,
  isSubmitting,
  onCreate,
  onClose,
  availableProfessions,
  error,
}: {
  defaultData: any;
  isSubmitting: boolean;
  onCreate: (data: any) => void;
  onClose: () => void;
  availableProfessions: any[];
  error: any;
}) {
  const [data, handleFieldChange] = useForm(() => ({
    name: '',
    ...defaultData,
  }));

  const nameField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      name: data.name.trim(),
      isPublic: true,
      professions: data.professions.map((profession) => profession.id),
    };

    if (!cleanData.name) {
      nameField.current.select();
      return;
    }

    onCreate(cleanData);
  }, [onCreate, data]);

  useEffect(() => {
    nameField.current.focus();
  }, []);

  return (
    <ModalContainer open basic size="tiny" onClose={onClose}>
      <ProjectAddModalContent
        handleFieldChange={handleFieldChange}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        nameField={nameField}
        data={data}
        availableProfessions={availableProfessions}
        error={error}
      />
    </ModalContainer>
  );
}

export default ProjectAddModal;
