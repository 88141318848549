/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType.js';
import { Input } from '../../lib/custom-ui';
import Button from '../Button/Button.js';
import FormError from '../FormError/FormError.js';

function BoardDuplicateModalContent({
  isSubmitting,
  handleSubmit,
  nameField,
  data,
  handleFieldChange,
  error,
}: {
  isSubmitting: boolean;
  handleSubmit: () => void;
  nameField: LegacyRef<Input>;
  data: {
    name: string;
  };
  handleFieldChange: (...args: any[]) => void;
  error?: any;
}) {
  const [t] = useTranslation();

  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <img src="/assets/images/icon-add-document-green.svg" alt="" />
        <span className="text-2xl text-blue100 font-bold">{t('common.duplicateBoard')}</span>
      </header>
      <Form onSubmit={handleSubmit} className="mt-2">
        <div>{t('common.enterNewBoardTitle')}</div>
        <Input
          fluid
          inverted
          ref={nameField}
          name="name"
          value={data.name}
          readOnly={isSubmitting}
          onChange={handleFieldChange}
        />

        <FormError error={error} />

        <div className="flex justify-end mt-5">
          <Button type="submit" disabled={isSubmitting} variant={ButtonType.Green}>
            {t('common.ok')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default React.memo(BoardDuplicateModalContent);
