import React, { useCallback } from 'react';
import ModalContainer from '../../containers/ModalContainer';
import { useForm } from '../../hooks';
import BoardPasswordModalContent from '../BoardPasswordModalContent/BoardPasswordModalContent';

function BoardPasswordModal({
  defaultData,
  isSubmitting,
  tryAccess,
  onClose,
  boardId,
  error,
}: {
  defaultData: object;
  isSubmitting: boolean;
  tryAccess: (url: string, projectId: string, plainTextPassword: string) => void;
  onClose: () => void;
  boardId: string;
  error?: any;
}) {
  const [data, handleFieldChange] = useForm(() => ({
    ...defaultData,
  }));

  const handleSubmit = useCallback(() => {
    const cleanData = data;
    tryAccess(window.location.href, boardId, cleanData.plainTextPassword);
  }, [data, tryAccess, boardId]);

  return (
    <ModalContainer open basic size="tiny" onClose={onClose} showCloseButton={false}>
      <BoardPasswordModalContent
        data={data}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handleFieldChange={handleFieldChange}
        error={error}
      />
    </ModalContainer>
  );
}

export default React.memo(BoardPasswordModal);
