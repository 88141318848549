export default {
  /* Consent */
  CONSENT_MEDIA_PREVIEW_ALLOW: 'CONSENT_MEDIA_PREVIEW_ALLOW',

  /* Router */
  LOCATION_CHANGE_HANDLE: 'LOCATION_CHANGE_HANDLE',
  LOCATION_CHANGE_HANDLE__BOARD_FETCH: 'LOCATION_CHANGE_HANDLE__BOARD_FETCH',

  /* Socket */

  SOCKET_DISCONNECT_HANDLE: 'SOCKET_DISCONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE: 'SOCKET_RECONNECT_HANDLE',
  SOCKET_RECONNECT_HANDLE__CORE_FETCH: 'SOCKET_RECONNECT_HANDLE__CORE_FETCH',

  /* Login */

  // this is used for the admin-login page
  LOGIN_INITIALIZE: 'LOGIN_INITIALIZE',
  // this is used for users to login
  LOGIN_INITIALIZE_SSO: 'LOGIN_INITIALIZE_SSO',
  AUTHENTICATE: 'AUTHENTICATE',
  AUTHENTICATE__SUCCESS: 'AUTHENTICATE__SUCCESS',
  AUTHENTICATE__FAILURE: 'AUTHENTICATE__FAILURE',
  USING_OIDC_AUTHENTICATE: 'USING_OIDC_AUTHENTICATE',
  USING_OIDC_AUTHENTICATE__SUCCESS: 'USING_OIDC_AUTHENTICATE__SUCCESS',
  USING_OIDC_AUTHENTICATE__FAILURE: 'USING_OIDC_AUTHENTICATE__FAILURE',
  AUTHENTICATE_ERROR_CLEAR: 'AUTHENTICATE_ERROR_CLEAR',

  /* Core */

  CORE_INITIALIZE: 'CORE_INITIALIZE',
  CORE_INITIALIZE__CONFIG_FETCH: 'CORE_INITIALIZE__CONFIG_FETCH',
  LOGOUT: 'LOGOUT',
  LOGOUT__ACCESS_TOKEN_INVALIDATE: 'LOGOUT__ACCESS_TOKEN_INVALIDATE',

  /* Resize */
  HANDLE_RESIZE: 'HANDLE_RESIZE',

  /* Modals */

  MODAL_OPEN: 'MODAL_OPEN',
  MODAL_CLOSE: 'MODAL_CLOSE',

  /* Users */

  USER_CREATE: 'USER_CREATE',
  USER_CREATE__SUCCESS: 'USER_CREATE__SUCCESS',
  USER_CREATE__FAILURE: 'USER_CREATE__FAILURE',
  USER_CREATE_HANDLE: 'USER_CREATE_HANDLE',
  USER_CREATE_ERROR_CLEAR: 'USER_CREATE_ERROR_CLEAR',
  USER_UPDATE: 'USER_UPDATE',
  USER_UPDATE__SUCCESS: 'USER_UPDATE__SUCCESS',
  USER_UPDATE__FAILURE: 'USER_UPDATE__FAILURE',
  USER_UPDATE_HANDLE: 'USER_UPDATE_HANDLE',
  USER_EMAIL_UPDATE: 'USER_EMAIL_UPDATE',
  USER_EMAIL_UPDATE__SUCCESS: 'USER_EMAIL_UPDATE__SUCCESS',
  USER_EMAIL_UPDATE__FAILURE: 'USER_EMAIL_UPDATE__FAILURE',
  USER_EMAIL_UPDATE_ERROR_CLEAR: 'USER_EMAIL_UPDATE_ERROR_CLEAR',
  USER_PASSWORD_UPDATE: 'USER_PASSWORD_UPDATE',
  USER_PASSWORD_UPDATE__SUCCESS: 'USER_PASSWORD_UPDATE__SUCCESS',
  USER_PASSWORD_UPDATE__FAILURE: 'USER_PASSWORD_UPDATE__FAILURE',
  USER_PASSWORD_UPDATE_ERROR_CLEAR: 'USER_PASSWORD_UPDATE_ERROR_CLEAR',
  USER_USERNAME_UPDATE: 'USER_USERNAME_UPDATE',
  USER_USERNAME_UPDATE__SUCCESS: 'USER_USERNAME_UPDATE__SUCCESS',
  USER_USERNAME_UPDATE__FAILURE: 'USER_USERNAME_UPDATE__FAILURE',
  USER_USERNAME_UPDATE_ERROR_CLEAR: 'USER_USERNAME_UPDATE_ERROR_CLEAR',
  USER_AVATAR_UPDATE: 'USER_AVATAR_UPDATE',
  USER_AVATAR_UPDATE__SUCCESS: 'USER_AVATAR_UPDATE__SUCCESS',
  USER_AVATAR_UPDATE__FAILURE: 'USER_AVATAR_UPDATE__FAILURE',
  USER_DELETE: 'USER_DELETE',
  USER_DELETE__SUCCESS: 'USER_DELETE__SUCCESS',
  USER_DELETE__FAILURE: 'USER_DELETE__FAILURE',
  USER_DELETE_HANDLE: 'USER_DELETE_HANDLE',
  USER_TO_CARD_ADD: 'USER_TO_CARD_ADD',
  USER_TO_CARD_ADD__SUCCESS: 'USER_TO_CARD_ADD__SUCCESS',
  USER_TO_CARD_ADD__FAILURE: 'USER_TO_CARD_ADD__FAILURE',
  USER_TO_CARD_ADD_HANDLE: 'USER_TO_CARD_ADD_HANDLE',
  USER_FROM_CARD_REMOVE: 'USER_FROM_CARD_REMOVE',
  USER_FROM_CARD_REMOVE__SUCCESS: 'USER_FROM_CARD_REMOVE__SUCCESS',
  USER_FROM_CARD_REMOVE__FAILURE: 'USER_FROM_CARD_REMOVE__FAILURE',
  USER_FROM_CARD_REMOVE_HANDLE: 'USER_FROM_CARD_REMOVE_HANDLE',
  USER_TO_BOARD_FILTER_ADD: 'USER_TO_BOARD_FILTER_ADD',
  USER_FROM_BOARD_FILTER_REMOVE: 'USER_FROM_BOARD_FILTER_REMOVE',

  /* Projects */

  PROJECT_CREATE: 'PROJECT_CREATE',
  PROJECT_CREATE__SUCCESS: 'PROJECT_CREATE__SUCCESS',
  PROJECT_CREATE__FAILURE: 'PROJECT_CREATE__FAILURE',
  PROJECT_CREATE_HANDLE: 'PROJECT_CREATE_HANDLE',
  PROJECT_UPDATE: 'PROJECT_UPDATE',
  PROJECT_UPDATE__SUCCESS: 'PROJECT_UPDATE__SUCCESS',
  PROJECT_UPDATE__FAILURE: 'PROJECT_UPDATE__FAILURE',
  PROJECT_IMPORT: 'PROJECT_IMPORT',
  PROJECT_IMPORT__SUCCESS: 'PROJECT_IMPORT__SUCCESS',
  PROJECT_IMPORT__FAILURE: 'PROJECT_IMPORT__FAILURE',
  PROJECT_IMPORT_CANCEL: 'PROJECT_IMPORT_CANCEL',
  PROJECT_IMPORT_CANCEL__SUCCESS: 'PROJECT_IMPORT_CANCEL__SUCCESS',
  PROJECT_IMPORT_CANCEL__FAILURE: 'PROJECT_IMPORT_CANCEL__FAILURE',
  PROJECT_DUPLICATE: 'PROJECT_DUPLICATE',
  PROJECT_DUPLICATE__SUCCESS: 'PROJECT_DUPLICATE__SUCCESS',
  PROJECT_DUPLICATE__FAILURE: 'PROJECT_DUPLICATE__FAILURE',
  PROJECT_UPDATE_HANDLE: 'PROJECT_UPDATE_HANDLE',
  PROJECT_BACKGROUND_IMAGE_UPDATE: 'PROJECT_BACKGROUND_IMAGE_UPDATE',
  PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS: 'PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS',
  PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE: 'PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE',
  PROJECT_THUMBNAIL_UPDATE: 'PROJECT_THUMBNAIL_UPDATE',
  PROJECT_THUMBNAIL_UPDATE__SUCCESS: 'PROJECT_THUMBNAIL_UPDATE__SUCCESS',
  PROJECT_THUMBNAIL_UPDATE__FAILURE: 'PROJECT_THUMBNAIL_UPDATE__FAILURE',
  PROJECT_THUMBNAIL_DELETE: 'PROJECT_THUMBNAIL_DELETE',
  PROJECT_THUMBNAIL_DELETE__SUCCESS: 'PROJECT_THUMBNAIL_DELETE__SUCCESS',
  PROJECT_THUMBNAIL_DELETE__FAILURE: 'PROJECT_THUMBNAIL_DELETE__FAILURE',
  PROJECT_DELETE: 'PROJECT_DELETE',
  PROJECT_DELETE__SUCCESS: 'PROJECT_DELETE__SUCCESS',
  PROJECT_DELETE__FAILURE: 'PROJECT_DELETE__FAILURE',
  PROJECT_SCHEDULE_FOR_DELETION: 'PROJECT_SCHEDULE_FOR_DELETION',
  PROJECT_SCHEDULE_FOR_DELETION__SUCCESS: 'PROJECT_SCHEDULE_FOR_DELETION__SUCCESS',
  PROJECT_SCHEDULE_FOR_DELETION__FAILURE: 'PROJECT_SCHEDULE_FOR_DELETION__FAILURE',
  PROJECT_RESTORE_FROM_TRASH: 'PROJECT_RESTORE_FROM_TRASH',
  PROJECT_RESTORE_FROM_TRASH__SUCCESS: 'PROJECT_RESTORE_FROM_TRASH__SUCCESS',
  PROJECT_RESTORE_FROM_TRASH__FAILURE: 'PROJECT_RESTORE_FROM_TRASH__FAILURE',
  PROJECT_DELETE_HANDLE: 'PROJECT_DELETE_HANDLE',
  LOAD_COMMUNITY_PROJECTS: 'LOAD_COMMUNITY_PROJECTS',
  LOAD_COMMUNITY_PROJECTS__SUCCESS: 'LOAD_COMMUNITY_PROJECTS__SUCCESS',
  LOAD_COMMUNITY_PROJECTS__FAILURE: 'LOAD_COMMUNITY_PROJECTS__FAILURE',
  PROJECT_BOOKMARK_CREATE: 'PROJECT_BOOKMARK_CREATE',
  PROJECT_BOOKMARK_CREATE__SUCCESS: 'PROJECT_BOOKMARK_CREATE__SUCCESS',
  PROJECT_BOOKMARK_CREATE__FAILURE: 'PROJECT_BOOKMARK_CREATE__FAILURE',
  PROJECT_BOOKMARK_DELETE: 'PROJECT_BOOKMARK_DELETE',
  PROJECT_BOOKMARK_DELETE__SUCCESS: 'PROJECT_BOOKMARK_DELETE__SUCCESS',
  PROJECT_BOOKMARK_DELETE__FAILURE: 'PROJECT_BOOKMARK_DELETE__FAILURE',
  PROJECT_TRY_ACCESS_PASSWORD: `PROJECT_TRY_ACCESS_PASSWORD`,
  PROJECT_TRY_ACCESS_PASSWORD__SUCCESS: `PROJECT_TRY_ACCESS_PASSWORD__SUCCESS`,
  PROJECT_TRY_ACCESS_PASSWORD__FAILURE: `PROJECT_TRY_ACCESS_PASSWORD__FAILURE`,
  PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT: `PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT`,
  PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__SUCCESS: `PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__SUCCESS`,
  PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__FAILURE: `PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__FAILURE`,

  /* Project managers */

  PROJECT_MANAGER_CREATE: 'PROJECT_MANAGER_CREATE',
  PROJECT_MANAGER_CREATE__SUCCESS: 'PROJECT_MANAGER_CREATE__SUCCESS',
  PROJECT_MANAGER_CREATE__FAILURE: 'PROJECT_MANAGER_CREATE__FAILURE',
  PROJECT_MANAGER_CREATE_HANDLE: 'PROJECT_MANAGER_CREATE_HANDLE',
  PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH: 'PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH',
  PROJECT_MANAGER_DELETE: 'PROJECT_MANAGER_DELETE',
  PROJECT_MANAGER_DELETE__SUCCESS: 'PROJECT_MANAGER_DELETE__SUCCESS',
  PROJECT_MANAGER_DELETE__FAILURE: 'PROJECT_MANAGER_DELETE__FAILURE',
  PROJECT_MANAGER_DELETE_HANDLE: 'PROJECT_MANAGER_DELETE_HANDLE',

  /* Boards */

  BOARD_CREATE: 'BOARD_CREATE',
  BOARD_CREATE__SUCCESS: 'BOARD_CREATE__SUCCESS',
  BOARD_CREATE__FAILURE: 'BOARD_CREATE__FAILURE',
  BOARD_CREATE_HANDLE: 'BOARD_CREATE_HANDLE',
  BOARD_FETCH: 'BOARD_FETCH',
  BOARD_FETCH__SUCCESS: 'BOARD_FETCH__SUCCESS',
  BOARD_FETCH__FAILURE: 'BOARD_FETCH__FAILURE',
  BOARD_UPDATE: 'BOARD_UPDATE',
  BOARD_UPDATE__SUCCESS: 'BOARD_UPDATE__SUCCESS',
  BOARD_UPDATE__FAILURE: 'BOARD_UPDATE__FAILURE',
  BOARD_UPDATE_HANDLE: 'BOARD_UPDATE_HANDLE',
  BOARD_DELETE: 'BOARD_DELETE',
  BOARD_DELETE__SUCCESS: 'BOARD_DELETE__SUCCESS',
  BOARD_DELETE__FAILURE: 'BOARD_DELETE__FAILURE',
  BOARD_DELETE_HANDLE: 'BOARD_DELETE_HANDLE',
  BOARD_SET_SCROLL_MODE: `BOARD_SET_SCROLL_MODE`,

  /* Board memberships */

  BOARD_MEMBERSHIP_CREATE: 'BOARD_MEMBERSHIP_CREATE',
  BOARD_MEMBERSHIP_CREATE__SUCCESS: 'BOARD_MEMBERSHIP_CREATE__SUCCESS',
  BOARD_MEMBERSHIP_CREATE__FAILURE: 'BOARD_MEMBERSHIP_CREATE__FAILURE',
  BOARD_MEMBERSHIP_CREATE_HANDLE: 'BOARD_MEMBERSHIP_CREATE_HANDLE',
  BOARD_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH: 'BOARD_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH',
  BOARD_MEMBERSHIP_UPDATE: 'BOARD_MEMBERSHIP_UPDATE',
  BOARD_MEMBERSHIP_UPDATE__SUCCESS: 'BOARD_MEMBERSHIP_UPDATE__SUCCESS',
  BOARD_MEMBERSHIP_UPDATE__FAILURE: 'BOARD_MEMBERSHIP_UPDATE__FAILURE',
  BOARD_MEMBERSHIP_UPDATE_HANDLE: 'BOARD_MEMBERSHIP_UPDATE_HANDLE',
  BOARD_MEMBERSHIP_DELETE: 'BOARD_MEMBERSHIP_DELETE',
  BOARD_MEMBERSHIP_DELETE__SUCCESS: 'BOARD_MEMBERSHIP_DELETE__SUCCESS',
  BOARD_MEMBERSHIP_DELETE__FAILURE: 'BOARD_MEMBERSHIP_DELETE__FAILURE',
  BOARD_MEMBERSHIP_DELETE_HANDLE: 'BOARD_MEMBERSHIP_DELETE_HANDLE',

  /* Lists */

  LIST_CREATE: 'LIST_CREATE',
  LIST_CREATE__SUCCESS: 'LIST_CREATE__SUCCESS',
  LIST_CREATE__FAILURE: 'LIST_CREATE__FAILURE',
  LIST_CREATE_HANDLE: 'LIST_CREATE_HANDLE',
  LIST_UPDATE: 'LIST_UPDATE',
  LIST_UPDATE__SUCCESS: 'LIST_UPDATE__SUCCESS',
  LIST_UPDATE__FAILURE: 'LIST_UPDATE__FAILURE',
  LIST_UPDATE_HANDLE: 'LIST_UPDATE_HANDLE',
  LIST_DELETE: 'LIST_DELETE',
  LIST_DELETE__SUCCESS: 'LIST_DELETE__SUCCESS',
  LIST_DELETE__FAILURE: 'LIST_DELETE__FAILURE',
  LIST_DELETE_HANDLE: 'LIST_DELETE_HANDLE',

  /* Cards */

  CARD_CREATE: 'CARD_CREATE',
  CARD_CREATE__SUCCESS: 'CARD_CREATE__SUCCESS',
  CARD_CREATE__FAILURE: 'CARD_CREATE__FAILURE',
  CARD_CREATE_HANDLE: 'CARD_CREATE_HANDLE',
  CARD_FETCH: 'CARD_FETCH',
  CARD_FETCH__SUCCESS: 'CARD_FETCH__SUCCESS',
  CARD_FETCH__FAILURE: 'CARD_FETCH__FAILURE',
  CARD_UPDATE: 'CARD_UPDATE',
  CARD_UPDATE__SUCCESS: 'CARD_UPDATE__SUCCESS',
  CARD_UPDATE__FAILURE: 'CARD_UPDATE__FAILURE',
  CARD_UPDATE_HANDLE: 'CARD_UPDATE_HANDLE',
  CARD_TRANSFER: 'CARD_TRANSFER',
  CARD_TRANSFER__SUCCESS: 'CARD_TRANSFER__SUCCESS',
  CARD_TRANSFER__FAILURE: 'CARD_TRANSFER__FAILURE',
  CARD_DELETE: 'CARD_DELETE',
  CARD_DELETE__SUCCESS: 'CARD_DELETE__SUCCESS',
  CARD_DELETE__FAILURE: 'CARD_DELETE__FAILURE',
  CARD_DELETE_HANDLE: 'CARD_DELETE_HANDLE',
  CARD_DUPLICATE: 'CARD_DUPLICATE',
  CARD_DUPLICATE__SUCCESS: 'CARD_DUPLICATE__SUCCESS',
  CARD_DUPLICATE__FAILURE: 'CARD_DUPLICATE__FAILURE',

  /* Attachments */

  ATTACHMENT_CREATE: 'ATTACHMENT_CREATE',
  ATTACHMENT_CREATE__SUCCESS: 'ATTACHMENT_CREATE__SUCCESS',
  ATTACHMENT_CREATE__FAILURE: 'ATTACHMENT_CREATE__FAILURE',
  ATTACHMENT_CREATE_HANDLE: 'ATTACHMENT_CREATE_HANDLE',
  ATTACHMENT_UPDATE: 'ATTACHMENT_UPDATE',
  ATTACHMENT_UPDATE__SUCCESS: 'ATTACHMENT_UPDATE__SUCCESS',
  ATTACHMENT_UPDATE__FAILURE: 'ATTACHMENT_UPDATE__FAILURE',
  ATTACHMENT_UPDATE_HANDLE: 'ATTACHMENT_UPDATE_HANDLE',
  ATTACHMENT_DELETE: 'ATTACHMENT_DELETE',
  ATTACHMENT_DELETE__SUCCESS: 'ATTACHMENT_DELETE__SUCCESS',
  ATTACHMENT_DELETE__FAILURE: 'ATTACHMENT_DELETE__FAILURE',
  ATTACHMENT_DELETE_HANDLE: 'ATTACHMENT_DELETE_HANDLE',

  /* Global Error */

  GLOBAL_ERROR: 'GLOBAL_ERROR',

  /* Professions */
  PROFESSION_TO_PROJECT_ADD_HANDLE: 'PROFESSION_TO_PROJECT_ADD_HANDLE',
  PROFESSION_FROM_PROJECT_REMOVE_HANDLE: 'PROFESSION_FROM_PROJECT_REMOVE_HANDLE',

  /* Profession filter */
  PROJECT_FILTER_UPDATE: 'PROJECT_FILTER_UPDATE',

  /* Search */
  SEARCH: 'SEARCH',
  SEARCH__SUCCESS: 'SEARCH__SUCCESS',
  SEARCH__FAILURE: 'SEARCH__FAILURE',
  SEARCH_LOAD_MORE: 'SEARCH_LOAD_MORE',
  SEARCH_LOAD_MORE__SUCCESS: 'SEARCH_LOAD_MORE__SUCCESS',
  SEARCH_LOAD_MORE__FAILURE: 'SEARCH_LOAD_MORE__FAILURE',
  SEARCH_RESTORE: 'SEARCH_RESTORE',
  SEARCH_VALUE_UPDATE: 'SEARCH_VALUE_UPDATE',

  /* Header */
  HEADER_SEARCH_EXPAND: 'HEADER_SEARCH_EXPAND',
  HEADER_SEARCH_COLLAPSE: 'HEADER_SEARCH_COLLAPSE',
} as const;
