import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  items: [],
  error: null,
  itemError: null,
};

const updateItemsRemovingRestoredOrDeletedItemFromTheList = (items: any, restoredId: string) => {
  return items.filter((item) => item.id !== restoredId);
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__SUCCESS:
      return {
        ...state,
        ...payload,
        error: null,
      };
    case ActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    case ActionTypes.PROJECT_RESTORE_FROM_TRASH__SUCCESS:
    case ActionTypes.PROJECT_DELETE__SUCCESS:
      return {
        ...state,
        items: updateItemsRemovingRestoredOrDeletedItemFromTheList(state.items, payload.project.id),
        itemError: null,
      };
    case ActionTypes.PROJECT_RESTORE_FROM_TRASH__FAILURE:
    case ActionTypes.PROJECT_DELETE__FAILURE:
      return {
        ...state,
        itemError: payload.error,
      };
    default:
      return state;
  }
};
