import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Icon, Loader } from 'semantic-ui-react';
import styles from './Static.module.scss';
import ProjectsContainer from '../../containers/ProjectsContainer';
import SearchContainer from '../../containers/SearchContainer';
import Background from '../Background/index';
import BoardAccessDenied from '../BoardAccessDenied/BoardAccessDenied';
import BoardNotFound from '../BoardNotFound/BoardNotFound';
import BoardPasswordPage from '../BoardPasswordPage/BoardPasswordPage';
import BoardScheduledForDeletion from '../BoardScheduledForDeletion/BoardScheduledForDeletion.js';
import BoardWithBoardInfo from '../BoardWithBoardInfo/BoardWithBoardInfo';
import ErrorComponent from '../ErrorComponent/ErrorComponent';
import DataProtectionPage from '../MarkdownDataProtectionPage/MarkdownDataProtectionPage';
import ImprintPage from '../MarkdownImprintPage/MarkdownImprintPage';
import LicensesPage from '../MarkdownLicensesPage/MarkdownLicensesPage';
import NotFound from '../NotFound/NotFound';
import TrashPage from '../TrashPage/TrashPage';

const getBoardErrorString = (error) => {
  if (!error) {
    return 'unknown error';
  }
  let errorString = `${error}`;
  if (errorString.startsWith('Error: Error')) {
    errorString = errorString.slice('Error: '.length);
  }
  return errorString;
};

function Static({
  isTrash,
  isDataProtection,
  isImprint,
  theme,
  boardScrollFull,
  projectId = undefined,
  cardId = undefined,
  board = undefined,
  project = undefined,
  isSearch = false,
  isImport = false,
  isNotFound = false,
  isLicenses = false,
}) {
  const [t] = useTranslation();

  if (isNotFound) {
    return <NotFound />;
  }

  if (isTrash) {
    return (
      <div className={styles.wrapper}>
        <TrashPage />
      </div>
    );
  }

  if (isImprint) {
    return <ImprintPage />;
  }

  if (isLicenses) {
    return <LicensesPage />;
  }

  if (isDataProtection) {
    return <DataProtectionPage />;
  }

  if (isSearch) {
    // @ts-ignore
    return <SearchContainer />;
  }

  if (board?.isScheduledForDeletion) {
    return <BoardScheduledForDeletion />;
  }

  if (board?.isAccessDenied) {
    return <BoardAccessDenied />;
  }

  if (board?.isPasswordRequired) {
    return <BoardPasswordPage />;
  }

  if (board?.error) {
    const errorString = getBoardErrorString(board.error);
    return <ErrorComponent heading={errorString} image="" />;
  }

  if (board?.isFetching) {
    return (
      <div className={classNames(styles.wrapper, styles.wrapperLoader, styles.wrapperProject)}>
        <Loader active size="big" />
      </div>
    );
  }

  if (projectId === undefined || isImport) {
    return (
      <div className={styles.wrapper}>
        <ProjectsContainer />
      </div>
    );
  }

  if (cardId === null) {
    return (
      <div className={classNames(styles.wrapper, styles.wrapperFlex)}>
        <NotFound
          heading={t('common.cardNotFound', {
            context: 'title',
          })}
        />
      </div>
    );
  }

  if (board === null || projectId === null) {
    return (
      <div className={classNames(styles.wrapper, styles.wrapperFlex)}>
        <BoardNotFound />
      </div>
    );
  }

  if (board === undefined) {
    return (
      <div className={classNames(styles.wrapper, styles.wrapperFlex, styles.wrapperProject)}>
        <div className={styles.message}>
          <Icon inverted name="hand point up outline" size="huge" className={styles.messageIcon} />
          <h1 className={styles.messageTitle}>
            {t('common.openBoard', {
              context: 'title',
            })}
          </h1>
          <div className={styles.messageContent}>
            <Trans i18nKey="common.createNewOneOrSelectExistingOne" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.wrapper, styles.wrapperFlex, styles.wrapperBoard)}>
      <Background
        type={project?.background?.type}
        name={project?.background?.name}
        imageUrl={project?.backgroundImage?.url}
      />
      <BoardWithBoardInfo theme={theme} boardScrollFull={boardScrollFull} />
    </div>
  );
}

Static.propTypes = {
  projectId: PropTypes.string,
  isTrash: PropTypes.bool,
  cardId: PropTypes.string,
  board: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  project: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isSearch: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  isImprint: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  isDataProtection: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  isImport: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  isNotFound: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  isLicenses: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
  theme: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  boardScrollFull: PropTypes.bool, // eslint-disable-line react/forbid-prop-types
};

export default Static;
