import React from 'react';
import { useTranslation } from 'react-i18next';
import FormError from '../FormError/FormError';
import Heading from '../Heading/Heading';
import ProjectsList from '../ProjectsList/ProjectsList';
import SimpleAlert from '../SimpleAlert/SimpleAlert.js';

function Trash({
  projects,
  error,
  restoreProjectFromTrash,
  deleteProjectFromTrash,
  itemError,
}: {
  projects: any[];
  error?: any;
  restoreProjectFromTrash: (projectId: string) => void;
  deleteProjectFromTrash: (projectId: string) => void;
  itemError?: any;
}) {
  const [t] = useTranslation();

  const menuItems = [
    {
      label: t('action.deleteProjectPermanently'),
      action: deleteProjectFromTrash,
    },
    {
      label: t('action.restoreProjectFromTrash'),
      action: restoreProjectFromTrash,
    },
  ];

  return (
    <div className="contain-content w-full h-full flex flex-col bg-bgGray">
      <main className="overflow-y-auto grow pt-6 px-2 lg:pt-8">
        <div className="md:mx-auto max-w-screen-lg">
          <Heading text={t('common.trash')} />

          {itemError && <SimpleAlert error={itemError} />}
          {error && <FormError error={error} />}

          {projects.length === 0 && <p className="mt-1">{t('common.noTrashProjectsInTrash')}</p>}
          {projects.length > 0 && (
            <p className="mt-1 mb-1">{t('common.trashDeletedAfter14Days')}</p>
          )}
          <ProjectsList items={projects} menuItems={menuItems} />
        </div>
      </main>
    </div>
  );
}

export default React.memo(Trash);
