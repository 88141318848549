import ActionTypes from '../../constants/ActionTypes';

export const deleteProjectThumbnail = (id) => ({
  type: ActionTypes.PROJECT_THUMBNAIL_DELETE,
  payload: {
    id,
  },
});

deleteProjectThumbnail.success = (project) => ({
  type: ActionTypes.PROJECT_THUMBNAIL_DELETE__SUCCESS,
  payload: {
    project,
  },
});

deleteProjectThumbnail.failure = (id, error) => ({
  type: ActionTypes.PROJECT_THUMBNAIL_DELETE__FAILURE,
  payload: {
    id,
    error,
  },
});
