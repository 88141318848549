/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import classNames from 'classnames';

function ColorChooserBlock({
  color,
  setColor,
  isSelected,
}: {
  color: string;
  setColor: (color: string) => void;
  isSelected: boolean;
}) {
  const handleClick = () => {
    setColor(color);
  };
  return (
    <button
      className={classNames(isSelected ? 'border-4 border-black' : 'border-4 border-transparent')}
      style={{
        background: color,
      }}
      title={color}
      onClick={handleClick}
      type="button"
    >
      <div
        className={classNames('flex w-[35px] h-[35px]')}
        style={{
          background: color,
        }}
        title={color}
      />
    </button>
  );
}

export default ColorChooserBlock;
