/* eslint-disable no-unsafe-finally */
import { call, cancel, fork, put, take } from 'redux-saga/effects';
import { importProjectCancelApi } from './importProjectCancel';
import actions from '../../../../actions';
import api from '../../../../api';
import ActionTypes from '../../../../constants/ActionTypes';
import { createLocalId } from '../../../../utils/local-id';
import request from '../../request';

function* makeImportRequest(data, requestId) {
  try {
    const {
      item: project,
      canceled,
      included: { projectManagers, projectProfessions, boards, boardMemberships, lists, cards },
    } = yield call(request, api.importProjectFromPadlet, {
      ...data,
      requestId,
    });
    if (canceled) {
      return;
    }
    yield put(
      actions.importProjectFromPadlet.success(
        project,
        boards,
        lists,
        cards,
        projectProfessions,
        boardMemberships,
        projectManagers,
      ),
    );
  } catch (error) {
    yield put(actions.importProjectFromPadlet.failure(error));
  }
}

export function* importProjectFromPadlet(data: { url: string }) {
  const requestId = yield call(createLocalId);
  yield put(
    actions.importProjectFromPadlet({
      ...data,
      requestId,
    }),
  );

  try {
    const task = yield fork(makeImportRequest, data, requestId);
    const action = yield take([ActionTypes.PROJECT_IMPORT_CANCEL]);
    if (action.type === ActionTypes.PROJECT_IMPORT_CANCEL) {
      cancel(task);
      yield call(importProjectCancelApi, { requestId });
    }
  } finally {
    //  ignore
  }
}
