import * as selectors from './router';
import ModalTypes from '../constants/ModalTypes';

export const selectCurrentModal = (state) => {
  const {
    core: { modals },
  } = state;
  const path = selectors.selectPath(state);
  const { isImport } = path;
  if (isImport) {
    return {
      modals: [
        {
          type: ModalTypes.BOARD_IMPORT,
        },
      ],
    };
  }
  return {
    modals,
  };
};
