/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ButtonType from '../../constants/ButtonType';
import Button from '../Button/Button';

function BoardImportModalContent({ boardUrl }: { boardUrl: string }) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const onClickToBoard = () => {
    navigate(boardUrl);
  };
  return (
    <div className="pt-6 flex flex-col flex-1">
      <div>
        <p>{t('common.importSuccessful')}</p>
        <p>{t('common.youCanNowEditBoard')}</p>
      </div>
      <div className="mt-2">
        <small>{t('common.pleaseCheckBoardHint')}</small>
      </div>
      <div className="mt-auto" />
      <Button type="button" variant={ButtonType.Green} onClick={onClickToBoard} className="ml-auto">
        {t('common.toBoard')}
      </Button>
    </div>
  );
}

export default React.memo(BoardImportModalContent);
