/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { isImage } from '../../utils/isImage';
import ExternalLink from '../ExternalLink/ExternalLink';

function MediaPreviewImage({
  url,
  onClick,
  mediaLinkUrl,
}: {
  url: string;
  onClick?: (url: string) => void;
  mediaLinkUrl?: string;
}) {
  const wrappedHandleClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(url);
  };

  const image = (
    <img
      src={url}
      alt=""
      className="h-full max-h-full object-contain min-h-[100px] min-w-[100px] w-full"
    />
  );
  if (mediaLinkUrl && !isImage(mediaLinkUrl)) {
    return (
      <ExternalLink className="flex h-full max-h-full contain-content" url={mediaLinkUrl}>
        {image}
      </ExternalLink>
    );
  }
  return (
    <div
      className="flex h-full max-h-full contain-content flex-1"
      data-testid="MediaPreviewImage"
      onClick={wrappedHandleClick}
    >
      {image}
    </div>
  );
}

export default MediaPreviewImage;
