import HeaderSearchContainer from '../../containers/HeaderSearchContainer';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HeaderMenu from '../HeaderMenu/HeaderMenu';
import HeaderMobileBackButton from '../HeaderMobileBackButton/HeaderMobileBackButton';
import HeaderSearchButton from '../HeaderSearchButton/HeaderSearchButton';

function HeaderMobile({
  searchExpanded,
  expandSearch,
  collapseSearch,
  openAbout,
  onLogout,
  onAuthenticateUsingOidc,
  isLoggedIn,
  board,
  showSearch,
  project,
  addBookmark,
  removeBookmark,
  user,
  canEdit,
  showScrollChange,
  toggleScrollMode,
  boardScrollFull,
  handleDelete,
  canDelete,
  isPasswordProtected,
}: {
  openAbout: () => void;
  onLogout: () => void;
  searchExpanded: boolean;
  expandSearch: () => void;
  handleDelete: () => void;
  collapseSearch: () => void;
  onAuthenticateUsingOidc: () => void;
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
  isLoggedIn: boolean;
  board: any;
  project: any;
  showSearch: boolean;
  canEdit: boolean;
  user: any;
  showScrollChange: boolean;
  toggleScrollMode: () => void;
  boardScrollFull: boolean;
  canDelete: boolean;
  isPasswordProtected: boolean;
}) {
  const handleClickSearch = searchExpanded ? collapseSearch : expandSearch;
  return (
    <div className="flex flex-col p-2">
      <div className="h-12 flex items-center contain-strict">
        {!project && <HeaderLogo />}

        {project && <HeaderMobileBackButton />}

        {project && <div className="text-blue100 truncate pr-1">{project.name}</div>}

        <div className="gap-2 flex ml-auto shrink-0">
          {showSearch && <HeaderSearchButton onClick={handleClickSearch} />}
          <HeaderMenu
            openAbout={openAbout}
            onLogout={onLogout}
            isLoggedIn={isLoggedIn}
            onAuthenticateUsingOidc={onAuthenticateUsingOidc}
            board={board}
            project={project}
            addBookmark={addBookmark}
            removeBookmark={removeBookmark}
            user={user}
            canEdit={canEdit}
            isMobile
            showScrollChange={showScrollChange}
            toggleScrollMode={toggleScrollMode}
            boardScrollFull={boardScrollFull}
            handleDelete={handleDelete}
            canDelete={canDelete}
            isPasswordProtected={isPasswordProtected}
            position="top right"
          />
        </div>
      </div>

      {showSearch && searchExpanded && (
        <div>
          {/* @ts-ignore */}
          <HeaderSearchContainer />
        </div>
      )}
    </div>
  );
}

export default HeaderMobile;
