import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function CalendarButton({ className, onClick }: { className?: string; onClick?: () => void }) {
  const [t] = useTranslation();
  return (
    <button
      className={classNames(className)}
      aria-label={t('common.schedule')}
      onClick={onClick}
      type="button"
    >
      <img src="/assets/images/icon-calendar.svg" alt="" className="w-6 h-6" />
    </button>
  );
}

export default CalendarButton;
