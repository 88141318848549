export default function highlightText(
  text: string | null | undefined,
  highlightStartToken = '__start__',
  highlightEndToken = '__end__',
) {
  const fragments =
    text?.split(new RegExp(`(${highlightStartToken}|${highlightEndToken})`, 'g')) ?? [];
  let highlight = false;
  const highlighted = [];
  fragments.forEach((fragment, index) => {
    switch (fragment) {
      case highlightStartToken:
        highlight = true;
        break;
      case highlightEndToken:
        highlight = false;
        break;
      case '':
        break;
      default:
        highlighted.push({ text: fragment, highlight });
    }
  });
  return highlighted;
}
