import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import Paths from '../constants/Paths';
import CoreContainer from '../containers/CoreContainer';
import LoadingContainer from '../containers/LoadingContainer';
import LoginWrapperContainer from '../containers/LoginWrapperContainer';
import { ReduxRouter } from '../lib/redux-router';

import '../lib/custom-ui/styles.css';
import '../styles.module.scss';

function Root({ store, history }: { store: any; history: any }) {
  return (
    <div className="h-full flex flex-col overflow-y-auto">
      <Provider store={store}>
        <ReduxRouter history={history}>
          <Routes>
            <Route path={Paths.ADMIN_LOGIN} element={<LoginWrapperContainer />} />
            <Route path={Paths.OIDC_CALLBACK} element={<LoginWrapperContainer />} />
            <Route path={Paths.LOADING_PAGE} element={<LoadingContainer />} />
            <Route path={Paths.ROOT} element={<CoreContainer />} />
            <Route path={Paths.PROJECTS} element={<CoreContainer />} />
            <Route path={Paths.BOARD_IMPORT} element={<CoreContainer />} />
            <Route path={Paths.BOARDS} element={<CoreContainer />} />
            <Route path={Paths.BOARD_ACCESS} element={<CoreContainer />} />
            <Route path={Paths.BOARD_SETTINGS} element={<CoreContainer />} />
            <Route path={Paths.BOARD_DUPLICATE} element={<CoreContainer />} />
            <Route path={Paths.CARDS} element={<CoreContainer />} />
            <Route path={Paths.SLIDESHOW} element={<CoreContainer />} />
            <Route path={Paths.IMPRINT} element={<CoreContainer />} />
            <Route path={Paths.DATA_PROTECTION} element={<CoreContainer />} />
            <Route path={Paths.LICENSES} element={<CoreContainer />} />
            <Route path={Paths.TRASH} element={<CoreContainer />} />
            <Route path="*" element={<CoreContainer />} />
          </Routes>
        </ReduxRouter>
      </Provider>
    </div>
  );
}

export default Root;
