import * as plainTextPasswordStorage from './plainTextPasswordStorage';

const getKey = (boardId: string) => {
  return `mikado:plaintext-password-${boardId}`;
};

export const getPlainTextPassword = (boardId: string) => {
  const key = getKey(boardId);
  return plainTextPasswordStorage.getItem(key);
};

export const setPlainTextPassword = (boardId: string, plainTextPassword: string) => {
  const key = getKey(boardId);
  return plainTextPasswordStorage.setItem(key, plainTextPassword);
};

export const removePlainTextPassword = (boardId: string) => {
  const key = getKey(boardId);
  plainTextPasswordStorage.removeItem(key);
};
