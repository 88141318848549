/* eslint-disable react/jsx-props-no-spreading */
import Flags from '../../constants/Flags';
import ContentPreviewMde from '../ContentPreviewMde/ContentPreviewMde';
import ContentPreviewQuill from '../ContentPreviewQuill/ContentPreviewQuill';

function ContentPreview(props: { value: string }) {
  const Component = Flags.CONTENT_EDITOR_QUILL ? ContentPreviewQuill : ContentPreviewMde;
  return <Component {...props} />;
}

export default ContentPreview;
