import { call } from 'redux-saga/effects';
import coreSaga from './core';
import loginSaga from './login';
import { getAccessToken } from '../utils/access-token-storage';

export default function* rootSaga() {
  const accessToken = yield call(getAccessToken);

  if (!accessToken) {
    // This will _not_ forward to /login. We will only do that in case of a 401 response.
    // This allows access to anonymous users.
    yield call(loginSaga);
  }

  yield call(coreSaga);
}
