/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import type { Profession } from '../../types/Profession';
import { SortOrder } from '../../types/SortOrder';
import Heading from '../Heading/Heading';
import ProjectsFilter from '../ProjectsFilter/ProjectsFilter';
import ProjectsList from '../ProjectsList/ProjectsList';
import ProjectsSort from '../ProjectsSort/ProjectsSort';

function CommunityProjects({
  onAdd,
  canLoadMore,
  communityItems,
  professions,
  updateFilter,
  professionIds,
  loadMoreError,
  addBookmark,
  removeBookmark,
  showProjectsSort,
  sortOrders,
  sortOrder,
  communityPage,
}: {
  onAdd: () => void;
  canLoadMore: boolean;
  updateFilter: (professionIds: string[], sortOrder: SortOrder, page: number) => void;
  communityItems: any[];
  professions: readonly Profession[];
  professionIds: string[];
  loadMoreError: boolean;
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
  showProjectsSort: boolean;
  sortOrders: readonly SortOrder[];
  sortOrder: SortOrder;
  communityPage: number;
}) {
  const [t] = useTranslation();

  return (
    <>
      <Heading text={t('common.discoverCommunityBoards')} />

      <div className="flex flex-col md:flex-row justify-between">
        <ProjectsFilter
          professions={professions}
          professionIds={professionIds}
          updateFilter={(professionIds) => {
            updateFilter(professionIds, sortOrder, 1);
          }}
        />

        {showProjectsSort && (
          <ProjectsSort
            sortOrders={sortOrders}
            sortId={sortOrder}
            updateSort={(sortOrder) => {
              updateFilter(professionIds, sortOrder, 1);
            }}
          />
        )}
      </div>

      <div className="mt-2" />
      {communityItems.length === 0 ? (
        <div className="mt-8 text-blue50 w-full">{t('common.noBoards')}</div>
      ) : (
        <ProjectsList
          items={communityItems}
          canAdd={false}
          onAdd={onAdd}
          canLoadMore={canLoadMore}
          onLoadMore={() => {
            updateFilter(professionIds, sortOrder, communityPage + 1);
          }}
          loadMoreError={loadMoreError}
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          showBookmarks={false}
        />
      )}
    </>
  );
}

export default CommunityProjects;
