import { uniq } from 'lodash-es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType';
import { extractEmails } from '../../utils/extractEmails.js';
import Button from '../Button/Button';
import PillList from '../PillList/PillList';

const getId = (user: string) => {
  return user;
};

const getLabel = (user: string) => {
  return user;
};

const getNewEmails = (emails: readonly string[], newEmail: string) => {
  const newEmails = extractEmails(newEmail);
  return uniq([...emails, ...newEmails]);
};

function FormUserAccess({
  emails,
  setUsers,
}: {
  emails: readonly string[];
  setUsers: (users: readonly string[]) => void;
}) {
  const [t] = useTranslation();
  const [newEmail, setNewEmail] = useState('');

  const addUser = () => {
    const newEmails = getNewEmails(emails, newEmail);
    setUsers(newEmails);
    setNewEmail('');
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewEmail(event.target.value);
  };
  const handleKeyDown = (event: any) => {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      addUser();
    }
  };
  return (
    <div className="flex flex-col max-w-[400px] mt-7">
      <p className="mt-4">{t('common.usersWithWriteAccess')}</p>
      <PillList
        className="mt-2"
        items={emails}
        getId={getId}
        getLabel={getLabel}
        setSelectedItems={setUsers}
        closeLabel={t('common.delete')}
      />

      <small className="text-xs font-light text-blue50 mt-1">
        {t('common.addLeandoSsoUsersWithWriteAccess')}
      </small>

      <div className="flex gap-3 mt-1">
        <Input
          fluid
          inverted
          name="user"
          type="email"
          className="flex flex-1"
          value={newEmail}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        <Button type="button" variant={ButtonType.Blue} onClick={addUser}>
          {t('common.add')}
        </Button>
      </div>
    </div>
  );
}

export default FormUserAccess;
