/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Markdown } from '../../lib/custom-ui/index';
import styles from '../CardModal/CardModal.module.scss';
import DescriptionEdit from '../CardModal/DescriptionEdit';
import ContentPreview from '../ContentPreview/ContentPreview';

function CardModalContentDescription({
  description,
  canEdit,
  initialForceOpen,
  handleDescriptionUpdate,
  descriptionEditOpen,
  openDescriptionEdit,
  closeDescriptionEdit,
}: {
  description: string;
  canEdit: boolean;
  initialForceOpen?: number;
  handleDescriptionUpdate: (value: string) => void;
  descriptionEditOpen: boolean;
  openDescriptionEdit: () => void;
  closeDescriptionEdit: () => void;
}) {
  const [t] = useTranslation();

  return (
    <div className={styles.contentModule}>
      <div className={styles.moduleWrapper}>
        <div className="mb-2">{t('common.description')}</div>
        {canEdit ? (
          // @ts-ignore
          <DescriptionEdit
            defaultValue={description}
            onUpdate={handleDescriptionUpdate}
            initialForceOpen={initialForceOpen}
            descriptionEditOpen={descriptionEditOpen}
            openDescriptionEdit={openDescriptionEdit}
            closeDescriptionEdit={closeDescriptionEdit}
          >
            {description ? (
              <button
                type="button"
                className={classNames(styles.descriptionText, styles.cursorPointer)}
              >
                <ContentPreview value={description} />
              </button>
            ) : (
              <button type="button" className={styles.descriptionButton}>
                <span className={styles.descriptionButtonText}>
                  {t('action.addMoreDetailedDescription')}
                </span>
              </button>
            )}
          </DescriptionEdit>
        ) : (
          <div className={styles.descriptionText}>
            {/* @ts-ignore */}
            <Markdown linkStopPropagation linkTarget="_blank">
              {description}
            </Markdown>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(CardModalContentDescription);
