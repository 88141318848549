/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
import { attr, fk, many } from 'redux-orm';
import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';

const isPasswordRequiredError = (error: unknown) => {
  return error && error['code'] === 'E_PASSWORD_REQUIRED';
};

const isAccessDeniedError = (error: unknown) => {
  return error && error['code'] === 'E_ACCESS_DENIED';
};

const isScheduledForDeletionError = (error: unknown) => {
  return error && error['code'] === 'E_PROJECT_SCHEDULED_FOR_DELETION';
};

export default class extends BaseModel {
  static modelName = 'Board';

  static fields = {
    id: attr(),
    position: attr(),
    name: attr(),
    error: attr(),
    isFetching: attr({
      getDefault: () => null,
    }),
    isAccessDenied: attr(),
    isScheduledForDeletion: attr(),
    isPasswordRequired: attr(),
    projectId: fk({
      to: 'Project',
      as: 'project',
      relatedName: 'boards',
    }),
    memberUsers: many({
      to: 'User',
      through: 'BoardMembership',
      relatedName: 'boards',
    }),
    filterUsers: many('User', 'filterBoards'),
  };

  static reducer({ type, payload }, Board) {
    switch (type) {
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.board) {
          Board.upsert({
            ...payload.board,
            isFetching: false,
          });
        }
        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE__BOARD_FETCH:
      case ActionTypes.BOARD_FETCH:
        Board.upsert({
          id: payload.id,
          isFetching: true,
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Board.all().delete();

        if (payload.board) {
          Board.upsert({
            ...payload.board,
            isFetching: false,
          });
        }

        payload.boards.forEach((board) => {
          Board.upsert(board);
        });

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE__CORE_FETCH:
        Board.all()
          .toModelArray()
          .forEach((boardModel) => {
            if (boardModel.id !== payload.currentBoardId) {
              boardModel.update({
                isFetching: null,
              });

              boardModel.deleteRelated(payload.currentUserId);
            }
          });

        break;
      case ActionTypes.CORE_INITIALIZE:
        if (payload.board) {
          Board.upsert({
            ...payload.board,
            isFetching: false,
          });
        }

        if (payload.boards) {
          payload.boards.forEach((board) => {
            Board.upsert(board);
          });
        }

        break;
      case ActionTypes.USER_TO_BOARD_FILTER_ADD:
        Board.withId(payload.boardId).filterUsers.add(payload.id);

        break;
      case ActionTypes.USER_FROM_BOARD_FILTER_REMOVE:
        Board.withId(payload.boardId).filterUsers.remove(payload.id);

        break;
      case ActionTypes.PROJECT_CREATE_HANDLE:
        payload.boards.forEach((board) => {
          Board.upsert(board);
        });

        break;
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.BOARD_MEMBERSHIP_CREATE_HANDLE:
        if (payload.boards) {
          payload.boards.forEach((board) => {
            Board.upsert({
              ...board,
              ...(payload.board &&
                payload.board.id === board.id && {
                  isFetching: false,
                }),
            });
          });
        }

        break;
      case ActionTypes.BOARD_CREATE:
      case ActionTypes.BOARD_CREATE_HANDLE:
      case ActionTypes.BOARD_UPDATE__SUCCESS:
      case ActionTypes.BOARD_UPDATE_HANDLE:
        Board.upsert(payload.board);

        break;
      case ActionTypes.BOARD_CREATE__SUCCESS:
        if (payload.localId) {
          Board.withId(payload.localId).delete();
        }
        Board.upsert(payload.board);

        break;
      case ActionTypes.BOARD_FETCH__SUCCESS:
        Board.upsert({
          ...payload.board,
          isFetching: false,
        });

        break;
      case ActionTypes.PROJECT_TRY_ACCESS_PASSWORD__SUCCESS:
        Board.withId(payload.id).update({
          isPasswordRequired: false,
        });
        break;
      case ActionTypes.BOARD_FETCH__FAILURE:
        if (isPasswordRequiredError(payload.error)) {
          Board.withId(payload.id).update({
            isPasswordRequired: true,
            isFetching: null,
          });
        } else if (isAccessDeniedError(payload.error)) {
          Board.withId(payload.id).update({
            isAccessDenied: true,
            isFetching: null,
          });
        } else if (isScheduledForDeletionError(payload.error)) {
          Board.withId(payload.id).update({
            isScheduledForDeletion: true,
            isFetching: null,
          });
        } else {
          Board.withId(payload.id).update({
            isFetching: null,
            error: payload.error,
          });
        }

        break;
      case ActionTypes.BOARD_UPDATE:
        Board.withId(payload.id).update(payload.data);

        break;
      case ActionTypes.BOARD_DELETE:
        Board.withId(payload.id).deleteWithRelated();

        break;
      case ActionTypes.BOARD_DELETE__SUCCESS:
      case ActionTypes.BOARD_DELETE_HANDLE: {
        const boardModel = Board.withId(payload.board.id);

        if (boardModel) {
          boardModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.LOAD_COMMUNITY_PROJECTS__SUCCESS:
      case ActionTypes.PROJECT_DUPLICATE__SUCCESS:
        for (const board of payload.boards) {
          Board.upsert({
            ...board,
            isFetching: false,
          });
        }
        break;
      default:
    }
  }

  getOrderedMembershipsQuerySet() {
    return this.withRelatedFields().memberships.orderBy('createdAt');
  }

  getOrderedLabelsQuerySet() {
    return this.withRelatedFields().labels.orderBy('position');
  }

  getOrderedListsQuerySet() {
    return this.withRelatedFields().lists.orderBy('position');
  }

  getMembershipModelForUser(userId) {
    return this.withRelatedFields()
      .memberships.filter({
        userId,
      })
      .first();
  }

  hasMembershipForUser(userId) {
    return this.withRelatedFields()
      .memberships.filter({
        userId,
      })
      .exists();
  }

  isAvailableForUser(userId) {
    return (
      this.withRelatedFields().project &&
      (this.withRelatedFields().project.hasManagerForUser(userId) ||
        this.hasMembershipForUser(userId))
    );
  }

  deleteRelated(exceptMemberUserId?: string) {
    this.withRelatedFields()
      .memberships.toModelArray()
      .forEach((boardMembershipModel) => {
        if (boardMembershipModel.userId !== exceptMemberUserId) {
          boardMembershipModel.deleteWithRelated();
        }
      });

    this.withRelatedFields()
      .lists.toModelArray()
      .forEach((listModel) => {
        listModel.deleteWithRelated();
      });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }

  private withRelatedFields() {
    // These properties are defined in other models as 'relatedName'
    return this as typeof this & {
      memberships: any;
      labels: any;
      lists: any;
      project: any;
    };
  }
}
