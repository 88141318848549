/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';

function HeaderSearch({
  value,
  setValue,
  handleEnter,
}: {
  value: string;
  setValue: (value: string) => void;
  handleEnter: () => void;
}) {
  const [t] = useTranslation();
  const handleInput = (event: any) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleEnter();
    }
  };
  return (
    <div data-testid="HeaderSearch" className="flex items-center justify-center mx-auto flex-1">
      <label className="bg-bgGray px-4 py-3 font-bold border border-px flex rounded-sm border-blue10 w-full max-w-[392px]">
        <div className="w-6 h-6 contain-strict">
          <img src="/assets/images/icon-search.svg" alt="" className="" />
        </div>
        <input
          type="search"
          placeholder={t('common.search')}
          className="bg-transparent outline-none pl-2 text-blue100 w-full"
          value={value}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
        />
      </label>
    </div>
  );
}

export default React.memo(HeaderSearch);
