const {
  VITE_CI_COMMIT_SHORT_SHA,
  VITE_CI_COMMIT_TAG,
  BASE_URL,
  VITE_SERVER_BASE_URL,
  VITE_CI_MATOMO_ID,
  VITE_CI_DEPLOYMENT_ENVIRONMENT,
  MODE,
} = import.meta.env;

const VERSION = VITE_CI_COMMIT_TAG || VITE_CI_COMMIT_SHORT_SHA;

let BASE_PATH = BASE_URL;
if (BASE_PATH.endsWith('/')) {
  BASE_PATH = BASE_PATH.slice(0, -1);
}

const isProduction = MODE === 'production';
let SERVER_BASE_URL = VITE_SERVER_BASE_URL || (isProduction ? BASE_URL : 'http://localhost:1337');

while (SERVER_BASE_URL.startsWith('/')) {
  SERVER_BASE_URL = SERVER_BASE_URL.slice(1);
}

const SERVER_HOST_NAME = SERVER_BASE_URL.replace(/^(.*\/\/[^/?#]*).*$/, '$1');

const ACCESS_TOKEN_KEY = 'accessToken';
const ACCESS_TOKEN_VERSION_KEY = 'accessTokenVersion';
const ACCESS_TOKEN_VERSION = '1';

const POSITION_GAP = 65535;
const ACTIVITIES_LIMIT = 50;

export default {
  VERSION,
  BASE_PATH,
  SERVER_BASE_URL,
  SERVER_HOST_NAME,
  ACCESS_TOKEN_KEY,
  ACCESS_TOKEN_VERSION_KEY,
  ACCESS_TOKEN_VERSION,
  POSITION_GAP,
  ACTIVITIES_LIMIT,
  VITE_CI_COMMIT_TAG,
  VITE_CI_COMMIT_SHORT_SHA,
  VITE_CI_MATOMO_ID,
  VITE_CI_DEPLOYMENT_ENVIRONMENT,
};
