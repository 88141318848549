/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType.js';
import { Input } from '../../lib/custom-ui/index';
import Button from '../Button/Button.js';
import FormError from '../FormError/FormError.js';

function BoardPasswordModalContent({
  isSubmitting,
  handleSubmit,
  data,
  handleFieldChange,
  error,
}: {
  isSubmitting: boolean;
  handleSubmit: () => void;
  data: {
    password: string;
  };
  handleFieldChange: (...args: any[]) => void;
  error?: any;
}) {
  const [t] = useTranslation();

  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <span className="text-2xl text-blue100 font-bold">{t('common.protectedBoard')}</span>
      </header>

      <Form onSubmit={handleSubmit} className="mt-2">
        <label className="flex gap-1 flex-col">
          <span>{t('common.pleaseEnterPassword')}</span>
          <Input
            type="password"
            id="password"
            name="password"
            onChange={(event) => {
              const newValue = event.target.value;
              handleFieldChange(null, {
                name: 'plainTextPassword',
                value: newValue,
              });
            }}
          />
        </label>

        <FormError error={error} />

        <div className="flex justify-end mt-5">
          <Button type="submit" disabled={isSubmitting} variant={ButtonType.Green}>
            {t('common.ok')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default React.memo(BoardPasswordModalContent);
