export const isSameHost = (url: string) => {
  try {
    const urlHost = new URL(url).host;
    const locationHost = window.location.host;
    if (urlHost === 'localhost:1337' && locationHost === 'localhost:3000') {
      return true;
    }
    return urlHost === locationHost;
  } catch {
    return false;
  }
};
