import { getFileName } from '../../utils/getFileName.js';
import ExternalLink from '../ExternalLink/ExternalLink';

function MediaPreviewFile({
  url,
  icon = '/assets/images/icon-file.svg',
  iconSize = 15,
}: {
  url: string;
  icon?: string;
  iconSize?: number;
}) {
  const fileName = getFileName(url);
  return (
    <div className="contain-content" data-testid="MediaPreviewFile">
      <ExternalLink className="text-green100 flex gap-1 contain-content" url={url}>
        <img
          src={icon}
          alt=""
          width={iconSize}
          height={iconSize}
          className="max-w-[50px] max-h-[50px] object-contain"
        />
        <span className="text-green100 flex items-center">{fileName}</span>
      </ExternalLink>
    </div>
  );
}

export default MediaPreviewFile;
