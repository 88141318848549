import { useTranslation } from 'react-i18next';
import ErrorComponent from '../ErrorComponent/ErrorComponent.js';

function NotFound({
  heading,
  subheading,
  image,
}: { heading?: string; subheading?: string; image?: string } = {}) {
  const [t] = useTranslation();
  const actualHeading = heading ?? t('common.pageNotFound');
  const actualSubHeading = subheading ?? t('common.pageNotFoundPleaseContactSupportThanks');
  const actualImage = image ?? '/assets/images/not-found.svg';
  return (
    <ErrorComponent heading={actualHeading} subheading={actualSubHeading} image={actualImage} />
  );
}

export default NotFound;
