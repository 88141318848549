import { useTranslation } from 'react-i18next';
import Heading from '../Heading/Heading';

function SearchResultsNoResults() {
  const [t] = useTranslation();

  return (
    <div className="bg-bgGray flex-1 contain-content text-blue100 overflow-y-auto p-4 md:px-20 md:py-8 lg:px-44 lg:py-16">
      <Heading text={t('common.searchResults')} />
      <h3 className="text-blue50 pt-3">{t('common.noSearchResults')}</h3>
    </div>
  );
}

export default SearchResultsNoResults;
