import styles from './Fixed.module.scss';
import HeaderContainer from '../../containers/HeaderContainer';

function Fixed({ projectId, show }: { projectId?: string; show?: boolean }) {
  if (!show) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <HeaderContainer />
    </div>
  );
}

export default Fixed;
