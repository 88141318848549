import socket from './socket';

const getProfessions = async (headers) => {
  const data = {};
  const body = await socket.get(`/professions`, data, headers);
  const professions = body.items;
  return professions;
};

export default {
  getProfessions,
};
