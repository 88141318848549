import { transformBoardMembership } from './board-memberships';
import http from './http';
import { transformProjectManager } from './project-managers';
import socket from './socket';
import { transformUser } from './users';

/* Actions */

export const getProjects = (headers) =>
  socket.get('/projects', undefined, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      users: body.included.users.map(transformUser),
      projectManagers: body.included.projectManagers.map(transformProjectManager),
      boardMemberships: body.included.boardMemberships.map(transformBoardMembership),
    },
  }));

export const getCommunityProjects = (data, headers) =>
  socket.get('/communityProjects', data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      projectManagers: body.included.projectManagers.map(transformProjectManager),
      boardMemberships: body.included.boardMemberships.map(transformBoardMembership),
    },
  }));

export const createProject = (data, headers) =>
  socket.post('/projects', data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      projectManagers: body.included.projectManagers.map(transformProjectManager),
    },
  }));

export const getProject = (id, plainTextPassword, headers) =>
  socket
    .get(
      `/projects/${id}`,
      {
        plainTextPassword,
      },
      headers,
    )
    .then((body) => ({
      ...body,
      included: {
        ...body.included,
        users: body.included.users.map(transformUser),
        projectManagers: body.included.projectManagers.map(transformProjectManager),
        boardMemberships: body.included.boardMemberships.map(transformBoardMembership),
      },
    }));

export const updateProject = (id, data, headers) => socket.patch(`/projects/${id}`, data, headers);

export const duplicateProject = (id, data, headers) =>
  socket.post(`/projects/${id}/duplicate`, data, headers);

export const updateProjectBackgroundImage = (id, data, headers) =>
  http.post(`/projects/${id}/background-image`, data, headers);

export const updateProjectThumbnail = (id, data, headers) =>
  http.post(`/projects/${id}/thumbnail`, data, headers);

export const deleteProjectThumbnail = (id, data, headers) =>
  socket.delete(`/projects/${id}/thumbnail`, undefined, headers);

export const deleteProject = (id, headers) => socket.delete(`/projects/${id}`, undefined, headers);

export const importProjectFromPadlet = (data, headers) =>
  socket.post('/import/padlet', data, headers);

export const importProjectCancel = (data, headers) => socket.post('/import/cancel', data, headers);

export const createBookmark = (id, headers) =>
  socket.post(`/projects/${id}/bookmarks`, {}, headers);

export const deleteBookmark = (id, headers) =>
  socket.delete(`/projects/${id}/bookmarks`, {}, headers);

export const getBookmarks = (headers) => socket.get(`/projects/bookmarks`, {}, headers);

export const getScheduledToBeDeletedProjects = (headers) =>
  socket.get('/projects/scheduled-to-be-deleted', {}, headers);
