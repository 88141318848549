/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */

import * as MediaPreviewType from '../constants/MediaPreviewType';

export const getMediaPreviewName = (mediaPreviewType: number) => {
  switch (mediaPreviewType) {
    case MediaPreviewType.EmbedYoutube:
      return 'YouTube';
    case MediaPreviewType.EmbedTwitter:
      return 'Twitter';
    case MediaPreviewType.EmbedGoogleMaps:
      return 'Google Maps';
    case MediaPreviewType.EmbedSoundcloud:
      return 'Soundcloud';
    case MediaPreviewType.EmbedInstagram:
      return 'Instagram';
    case MediaPreviewType.EmbedVimeo:
      return 'Vimeo';
    case MediaPreviewType.EmbedPdf:
      return 'Pdf';
    default:
      return `Unknown (${mediaPreviewType})`;
  }
};
