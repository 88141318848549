import { getMediaPreviewType } from '../../utils/getMediaPreviewType';
import MediaUploadRemoveButton from '../MediaUploadRemoveButton/MediaUploadRemoveButton';

function MediaUploadPreview({
  media,
  handleFileDelete,
  canEdit,
  mediaLinkUrl,
  mediaPreviewUrl,
  MediaPreviewContainer,
}: {
  media: string;
  handleFileDelete: () => void;
  canEdit: boolean;
  mediaLinkUrl?: string;
  mediaPreviewUrl?: string;
  MediaPreviewContainer: any;
}) {
  const previewType = getMediaPreviewType(mediaPreviewUrl || media);
  return (
    <div className="flex flex-col bg-bgGray p-3 relative">
      <div className="flex flex-col items-center flex-1">
        <div className="flex flex-col items-center p-4 w-full contain-content bg-white">
          <MediaPreviewContainer
            url={mediaPreviewUrl}
            interactive={false}
            mediaLinkUrl={mediaLinkUrl}
          />
        </div>
      </div>
      {canEdit && (
        <MediaUploadRemoveButton handleDelete={handleFileDelete} previewType={previewType} />
      )}
    </div>
  );
}

export default MediaUploadPreview;
