import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Search from '../components/Search/Search';
import entryActions from '../entry-actions';
import { push } from '../lib/redux-router';

const mapStateToProps = (state) => {
  const {
    ui: {
      searchForm: { error, searchResults, totalCount, searchValue, currentPage },
    },
  } = state;
  const canLoadMore = searchResults && searchResults.length < totalCount;
  return {
    items: searchResults,
    totalItemCount: totalCount,
    canLoadMore,
    error,
    searchValue,
    currentPage,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      loadMoreSearchResults: entryActions.loadMoreSearchResults,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  loadMore() {
    dispatchProps.loadMoreSearchResults(stateProps.searchValue, stateProps.currentPage + 1);
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Search);
