import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  isSubmitting: false,
  error: null,
  embedUrl: '',
  isMediaUpdating: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.ATTACHMENT_CREATE:
      return {
        ...state,
        isMediaUpdating: true,
      };
    case ActionTypes.ATTACHMENT_CREATE__SUCCESS:
    case ActionTypes.ATTACHMENT_UPDATE__SUCCESS:
      return initialState;
    case ActionTypes.ATTACHMENT_UPDATE__FAILURE:
    case ActionTypes.ATTACHMENT_CREATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        isMediaUpdating: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
