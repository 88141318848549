import ActionTypes from '../../constants/ActionTypes';

export const getScheduledToBeDeletedProjects = () => ({
  type: ActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT,
  payload: {},
});

getScheduledToBeDeletedProjects.success = (items: any[]) => ({
  type: ActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__SUCCESS,
  payload: {
    items,
  },
});

getScheduledToBeDeletedProjects.failure = (error) => ({
  type: ActionTypes.PROJECT_GET_SCHEDULED_TO_BE_DELETED_AT__FAILURE,
  payload: { error },
});
