/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { TFunction } from 'i18next';
import { ChangeEvent, useState } from 'react';
import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

// 20 MB
export const MAX_UPLOAD_SIZE = 20_000_000;

const getErrorMessage = (t: TFunction, error: ErrorCode | string) => {
  switch (error) {
    case ErrorCode.FileTooLarge:
      return t('common.fileTooBig');
    default:
      return t('common.invalidFile');
  }
};

function MediaUploadFilePicker({
  handleFileSelect,
}: {
  handleFileSelect: ({ file }: { file: File }) => void;
}) {
  const [t] = useTranslation();
  const [error, setError] = useState<ErrorCode | string | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const { files } = target;
    if (!files || files.length === 0) {
      return;
    }
    handleFileSelect({ file: files[0] });
  };

  const onDrop = (files: File[], rejectedFiles: FileRejection[]) => {
    setError(null);
    if (rejectedFiles.length > 0) {
      setError(rejectedFiles[0].errors[0].code);
    }
    if (!files || files.length === 0) {
      return;
    }
    handleFileSelect({ file: files[0] });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_UPLOAD_SIZE,
  });

  return (
    <div className="flex flex-col bg-bgGray p-3">
      <div {...getRootProps()}>
        <div className="flex flex-col items-center flex-1 cursor-pointer">
          <div
            className={classNames(
              'flex flex-col items-center p-4 w-full contain-content',
              isDragActive ? 'bg-bgGray' : 'bg-white',
            )}
          >
            <div className="border border-px border-blue10 p-2 rounded-sm">
              <img src="/assets/images/icon-upload.svg" alt="" className="w-6 h-6 contain-strict" />
            </div>
            <div className="mt-3 text-sm">
              <div className="flex justify-center">
                <span className="text-green70_opaque font-semibold">{t('common.uploadFile')}</span>
                <span className="text-blue50 font-normal ml-1"> / {t('common.dragAndDrop')}</span>
              </div>
              <span className="flex text-center text-blue50">
                {t('common.uploadFileDescription')}
              </span>
            </div>
          </div>
        </div>
        <input
          data-testid="file-upload-input"
          type="file"
          id="FileUpload"
          className="hidden"
          accept="*/*"
          onChange={handleChange}
          {...getInputProps()}
        />
      </div>
      {error && <p className="pt-2 text-sm text-errorRedDark">{getErrorMessage(t, error)}</p>}
    </div>
  );
}

export default MediaUploadFilePicker;
