/* eslint-disable no-restricted-syntax */
import { all, takeEvery } from 'redux-saga/effects';
import ActionTypes from '../../../constants/ActionTypes';
import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

const getKeys = (actionTypes) => {
  const keys = Object.keys(actionTypes);
  const filteredKeys = [];
  for (const key of keys) {
    if (key.endsWith('__FAILURE') || key === 'GLOBAL_ERROR') {
      filteredKeys.push(key);
    }
  }
  return filteredKeys;
};

export default function* coreWatchers() {
  const filteredKeys = getKeys(ActionTypes);
  yield all(filteredKeys.map((key) => takeEvery(key, services.handleError)));
}
