/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps, DropdownOnSearchChangeData } from 'semantic-ui-react';

function ComboBox<T>({
  options,
  selectedOptions,
  getLabel,
  getId,
  setSelectedOptions,
  placeholder,
  value,
  setValue,
  className,
}: {
  options: readonly T[];
  selectedOptions: readonly T[];
  getLabel: (option: T) => string;
  getId: (option: T) => string;
  setSelectedOptions: (options: readonly T[]) => void;
  placeholder?: string;
  value?: string;
  setValue?: (value: string) => void;
  className?: string;
}) {
  const selectedIds = selectedOptions.map((option) => getId(option));
  const dropDownOptions: DropdownItemProps[] = options
    .filter((option) => !selectedIds.includes(getId(option)))
    .map((option) => {
      return {
        text: getLabel(option),
        key: getId(option),
        onClick(event, data) {
          if (selectedOptions.includes(option)) {
            return;
          }
          const newOptions = [...selectedOptions, option];
          setSelectedOptions(newOptions);
          queueMicrotask(() => {
            if (document.activeElement instanceof HTMLInputElement) {
              document.activeElement.blur();
            }
          });
        },
      };
    });

  const onSearchChange = (
    _event: React.SyntheticEvent<HTMLElement>,
    data: DropdownOnSearchChangeData,
  ) => {
    setValue(data.searchQuery);
  };

  const controlledValue = selectedOptions.map((option) => getId(option));
  const [t] = useTranslation();

  return (
    <div className={className}>
      <Dropdown
        fluid
        search
        selection
        options={dropDownOptions}
        placeholder={placeholder}
        basic
        multiple
        searchQuery={value}
        onSearchChange={onSearchChange}
        value={controlledValue}
        closeOnBlur
        noResultsMessage={t('common.noResults')}
      />
    </div>
  );
}

export default React.memo(ComboBox);
