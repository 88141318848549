import classNames from 'classnames';

function SubHeading({ text, className }: { text: string; className?: string }) {
  return (
    <h2
      className={classNames('text-blue50 font-bold text-base font-sans m-0 font-medium', className)}
    >
      {text}
    </h2>
  );
}

export default SubHeading;
