const USERS = 'USERS';
const USER_SETTINGS = 'USER_SETTINGS';
const PROJECT_ADD = 'PROJECT_ADD';
const PROJECT_SETTINGS = 'PROJECT_SETTINGS';
const ABOUT = 'ABOUT';
const BOARD_IMPORT = 'BOARD_IMPORT';
const MEDIA_PREVIEW = 'MEDIA_PREVIEW';
const CARD = 'CARD';
const BOARD_ACCESS = 'BOARD_ACCESS';
const BOARD_SETTINGS = 'BOARD_SETTINGS';
const BOARD_DUPLICATE = 'BOARD_DUPLICATE';
const CARD_DUPLICATE = 'CARD_DUPLICATE';
const DESCRIPTION_EDIT_CONFIRM = 'DESCRIPTION_EDIT_CONFIRM';

export default {
  USERS,
  USER_SETTINGS,
  PROJECT_ADD,
  PROJECT_SETTINGS,
  ABOUT,
  BOARD_IMPORT,
  MEDIA_PREVIEW,
  CARD_DUPLICATE,
  CARD,
  BOARD_ACCESS,
  BOARD_SETTINGS,
  BOARD_DUPLICATE,
  DESCRIPTION_EDIT_CONFIRM,
};
