/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './AddAnotherList.module.scss';
import AddAnother from '../AddAnother/AddAnother.js';

function AddAnotherList({
  listIds,
  handleAddListClick,
  background,
}: {
  listIds: string[];
  handleAddListClick?: () => void;
  background?: string;
}) {
  const [t] = useTranslation();

  return (
    <AddAnother
      onClick={handleAddListClick}
      className="flex gap-2 items-center px-4"
      background={background}
    >
      <span className="relative flex">
        <span className="absolute w-3 h-3 bg-white mt-1.5 ml-1.5 z-10 hidden group-hover:block" />
        <img
          src="/assets/images/icon-plus-filled.svg"
          className={classNames(styles.addListButtonIcon, 'z-10 relative')}
          alt=""
        />
      </span>
      <span className={styles.addListButtonText}>
        {listIds.length > 0 ? t('action.addAnotherList') : t('action.addList')}
      </span>
    </AddAnother>
  );
}

export default AddAnotherList;
