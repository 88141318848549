import classnames from 'classnames';
import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Carousel } from 'react-responsive-carousel';
import { CarouselProps } from 'react-responsive-carousel/lib/ts/components/Carousel/types';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import Flags from '../../constants/Flags';

const calculateInitialSlideIndex = (
  slideNumber: string | undefined,
  minIndex: number,
  maxIndex: number,
): number => {
  if (!slideNumber) {
    return minIndex;
  }

  const slideNumberInt = Number(slideNumber) - 1;
  if (slideNumberInt < minIndex) {
    return minIndex;
  }
  if (slideNumberInt > maxIndex) {
    return maxIndex;
  }

  return slideNumberInt;
};

function SlideshowCarousel({
  children,
  goToSlide,
  slideNumber = '1',
  boardUrl,
  ...props
}: PropsWithChildren<
  Partial<CarouselProps> & {
    boardUrl: string;
    goToSlide: (index: number) => void;
    slideNumber: string | undefined;
  }
>) {
  const MIN_INDEX = 0;
  const MAX_INDEX = children ? children.length - 1 : MIN_INDEX;
  const handle = useFullScreenHandle();
  const currentSlideIndex = calculateInitialSlideIndex(slideNumber, MIN_INDEX, MAX_INDEX);

  const navigateAbsolute = useCallback(
    (index: number) => {
      const clampedIndex = Math.min(Math.max(index, MIN_INDEX), MAX_INDEX);
      goToSlide(clampedIndex + 1);
    },
    [MAX_INDEX, MIN_INDEX, goToSlide],
  );
  const navigateRelative = useCallback(
    (delta: number) => {
      const newSlideIndex = currentSlideIndex + delta;
      navigateAbsolute(newSlideIndex);
    },
    [currentSlideIndex, navigateAbsolute],
  );

  const updateCurrentSlide = useCallback(
    (index: number) => {
      if (currentSlideIndex !== index) {
        goToSlide(index + 1);
      }
    },
    [currentSlideIndex, goToSlide],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        navigateRelative(-1);
      } else if (event.key === 'ArrowRight') {
        navigateRelative(1);
      } else if (event.key === ' ') {
        // Spacebar
        navigateRelative(1);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigateRelative]);

  useEffect(() => {
    if (slideNumber && currentSlideIndex !== Number(slideNumber) - 1) {
      goToSlide(Number(slideNumber) - 1);
    }
  }, [currentSlideIndex, goToSlide, slideNumber]);

  return (
    <FullScreen className="bg-white" handle={handle}>
      <Carousel
        selectedItem={currentSlideIndex}
        onChange={updateCurrentSlide}
        renderIndicator={(onClickHandler, isSelected, index) => {
          return (
            <li
              className={classnames('!hidden md:!inline-block dot', isSelected && ' selected')}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              key={index}
              value={index}
              /* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
              role="button"
              tabIndex={0}
              aria-label={`slide item ${index + 1}`}
            />
          );
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {children}
      </Carousel>
      <button
        type="button"
        aria-label="fullscreen"
        className="group z-10 absolute bottom-0.5 right-0.5 outline-none border-none bg-transparent"
        onClick={() => (handle.active ? handle.exit() : handle.enter())}
      >
        <Icon
          fitted
          name={handle.active ? 'compress' : 'expand'}
          className="!text-2xl text-gray group-hover:text-blue70 group-hover:cursor-pointer"
        />
      </button>
      {Flags.SLIDE_SHOW_CLOSE_BUTTON && (
        <Link
          type="button"
          aria-label="close"
          className="group z-10 absolute left-0.5 top-0.5 outline-none border-none bg-transparent"
          to={boardUrl}
        >
          <img src="/assets/images/icon-close.svg" alt="" />
        </Link>
      )}
    </FullScreen>
  );
}

export default React.memo(SlideshowCarousel);
