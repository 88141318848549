/* eslint-disable no-shadow */
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import type { IProjectListItem } from '../../types/IProjectListItem';
import type { Profession } from '../../types/Profession';
import type { SortOrder } from '../../types/SortOrder';
import CommunityProjects from '../CommunityProjects/CommunityProjects.js';
import FavoriteProjects from '../FavoriteProjects/FavoriteProjects';
import MyProjects from '../MyProjects/MyProjects.js';
import Welcome from '../Welcome/Welcome.js';

function Projects({
  favoriteItems,
  items,
  canAdd,
  onAdd,
  canLoadMore,
  communityItems,
  professions,
  updateFilter,
  professionIds,
  loadMoreError,
  addBookmark,
  removeBookmark,
  showWelcome,
  showMyBoards,
  sortOrders,
  showProjectsSort,
  sortOrder,
  communityPage,
}: {
  favoriteItems: readonly IProjectListItem[];
  items?: any[];
  canAdd: boolean;
  onAdd: () => void;
  canLoadMore: boolean;
  updateFilter: (professionIds: string[], sortOrder: SortOrder, page: number) => void;
  communityItems: any[];
  professions: readonly Profession[];
  professionIds: string[];
  loadMoreError: boolean;
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
  showWelcome?: boolean;
  showMyBoards?: boolean;
  sortOrders: readonly SortOrder[];
  showProjectsSort: boolean;
  sortOrder: SortOrder;
  communityPage: number;
}) {
  const scrollRef = useScrollRestoration('projects');
  return (
    <div className="contain-content w-full h-full flex flex-col bg-bgGray">
      <main className="overflow-y-auto grow pt-6 px-2 lg:pt-8" ref={scrollRef}>
        <div className="md:mx-auto max-w-screen-lg">
          {showWelcome && <Welcome />}

          {favoriteItems.length > 0 && (
            <FavoriteProjects
              items={favoriteItems}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
            />
          )}

          {showMyBoards && (
            <MyProjects
              items={items}
              canAdd={canAdd}
              onAdd={onAdd}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
            />
          )}

          <div className="mt-6 lg:mt-8" />

          <CommunityProjects
            onAdd={onAdd}
            canLoadMore={canLoadMore}
            communityItems={communityItems}
            professions={professions}
            updateFilter={updateFilter}
            professionIds={professionIds}
            loadMoreError={loadMoreError}
            addBookmark={addBookmark}
            removeBookmark={removeBookmark}
            sortOrders={sortOrders}
            showProjectsSort={showProjectsSort}
            sortOrder={sortOrder}
            communityPage={communityPage}
          />
        </div>
      </main>
      <div className="mt-4" />
    </div>
  );
}

export default Projects;
