import * as SessionStorage from './sessionStorage';
import { storagePrefix } from '../constants/StoragePrefix';

const getKey = (mediaPreviewType: number) => {
  return `${storagePrefix}media-preview-consent:${mediaPreviewType}`;
};

export const allow = (mediaPreviewType: number) => {
  const key = getKey(mediaPreviewType);
  SessionStorage.setItem(key, '1');
};

export const get = (mediaPreviewType: number) => {
  const key = getKey(mediaPreviewType);
  return Boolean(SessionStorage.getItem(key));
};
