import ActionTypes from '../../constants/ActionTypes';

export const scheduleProjectForDeletion = (id) => ({
  type: ActionTypes.PROJECT_SCHEDULE_FOR_DELETION,
  payload: {
    id,
  },
});

scheduleProjectForDeletion.success = (id) => ({
  type: ActionTypes.PROJECT_SCHEDULE_FOR_DELETION__SUCCESS,
  payload: {
    id,
  },
});

scheduleProjectForDeletion.failure = (id, error) => ({
  type: ActionTypes.PROJECT_SCHEDULE_FOR_DELETION__FAILURE,
  payload: {
    id,
    error,
  },
});
