/* eslint-disable react/require-default-props */
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Logos from '../Logos/Logos.js';

function Footer({ className }: { className?: string }) {
  const [t] = useTranslation();
  return (
    <footer className={classNames('flex flex-col contain-content bg-bgGray p-4 pt-0', className)}>
      <div className="h-px bg-blue10 mb-4" role="none" />

      <div className="flex justify-between w-full px-4 text-blue50">
        <Logos />

        <div className="flex items-center gap-5">
          <a href="/impressum" target="_blank">
            {t('common.imprint')}
          </a>
          <a href="/datenschutz" target="_blank">
            {t('common.dataSecurity')}
          </a>
          <a href="/licenses" target="_blank">
            {t('common.licenses')}
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
