/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import Heading from '../Heading/Heading.js';
import ProjectsList from '../ProjectsList/ProjectsList.js';

function MyProjects({
  items,
  canAdd,
  onAdd,
  addBookmark,
  removeBookmark,
}: {
  items?: any[];
  canAdd: boolean;
  onAdd: () => void;
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
}) {
  const [t] = useTranslation();
  return (
    <>
      <Heading text={t('common.myBoards')} />
      <div className="mt-2" />
      <ProjectsList
        items={items}
        canAdd={canAdd}
        onAdd={onAdd}
        canLoadMore={false}
        onLoadMore={() => {}}
        addBookmark={addBookmark}
        removeBookmark={removeBookmark}
        showBookmarks={false}
      />
    </>
  );
}

export default MyProjects;
