import { useTranslation } from 'react-i18next';
import Heading from '../Heading/Heading';

function Welcome() {
  const [t] = useTranslation();

  return (
    <div className="flex flex-1 flex-col mb-2 bg-white" data-testid="welcome">
      <div className="flex flex-col-reverse md:flex-row p-6 gap-2 from-white to-green10 bg-gradient-175 from-[92%] to-[92%] md:bg-gradient-170 md:from-[68%] md:to-[68%]">
        <div className="flex flex-col gap-2 text-blue70 text-sm md:mb-5 mb-10">
          <Heading text={t('common.welcomeToMikado')} />
          <div className="mt-2">
            <p className="font-sans">{t('common.welcomeSubtitle1')}</p>
            <p className="mt-4">{t('common.welcomeSubtitle2')}</p>
            <p className="mt-4">{t('common.welcomeSubtitle3')}</p>
          </div>
          <div className="mt-2">
            <p className="text-blue100 font-bold">{t('common.welcomeQuestion1')}</p>
            <p>{t('common.welcomeAnswer1')}</p>
          </div>
          <div className="mt-1">
            <p className="text-blue100 font-bold">{t('common.welcomeQuestion2')}</p>
            <p>{t('common.welcomeAnswer2')}</p>
          </div>
          <div className="mt-1">
            <p className="text-blue100 font-bold">{t('common.welcomeQuestion3')}</p>
            <p>{t('common.welcomeAnswer3')}</p>
          </div>
        </div>
        <img src="/assets/images/mikado-laptop-wm.svg" alt="" className="w-full md:w-1/3" />
      </div>
    </div>
  );
}

export default Welcome;
