/* eslint-disable no-shadow */
import classNames from 'classnames';

function PillListItem<T>({
  item,
  onClose,
  getLabel,
  closeLabel,
}: {
  item: T;
  onClose: (item: T) => void;
  getLabel: (item: T) => string;
  closeLabel: string;
}) {
  const handleClick = () => {
    onClose(item);
  };
  return (
    <div
      className={classNames(
        'flex justify-center items-center py-1.5 px-1.5 border-none rounded-sm bg-blue10 text-blue100 font-medium text-sm leading-6',
      )}
    >
      <span>{getLabel(item)}</span>
      <button
        aria-label={closeLabel}
        onClick={handleClick}
        type="button"
        className="bg-transparent outline-none border-none w-6 h-6 contain-strict shrink-0 cursor-pointer flex items-center justify-center"
      >
        <img src="/assets/images/icon-close-orange.svg" alt="" className="w-[10px] h-[10px]" />
      </button>
    </div>
  );
}

export default PillListItem;
