import type { Profession } from '../types/Profession';

const getLabel = (option: Profession) => {
  return option.name ?? 'n/a';
};

const getPriority = (label: string) => {
  if (label === 'Sonstiges') {
    return -1;
  }
  if (label === 'Querschnittsthemen in der Ausbildung') {
    return 1;
  }
  return 0;
};

const compareLabel = (a: string, b: string) => {
  const priorityA = getPriority(a);
  const priorityB = getPriority(b);
  return priorityB - priorityA || a.localeCompare(b);
};

const toSorted = <T extends any>(array: readonly T[], compare: (a: T, b: T) => number) => {
  return [...array].sort(compare);
};

const compareProfession = (a: Profession, b: Profession) => {
  return compareLabel(getLabel(a), getLabel(b));
};

export const sortProfessions = (professions: readonly Profession[]) => {
  return toSorted(professions, compareProfession);
};
