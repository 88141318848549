import ActionTypes from '../constants/ActionTypes';
import ModalTypes from '../constants/ModalTypes';
import { LOCATION_CHANGE_HANDLE } from '../lib/redux-router';

const initialState = {
  isLogouting: false,
  modals: [],
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LOCATION_CHANGE_HANDLE:
    case ActionTypes.MODAL_CLOSE:
      return {
        ...state,
        modals: state.modals.slice(0, -1),
      };
    case ActionTypes.LOGOUT__ACCESS_TOKEN_INVALIDATE:
      return {
        ...state,
        isLogouting: true,
      };
    case ActionTypes.MODAL_OPEN: {
      const { type: currentModal, ...currentModalProps } = payload;
      return {
        ...state,
        modals: [
          ...state.modals,
          {
            type: currentModal,
            ...currentModalProps,
          },
        ],
      };
    }
    case ActionTypes.USER_UPDATE_HANDLE:
      if (state.currentModal === ModalTypes.USERS && payload.isCurrent && !payload.user.isAdmin) {
        return {
          ...state,
          modals: [],
        };
      }

      return state;
    case ActionTypes.PROJECT_MANAGER_DELETE:
    case ActionTypes.PROJECT_MANAGER_DELETE_HANDLE:
      if (
        state.currentModal === ModalTypes.PROJECT_SETTINGS &&
        payload.isCurrentUser &&
        payload.isCurrentProject
      ) {
        return {
          ...state,
          modals: [],
        };
      }

      return state;
    default:
      return state;
  }
};
