import { RefCallback, useCallback, useRef } from 'react';
import { useBeforeUnload, useLocation } from 'react-router-dom';
import { storagePrefix } from '../constants/StoragePrefix.js';

function restoreScrollPosition(key: string, element: HTMLElement) {
  const pos = window.sessionStorage.getItem(key);
  const scrollPosition = Number(pos) || 0;
  element.scrollTo(0, scrollPosition);
}

function saveScrollPosition(key: string, element?: HTMLElement | null) {
  if (element) {
    window.sessionStorage.setItem(key, element.scrollTop.toString());
  }
}

/**
 * Given a ref to a scrolling container element, keep track of its scroll
 * position and restore it on return (e.g., back/forward nav).
 * Note that `location.key` is used in the cache key, not `location.pathname`,
 * so the same path navigated to at different points in the history stack will
 * not share the same scroll position.
 *
 * This is inspired by this discussion on how to do scroll restoration with react-router if the window is not the main scroll container
 * https://github.com/remix-run/react-router/pull/10468#issuecomment-1877312374
 * We have to deviate from that recommendation a little bit, because we are not using a data router, but a custom setup for redux.
 * Migrating to a data router is non-trivial and is discussed here https://github.com/remix-run/react-router/issues/9422#issuecomment-1301182219.
 *
 * Use `elementName` to differentiate between element on the same page.
 */
export function useScrollRestoration(elementName: string): RefCallback<HTMLElement> {
  const location = useLocation();
  const key = `${storagePrefix}:scroll-position:${location.pathname}-${location.key}-${elementName}`;
  const refObject = useRef<HTMLElement>();

  useBeforeUnload(
    useCallback(() => {
      saveScrollPosition(key, refObject.current);
    }, [key]),
    {},
  );

  return useCallback(
    (newValue) => {
      if (newValue && newValue !== refObject.current) {
        // The ref is being set to a new element => restore scroll position
        restoreScrollPosition(key, newValue);
      }
      if (!newValue) {
        // The ref is being unset when the element is removed (i.e. we navigate somewhere else) => save scroll position
        saveScrollPosition(key, refObject.current);
      }
      refObject.current = newValue;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key, elementName],
  );
}
