import { call, put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import selectors from '../../../../selectors';
import { goToRoot } from '../router';

export function* handleProjectDelete(project) {
  const { projectId } = yield select(selectors.selectPath);

  if (project.id === projectId) {
    yield call(goToRoot);
  }

  yield put(actions.handleProjectDelete(project));
}
