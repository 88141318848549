import ActionTypes from '../../constants/ActionTypes';

export const duplicateProject = (id, data) => ({
  type: ActionTypes.PROJECT_DUPLICATE,
  payload: {
    id,
    data,
  },
});

duplicateProject.success = (
  project,
  projectManager,
  boards,
  boardMemberships,
  projectProfessions,
  lists,
  cards,
  attachments,
) => ({
  type: ActionTypes.PROJECT_DUPLICATE__SUCCESS,
  payload: {
    project,
    projectManager,
    boards,
    boardMemberships,
    projectProfessions,
    lists,
    cards,
    attachments,
  },
});

duplicateProject.failure = (id, error) => ({
  type: ActionTypes.PROJECT_DUPLICATE__FAILURE,
  payload: {
    id,
    error,
  },
});
