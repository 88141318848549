import React, { useRef } from 'react';
import ReactEmbed from 'react-embed';
import { useMutationObserver } from 'rooks';

const getDesiredHeight = (wrapperHeight: number, heightOfIframe: number) => {
  if (wrapperHeight < heightOfIframe) {
    return wrapperHeight;
  }

  return heightOfIframe;
};

function Embed({ url, heightOfIframe }: { url: string; heightOfIframe?: number }) {
  const ref = useRef<HTMLDivElement>();

  useMutationObserver(ref, () => {
    const iframeWrapper = ref.current;
    if (!heightOfIframe || !iframeWrapper) {
      return;
    }
    const iframeElement = iframeWrapper.querySelector('iframe');

    // vimeo special
    const vimeo = iframeWrapper.querySelector('[data-vimeo-initialized="true"]');
    if (vimeo) {
      const parent = vimeo.parentElement;
      if (parent) {
        parent.style.height = 'auto';
      }
    }
    if (!iframeElement) {
      return;
    }
    const wrapperHeight = iframeWrapper.clientHeight;
    const desiredHeight = String(getDesiredHeight(wrapperHeight, heightOfIframe));

    if (iframeElement.height === desiredHeight) {
      return;
    }

    iframeElement.height = desiredHeight;
  });

  return (
    <div ref={ref} className="flex justify-center lg:items-center h-full w-full contain-content">
      {url && <ReactEmbed url={url} />}
    </div>
  );
}

export default React.memo(Embed);
