import accessTokens from './access-tokens';
import attachments from './attachments';
import boardMemberships from './board-memberships';
import * as boards from './boards';
import cards from './cards';
import http from './http';
import lists from './lists';
import professions from './professions';
import projectManagers from './project-managers';
import * as projects from './projects';
import root from './root';
import * as search from './search';
import socket from './socket';
import users from './users';

export { http, socket };

export default {
  ...root,
  ...accessTokens,
  ...users,
  ...projects,
  ...projectManagers,
  ...boards,
  ...boardMemberships,
  ...lists,
  ...cards,
  ...attachments,
  ...professions,
  ...search,
};
