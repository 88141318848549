import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import LoginContainer from '../containers/LoginContainer';

const LoginWrapper = React.memo(({ isInitializing, currentUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  if (isInitializing) {
    return <Loader active size="massive" />;
  }
  return <LoginContainer />;
});

LoginWrapper.propTypes = {
  isInitializing: PropTypes.bool.isRequired,
  currentUser: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default LoginWrapper;
