import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import { push } from '../../../../lib/redux-router';
import { setPlainTextPassword } from '../../../../utils/plainTextPassword.js';
import request from '../../request';

export function* tryAccessPassword(url: string, boardId: string, plainTextPassword: string) {
  yield put(actions.tryAccess(boardId));
  try {
    yield call(request, api.getBoard, boardId, false, plainTextPassword);
  } catch (error) {
    yield put(actions.tryAccess.failure(error));
    return;
  }
  yield put(actions.tryAccess.success(boardId));
  yield call(setPlainTextPassword, boardId, plainTextPassword);
  yield put(push(url));
}
