/* eslint-disable no-fallthrough */
/* eslint-disable no-restricted-syntax */
import { attr, FieldSpecMap, many } from 'redux-orm';
import BaseModel from './BaseModel';
import ActionTypes from '../constants/ActionTypes';
import { ProjectBackgroundTypes } from '../constants/Enums';

const updateProjectProfessions = (Project, projectProfessions) => {
  const projectIds = projectProfessions.map((value) => value.projectId);
  for (const projectId of projectIds) {
    Project.withId(projectId).professions.clear();
  }
  projectProfessions.forEach(({ projectId, professionId }) => {
    Project.withId(projectId).professions.add(professionId);
  });
};

const updateProjectUserFavorites = (Project, projectUserFavorites) => {
  const projectIds = projectUserFavorites.map((value) => value.projectId);
  for (const projectId of projectIds) {
    const project = Project.withId(projectId);
    if (project) {
      project.favorite = true;
    }
  }
};

export default class extends BaseModel {
  static modelName = 'Project';

  static fields: FieldSpecMap = {
    id: attr(),
    name: attr(),
    isPublic: attr(),
    subtitle: attr(),
    background: attr(),
    backgroundImage: attr(),
    isBackgroundImageUpdating: attr({
      getDefault: () => false,
    }),
    managerUsers: many({
      to: 'User',
      through: 'ProjectManager',
      relatedName: 'projects',
    }),
    professions: many('Profession', 'professions'),
    isCommunityBoard: attr(),
    sortIndex: attr(),
    plainTextPassword: attr(),
    favorite: attr(),
    scheduledForDeletionAt: attr(),
    creatorUserId: attr(),
  };

  static reducer({ type, payload }, Project) {
    switch (type) {
      case ActionTypes.PROJECT_BOOKMARK_CREATE__SUCCESS:
        {
          const project = Project.withId(payload.bookmark.projectId);
          if (project) {
            project.favorite = true;
          }
        }
        break;
      case ActionTypes.PROJECT_BOOKMARK_DELETE__SUCCESS:
        {
          const project = Project.withId(payload.bookmark.projectId);
          if (project) {
            project.favorite = false;
          }
        }
        break;
      case ActionTypes.LOCATION_CHANGE_HANDLE:
        if (payload.projects) {
          payload.projects.forEach((project) => {
            Project.upsert(project);
          });
        }

        if (payload.projectProfessions) {
          updateProjectProfessions(Project, payload.projectProfessions);
        }

        break;
      case ActionTypes.SOCKET_RECONNECT_HANDLE:
        Project.all().delete();

        payload.projects.forEach((project) => {
          Project.upsert(project);
        });

        if (payload.communityProjects) {
          payload.communityProjects.forEach((project, index) => {
            Project.upsert({ ...project, isCommunityBoard: true, sortIndex: index });
          });
        }

        if (payload.projectProfessions) {
          updateProjectProfessions(Project, payload.projectProfessions);
        }
        break;
      case ActionTypes.CORE_INITIALIZE:
      case ActionTypes.BOARD_FETCH__SUCCESS:
        if (payload.projects) {
          payload.projects.forEach((project) => {
            Project.upsert(project);
          });
        }
        if (payload.communityProjects) {
          payload.communityProjects.forEach((project, index) => {
            Project.upsert({ ...project, isCommunityBoard: true, sortIndex: index });
          });
        }
        if (payload.projectProfessions) {
          updateProjectProfessions(Project, payload.projectProfessions);
        }
        if (payload.projectUserFavorites) {
          updateProjectUserFavorites(Project, payload.projectUserFavorites);
        }
        break;
      case ActionTypes.PROJECT_CREATE__SUCCESS:
      case ActionTypes.PROJECT_CREATE_HANDLE:
      case ActionTypes.PROJECT_UPDATE__SUCCESS:
      case ActionTypes.PROJECT_UPDATE_HANDLE:
      case ActionTypes.PROJECT_DUPLICATE__SUCCESS:
      case ActionTypes.PROJECT_THUMBNAIL_UPDATE__SUCCESS:
      case ActionTypes.PROJECT_RESTORE_FROM_TRASH__SUCCESS:
        Project.upsert(payload.project);
        if (payload.projectProfessions) {
          updateProjectProfessions(Project, payload.projectProfessions);
        }
        break;
      case ActionTypes.PROJECT_UPDATE: {
        const project = Project.withId(payload.id);
        project.update(payload.data);

        if (
          payload.data.backgroundImage === null &&
          project.background &&
          project.background.type === ProjectBackgroundTypes.IMAGE
        ) {
          project.background = null;
        }

        break;
      }
      case ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE:
        Project.withId(payload.id).update({
          isBackgroundImageUpdating: true,
        });

        break;
      case ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE__SUCCESS:
        Project.withId(payload.project.id).update({
          ...payload.project,
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.PROJECT_BACKGROUND_IMAGE_UPDATE__FAILURE:
        Project.withId(payload.id).update({
          isBackgroundImageUpdating: false,
        });

        break;
      case ActionTypes.PROJECT_THUMBNAIL_DELETE__SUCCESS:
        Project.withId(payload.project.id).update({
          ...payload.project,
          thumbnail: null,
        });
        break;
      case ActionTypes.PROJECT_DELETE: {
        const projectModel = Project.withId(payload.id);
        if (projectModel) {
          projectModel.deleteWithRelated();
        }
        break;
      }
      case ActionTypes.PROJECT_DELETE__SUCCESS:
      case ActionTypes.PROJECT_DELETE_HANDLE: {
        const projectModel = Project.withId(payload.project.id);
        if (projectModel) {
          projectModel.deleteWithRelated();
        }
        break;
      }
      case ActionTypes.PROJECT_SCHEDULE_FOR_DELETION__SUCCESS: {
        const projectModel = Project.withId(payload.id);

        if (projectModel) {
          projectModel.deleteWithRelated();
        }

        break;
      }
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE:
      case ActionTypes.BOARD_MEMBERSHIP_CREATE_HANDLE:
        if (payload.project) {
          const projectModel = Project.withId(payload.project.id);

          if (projectModel) {
            projectModel.deleteWithRelated();
          }

          Project.upsert(payload.project);
        }

        break;
      case ActionTypes.PROJECT_MANAGER_CREATE_HANDLE__PROJECT_FETCH:
      case ActionTypes.BOARD_MEMBERSHIP_CREATE_HANDLE__PROJECT_FETCH: {
        const projectModel = Project.withId(payload.id);

        if (projectModel) {
          projectModel.boards.toModelArray().forEach((boardModel) => {
            if (boardModel.id !== payload.currentBoardId) {
              boardModel.update({
                isFetching: null,
              });

              boardModel.deleteRelated(payload.currentUserId);
            }
          });
        }

        break;
      }
      case ActionTypes.PROFESSION_TO_PROJECT_ADD_HANDLE:
        try {
          Project.withId(payload.projectProfession.projectId).professions.add(
            payload.projectProfession.professionId,
          );
        } catch {
          // ignore
        }
        break;
      case ActionTypes.PROFESSION_FROM_PROJECT_REMOVE_HANDLE:
        try {
          Project.withId(payload.projectProfession.projectId).professions.remove(
            payload.projectProfession.professionId,
          );
        } catch {
          // ignore
        }
        break;
      case ActionTypes.LOAD_COMMUNITY_PROJECTS__SUCCESS: {
        let communityBoardCount: number;

        if (payload.page === 1) {
          try {
            Project.all().update({
              isCommunityBoard: false,
              sortIndex: null,
            });
          } catch {
            // ignore
          }
          communityBoardCount = 0;
        } else {
          communityBoardCount = Project.filter({ isCommunityBoard: true }).count();
        }

        payload.communityProjects.forEach((project, index) => {
          Project.upsert({
            ...project,
            isCommunityBoard: true,
            sortIndex: index + communityBoardCount,
          });
        });
        break;
      }
      default:
        break;
    }
  }

  getOrderedManagersQuerySet() {
    return this.withRelatedFields().managers.orderBy('createdAt');
  }

  getOrderedBoardsQuerySet() {
    return this.withRelatedFields().boards.orderBy('position');
  }

  getOrderedBoardsModelArrayForUser(userId) {
    return this.getOrderedBoardsQuerySet()
      .toModelArray()
      .filter((boardModel) => boardModel.hasMembershipForUser(userId));
  }

  getOrderedBoardsModelArrayAvailableForUser(userId) {
    if (this.hasManagerForUser(userId)) {
      return this.getOrderedBoardsQuerySet().toModelArray();
    }

    return this.getOrderedBoardsModelArrayForUser(userId);
  }

  hasManagerForUser(userId) {
    return this.withRelatedFields()
      .managers.filter({
        userId,
      })
      .exists();
  }

  hasMembershipInAnyBoardForUser(userId) {
    return this.withRelatedFields()
      .boards.toModelArray()
      .some((boardModel) => boardModel.hasMembershipForUser(userId));
  }

  isAvailableForUser(userId) {
    return (
      this.withRelatedFields().hasManagerForUser(userId) ||
      this.hasMembershipInAnyBoardForUser(userId)
    );
  }

  deleteRelated() {
    this.withRelatedFields().managers.delete();

    this.withRelatedFields()
      .boards.toModelArray()
      .forEach((boardModel) => {
        boardModel.deleteWithRelated();
      });
  }

  deleteWithRelated() {
    this.deleteRelated();
    this.delete();
  }

  private withRelatedFields() {
    // These properties are defined in other models as 'relatedName'
    return this as typeof this & {
      managers: any;
      boards: any;
    };
  }
}
