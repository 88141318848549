import { JOINER, MAX_PROFESSIONS } from '../constants/MaxProfessions';

export const formatProfessions = (professionNames: readonly string[]) => {
  const professionsString = professionNames.slice(0, MAX_PROFESSIONS).join(JOINER);
  const remainingProfessionsString = professionNames.slice(MAX_PROFESSIONS).join(JOINER);
  return {
    professionsString,
    remainingProfessionsString,
  };
};
