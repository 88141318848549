import { createSelector as createReduxOrmSelector } from 'redux-orm';
import { createSelector as createReselectSelector } from 'reselect';
import { selectCurrentUser } from './users';
import Paths from '../constants/Paths';
import orm from '../orm';
import matchPaths from '../utils/match-paths';

export const selectPathname = ({
  router: {
    location: { pathname },
  },
}) => pathname;

export const selectSearch = ({
  router: {
    location: { search },
  },
}) => ({
  search: search || '',
});

export const selectLocation = ({ router: { location } }) => location;

export const selectPathsMatch = createReselectSelector(selectPathname, (pathname) =>
  matchPaths(pathname, Object.values(Paths)),
);

const selectSearchMatch = createReselectSelector(selectSearch, ({ search }) => search);

const canAccessBoardRead = (projectModel, boardModel, user) => {
  if (projectModel?.isPublic) {
    return true;
  }
  return boardModel && (user?.isAdmin || boardModel.isAvailableForUser(user?.id));
};

const isBoardError = (boardModel) => {
  return (
    boardModel?.isPasswordRequired ||
    boardModel?.isAccessDenied ||
    boardModel?.isScheduledForDeletion
  );
};

export const selectPath = createReduxOrmSelector(
  orm,
  // @ts-ignore
  selectPathsMatch,
  selectSearchMatch,
  (state) => selectCurrentUser(state),
  ({ Project, Board, Card }, pathsMatch, search, currentUser) => {
    const currentUserId = currentUser?.id;
    if (pathsMatch) {
      if (pathsMatch.pathname === Paths.BOARD_IMPORT) {
        return { isImport: true };
      }
      switch (pathsMatch.pattern.path) {
        case Paths.PROJECTS: {
          const projectModel = Project.withId(pathsMatch.params.id);

          if (
            !projectModel ||
            (!projectModel.isAvailableForUser(currentUserId) && !projectModel.isPublic)
          ) {
            return {
              projectId: null,
            };
          }

          return {
            projectId: projectModel.id,
          };
        }
        case Paths.BOARDS: {
          const boardModel = Board.withId(pathsMatch.params.id);
          if (boardModel?.isFetching) {
            return {
              boardId: boardModel.id,
              projectId: boardModel.projectId,
              isSlideshow: false,
            };
          }
          if (isBoardError(boardModel)) {
            return {
              boardId: boardModel.id,
              projectId: boardModel.projectId,
              isSlideshow: false,
            };
          }

          const projectModel = Project.withId(boardModel?.projectId);
          if (!canAccessBoardRead(projectModel, boardModel, currentUser)) {
            return {
              boardId: null,
              projectId: null,
              isSlideshow: false,
            };
          }

          return {
            boardId: boardModel.id,
            projectId: boardModel.projectId,
            isSlideshow: false,
          };
        }
        case Paths.SLIDESHOW: {
          const boardModel = Board.withId(pathsMatch.params.id);
          const { slideNumber } = pathsMatch.params;
          if (boardModel?.isFetching) {
            return {
              boardId: boardModel.id,
              projectId: boardModel.projectId,
              isSlideshow: true,
              slideNumber,
            };
          }
          if (isBoardError(boardModel)) {
            return {
              boardId: boardModel.id,
              projectId: boardModel.projectId,
              isSlideshow: true,
            };
          }
          const projectModel = Project.withId(boardModel?.projectId);
          if (!canAccessBoardRead(projectModel, boardModel, currentUser)) {
            return {
              boardId: null,
              projectId: null,
              isSlideshow: true,
              slideNumber,
            };
          }

          return {
            boardId: boardModel.id,
            projectId: boardModel.projectId,
            isSlideshow: true,
            slideNumber,
          };
        }
        case Paths.CARDS: {
          const cardModel = Card.withId(pathsMatch.params.id);

          if (
            !cardModel ||
            !(
              currentUser?.isAdmin ||
              cardModel.isAvailableForUser(currentUserId) ||
              cardModel.list.board.project.isPublic
            )
          ) {
            return {
              cardId: null,
              boardId: null,
              projectId: null,
            };
          }

          return {
            cardId: cardModel.id,
            boardId: cardModel.boardId,
            projectId: cardModel.board.projectId,
          };
        }
        case Paths.BOARD_ACCESS: {
          const boardModel = Board.withId(pathsMatch.params.id);
          const projectModel = Project.withId(boardModel?.projectId);
          if (!canAccessBoardRead(projectModel, boardModel, currentUser)) {
            return {
              boardId: null,
              projectId: null,
              isBoardAccess: true,
            };
          }
          return {
            boardId: boardModel.id,
            projectId: boardModel.projectId,
            isBoardAccess: true,
          };
        }

        case Paths.BOARD_SETTINGS: {
          const boardModel = Board.withId(pathsMatch.params.id);
          const projectModel = Project.withId(boardModel?.projectId);
          if (!canAccessBoardRead(projectModel, boardModel, currentUser)) {
            return {
              boardId: null,
              projectId: null,
              isBoardSettings: true,
            };
          }
          return {
            boardId: boardModel.id,
            projectId: boardModel.projectId,
            isBoardSettings: true,
          };
        }
        case Paths.BOARD_DUPLICATE: {
          const boardModel = Board.withId(pathsMatch.params.id);
          const projectModel = Project.withId(boardModel?.projectId);
          if (!canAccessBoardRead(projectModel, boardModel, currentUser)) {
            return {
              boardId: null,
              projectId: null,
              isBoardDuplicate: true,
            };
          }
          return {
            boardId: boardModel.id,
            projectId: boardModel.projectId,
            isBoardDuplicate: true,
            projectName: projectModel.name,
          };
        }
        case Paths.IMPRINT:
          return {
            showBackButton: true,
            isImprint: true,
          };
        case Paths.DATA_PROTECTION:
          return {
            showBackButton: true,
            isDataProtection: true,
          };
        case Paths.LICENSES:
          return {
            showBackButton: true,
            isLicenses: true,
          };
        case Paths.TRASH:
          return {
            showBackButton: true,
            isTrash: true,
          };
        case Paths.ROOT:
          return {
            search,
            isSearch: Boolean(search && search.includes('?search')),
            showBackButton: Boolean(search && search.includes('?search')),
          };

        default:
          return {
            isNotFound: true,
          };
      }
    }

    return {
      isNotFound: true,
    };
  },
);
