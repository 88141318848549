import { storagePrefix } from '../constants/StoragePrefix.js';

export const saveState = (location, searchForm) => {
  const key = `${storagePrefix}navigation:${location.key}`;
  const value = JSON.stringify(searchForm);
  window.sessionStorage.setItem(key, value);
};

const tryParse = (value) => {
  try {
    const parsed = JSON.parse(value || 'null');
    return parsed;
  } catch {
    return null;
  }
};

export const getState = (location) => {
  const key = `${storagePrefix}navigation:${location.key}`;
  const value = window.sessionStorage.getItem(key);
  const parsed = tryParse(value);
  return parsed;
};
