/* eslint-disable no-shadow */
/* eslint-disable no-shadow */
import { Dropdown, DropdownItemProps, DropdownProps } from 'semantic-ui-react';

function Select<T>({
  onSelect,
  getText,
  getId,
  options,
  selectedId,
}: {
  onSelect: (selectedId: string) => void;
  getText: (option: T) => string;
  getId: (option: T) => string;
  options: readonly T[];
  selectedId: string;
}) {
  const wrappedOptions: DropdownItemProps[] = options.map((option) => {
    return {
      text: getText(option),
      key: getId(option),
      active: getId(option) === selectedId,
      value: getId(option),
    };
  });
  const handleSelect = (_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => {
    if (typeof data.value !== 'string') {
      return;
    }
    onSelect(data.value);
  };

  return (
    <div className="flex flex-col">
      <Dropdown
        fluid
        selection
        options={wrappedOptions}
        value={selectedId}
        onChange={handleSelect}
      />
    </div>
  );
}

export default Select;
