import { createSelector } from 'redux-orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const makeSelectCardById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }
      const { ref } = cardModel;
      const attachments = cardModel
        .getOrderedAttachmentsQuerySet()
        .toRefArray()
        .map((attachment) => ({
          ...attachment,
          isCover: attachment.id === cardModel.coverAttachmentId,
          isPersisted: !isLocalId(attachment.id),
        }));
      const mediaUrl = attachments?.[0]?.url || '';
      return {
        ...cardModel.ref,
        coverUrl: cardModel
          .getOrderedAttachmentsQuerySet()
          .first((attachment) => attachment.coverUrl)?.coverUrl,
        isPersisted: !isLocalId(id),
        mediaUrl,
      };
    },
  );

export const selectCardById = makeSelectCardById();

export const makeSelectUsersByCardId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      return cardModel.users.toRefArray();
    },
  );

export const selectUsersByCardId = makeSelectUsersByCardId();

export const makeSelectLastActivityIdByCardId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      const lastActivityModel = cardModel.getFilteredOrderedInCardActivitiesQuerySet().last();

      return lastActivityModel && lastActivityModel.id;
    },
  );

export const selectLastActivityIdByCardId = makeSelectLastActivityIdByCardId();

export const selectCurrentCard = createSelector(
  orm,
  (state) => state,
  (state) => selectPath(state).cardId,
  ({ Card }, state, id) => {
    return selectCardById(state, id);
  },
);

export const selectUsersForCurrentCard = createSelector(
  orm,
  (state) => selectPath(state).cardId,
  ({ Card }, id) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel.users.toRefArray();
  },
);

export const selectAttachmentsForCard = createSelector(
  orm,
  (state) => state,
  (id) => id,
  ({ Card }, state, id) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel
      .getOrderedAttachmentsQuerySet()
      .toRefArray()
      .map((attachment) => ({
        ...attachment,
        isCover: attachment.id === cardModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
  },
);

export const selectAttachmentsForCurrentCard = createSelector(
  orm,
  (state) => selectPath(state).cardId,
  ({ Card }, id) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel
      .getOrderedAttachmentsQuerySet()
      .toRefArray()
      .map((attachment) => ({
        ...attachment,
        isCover: attachment.id === cardModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
  },
);

export const makeSelectAttachmentForCardId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      if (!id) {
        return id;
      }

      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      const attachment = cardModel.getOrderedAttachmentsQuerySet().first();
      attachment.isCover = attachment.id === cardModel.coverAttachmentId;
      attachment.isPersisted = !isLocalId(attachment.id);

      return attachment;
    },
  );

export const selectAttachmentForCardId = makeSelectAttachmentForCardId();

export const selectActivitiesForCurrentCard = createSelector(
  orm,
  (state) => selectPath(state).cardId,
  (state) => selectCurrentUserId(state),
  ({ Card }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel
      .getFilteredOrderedInCardActivitiesQuerySet()
      .toModelArray()
      .map((activityModel) => ({
        ...activityModel.ref,
        isPersisted: !isLocalId(activityModel.id),
        user: {
          ...activityModel.user.ref,
          isCurrent: activityModel.user.id === currentUserId,
        },
      }));
  },
);
