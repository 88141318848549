import { all, apply, call, fork, select, take } from 'redux-saga/effects';
import services from './services';
import watchers from './watchers';
import api, { socket } from '../../api';
import ActionTypes from '../../constants/ActionTypes';
import Paths from '../../constants/Paths';
import selectors from '../../selectors';
import loginWatchers from '../login/watchers';

const getFullEndSessionUrl = (endSessionUrl, user) => {
  if (!user || !user.idToken) {
    return endSessionUrl;
  }
  const defaultUrl = new URL(endSessionUrl);
  defaultUrl.searchParams.append('id_token_hint', user.idToken);
  return defaultUrl.toString();
};

export default function* coreSaga() {
  yield all(watchers.map((watcher) => fork(watcher)));
  yield all(loginWatchers.map((watcher) => fork(watcher)));

  yield apply(socket, socket.connect);
  yield fork(services.initializeCore);

  yield take(ActionTypes.LOGOUT);

  const currentUser = yield select(selectors.selectCurrentUser);

  const oidcConfig = yield select(selectors.selectOidcConfig);

  if (oidcConfig && oidcConfig.endSessionUrl !== null) {
    const fullEndSessionUrl = getFullEndSessionUrl(oidcConfig.endSessionUrl, currentUser);
    window.location.href = fullEndSessionUrl;
  } else {
    window.location.href = Paths.ADMIN_LOGIN;
  }
}
