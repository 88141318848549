import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Icon, Input } from 'semantic-ui-react';
import { useToggle } from '../../../hooks';

const InputPassword = React.forwardRef(({ value, className, ...props }, ref) => {
  const [isVisible, toggleVisible] = useToggle();

  const handleToggleClick = useCallback(() => {
    toggleVisible();
  }, [toggleVisible]);

  const inputProps = {
    ...props,
    ref,
    type: isVisible ? 'text' : 'password',
    icon: <Icon link name={isVisible ? 'eye' : 'eye slash'} onClick={handleToggleClick} />,
  };

  return (
    <Input
      {...inputProps} // eslint-disable-line react/jsx-props-no-spreading
      className={className}
    />
  );
});

InputPassword.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
};

InputPassword.defaultProps = {
  className: undefined,
};

export default React.memo(InputPassword);
