/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import Flags from '../../constants/Flags';
import DefaultBoardContainer from '../../containers/BoardContainer';
import DefaultBoardInfoContainer from '../../containers/BoardInfoContainer';

function BoardWithBoardInfo({
  BoardInfoContainer = DefaultBoardInfoContainer,
  BoardContainer = DefaultBoardContainer,
  theme,
  boardScrollFull,
}: {
  theme?: any;
  BoardInfoContainer?: React.FC;
  BoardContainer?: React.FC;
  boardScrollFull?: boolean;
} = {}) {
  return (
    <div
      className={classNames(
        'flex flex-1',
        boardScrollFull ? 'overflow-y-scroll' : 'overflow-y-hidden',
      )}
      style={{
        backgroundColor: theme.value,
      }}
    >
      <div className={classNames('flex-col flex-1 md:mt-9', !boardScrollFull && 'flex')}>
        <div className="justify-center items-center w-full mx-auto hidden md:block px-3 mb-6 ">
          <BoardInfoContainer />
        </div>
        {Flags.BOARD_DEBUG_LOGS && (
          <div>
            <div id="log">logs</div>
            <br />
          </div>
        )}
        <BoardContainer />
        <div className="mt-auto" />
      </div>
    </div>
  );
}

export default BoardWithBoardInfo;
