/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import ModalTypes from '../../constants/ModalTypes.js';
import AboutModalContainer from '../../containers/AboutModalContainer';
import BoardAccessModalContainer from '../../containers/BoardAccessModalContainer.js';
import BoardDuplicateModalContainer from '../../containers/BoardDuplicateModalContainer.js';
import BoardImportModalContainer from '../../containers/BoardImportModalContainer';
import BoardSettingsModalContainer from '../../containers/BoardSettingsModalContainer.js';
import CardDuplicateModalContainer from '../../containers/CardDuplicateModalContainer.js';
import CardModalContainer from '../../containers/CardModalContainer.js';
import DescriptionEditConfirmModalContainer from '../../containers/DescriptionEditConfirmModalContainer';
import MediaPreviewModalContainer from '../../containers/MediaPreviewModalContainer';
import ProjectAddModalContainer from '../../containers/ProjectAddModalContainer';

function Noop() {
  return <div>Modal not found</div>;
}

const componentMap = {
  [ModalTypes.PROJECT_ADD]: ProjectAddModalContainer,
  [ModalTypes.ABOUT]: AboutModalContainer,
  [ModalTypes.BOARD_IMPORT]: BoardImportModalContainer,
  [ModalTypes.MEDIA_PREVIEW]: MediaPreviewModalContainer,
  [ModalTypes.CARD]: CardModalContainer,
  [ModalTypes.BOARD_ACCESS]: BoardAccessModalContainer,
  [ModalTypes.BOARD_SETTINGS]: BoardSettingsModalContainer,
  [ModalTypes.BOARD_DUPLICATE]: BoardDuplicateModalContainer,
  [ModalTypes.CARD_DUPLICATE]: CardDuplicateModalContainer,
  [ModalTypes.DESCRIPTION_EDIT_CONFIRM]: DescriptionEditConfirmModalContainer,
};

const getComponent = (modal) => {
  const component = componentMap[modal.type] || Noop;
  if (component === Noop) {
    console.warn(`modal not found`, modal);
  }
  return component;
};

function Modals({ modals }: { modals: any[] }) {
  return (
    <>
      {modals.map((modal) => {
        const Component = getComponent(modal);
        const { type, ...props } = modal;
        return <Component key={type} {...props} />;
      })}
    </>
  );
}

export default Modals;
