import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* importProjectCancel(data: { requestId: string }) {
  yield put(actions.importProjectCancel(data));
}

export function* importProjectCancelApi(data: { requestId: string }) {
  try {
    yield call(request, api.importProjectCancel, data);
  } catch (error) {
    yield put(actions.importProjectCancel.failure(error));
    return;
  }
  yield put(actions.importProjectCancel.success());
}
