import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  data: {},
  isSubmitting: false,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.PROJECT_DUPLICATE:
      return {
        ...state,
        isSubmitting: true,
        error: null,
      };
    case ActionTypes.PROJECT_DUPLICATE__SUCCESS:
      return initialState;
    case ActionTypes.PROJECT_DUPLICATE__FAILURE:
      return {
        ...state,
        isSubmitting: false,
        error: payload.error,
      };
    default:
      return state;
  }
};
