import React from 'react';
import { useTranslation } from 'react-i18next';
import { isProduction } from '../../constants/Flags';
import ErrorComponent from '../ErrorComponent/ErrorComponent.js';

const getErrorString = (error) => {
  if (error && error.message && error.stack) {
    return `${error.stack}`;
  }
  if (error && error instanceof Error) {
    return error.toString();
  }
  if (error && error.code) {
    return `Code: ${error.code}`;
  }
  return `Unknown Error: ${error}`;
};

function GlobalError({ globalError }: { globalError: unknown }) {
  const [t] = useTranslation();
  const showErrorLog = !isProduction();
  const errorString = showErrorLog ? getErrorString(globalError) : undefined;

  return (
    <div className="text-black flex justify-center items-center flex-col h-full">
      <ErrorComponent heading={t('common.oopsError')} subheading={errorString} image="" />
    </div>
  );
}

export default React.memo(GlobalError);
