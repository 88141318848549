/* eslint-disable no-shadow */
import classNames from 'classnames';
import PillListItem from '../PillListItem/PillListItem';

function PillList<T>({
  items,
  getId,
  getLabel,
  setSelectedItems,
  closeLabel,
  className,
}: {
  items: readonly T[];
  getId: (item: T) => string;
  getLabel: (item: T) => string;
  setSelectedItems: (items: readonly T[]) => void;
  closeLabel: string;
  className?: string;
}) {
  const onClose = (item: T) => {
    const otherItems = items.filter((existing) => existing !== item);
    setSelectedItems(otherItems);
  };
  return (
    <div
      className={classNames('flex border-none rounded-sm flex-wrap gap-3', className)}
      data-test-id="PillList"
    >
      {items.map((item) => {
        return (
          <PillListItem
            key={getId(item)}
            item={item}
            getLabel={getLabel}
            onClose={onClose}
            closeLabel={closeLabel}
          />
        );
      })}
    </div>
  );
}

export default PillList;
