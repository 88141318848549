import React from 'react';
import { useNavigate } from 'react-router-dom';

function HeaderMobileBackButton() {
  const navigate = useNavigate();
  const handleClickBack = () => {
    navigate('/');
  };
  return (
    <button
      aria-label="Zurück"
      type="button"
      className="w-7 flex justify-center shrink-0"
      onClick={handleClickBack}
    >
      <img src="/assets/images/icon-chevron-left.svg" alt="" className="h-5" />
    </button>
  );
}

export default React.memo(HeaderMobileBackButton);
