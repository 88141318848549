function HeaderSearchButton({ onClick }: { onClick: () => void }) {
  return (
    <button onClick={onClick} aria-label="Suchen" type="button">
      <div className="w-6 h-6 contain-strict ml-auto">
        <img src="/assets/images/icon-search.svg" alt="" className="" />
      </div>
    </button>
  );
}

export default HeaderSearchButton;
