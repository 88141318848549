/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { trash } from '../../constants/Paths';
import { MenuItem } from '../../types/MenuItemType';
import { getMainMenuItems } from '../../utils/getMainMenuItems.js';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItemButton from '../ContextMenuItemButton/ContextMenuItemButton.js';
import ContextMenuItemLink from '../ContextMenuItemLink/ContextMenuItemLink.js';
import ContextMenuItemSeparator from '../ContextMenuItemSeparator/ContextMenuItemSeparator.js';
import ContextMenuItemSmallText from '../ContextMenuItemSmallText/ContextMenuItemSmallText.js';

const renderItem = (menuItem: MenuItem, index: number) => {
  switch (menuItem.type) {
    case 'button':
      return <ContextMenuItemButton {...menuItem} key={index} />;
    case 'link':
      return <ContextMenuItemLink {...menuItem} key={index} />;
    case 'small-text':
      return <ContextMenuItemSmallText {...menuItem} key={index} />;
    case 'separator':
      return <ContextMenuItemSeparator {...menuItem} key={index} />;
    default:
      throw new Error(`unexpected menu item`);
  }
};

function UserStep({
  onLogout,
  openAbout,
  onClose,
  isLoggedIn,
  onAuthenticateUsingOidc,
  board,
  project,
  addBookmark,
  removeBookmark,
  user,
  canEdit,
  canDelete,
  isMobile,
  toggleScrollMode,
  showScrollChange,
  boardScrollFull,
  isPasswordProtected,
  handleDelete,
}: {
  isLoggedIn: boolean;
  isPasswordProtected: boolean;
  onClose: () => void;
  openAbout: () => void;
  onLogout: () => void;
  onAuthenticateUsingOidc: () => void;
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
  board: any;
  project: any;
  user: any;
  canEdit: boolean;
  isMobile: boolean;
  canDelete: boolean;
  toggleScrollMode: () => void;
  showScrollChange: boolean;
  boardScrollFull: boolean;
  handleDelete: () => void;
}) {
  const navigate = useNavigate();

  const wrappedOpenAbout = () => {
    openAbout();
    onClose();
  };

  const wrappedOpenTrashPage = () => {
    onClose();
    navigate(trash());
  };

  const handleBookmarkClick = () => {
    const fn = project.favorite ? removeBookmark : addBookmark;
    fn(project.id);
    onClose();
  };

  const items = getMainMenuItems({
    board,
    canEdit,
    handleBookmarkClick,
    isLoggedIn,
    onAuthenticateUsingOidc,
    onLogout,
    project,
    user,
    wrappedOpenAbout,
    wrappedOpenTrashPage,
    isMobile,
    toggleScrollMode,
    showScrollChange,
    boardScrollFull,
    handleDelete,
    canDelete,
    isPasswordProtected,
  });
  return <ContextMenu>{items.map(renderItem)}</ContextMenu>;
}

export default React.memo(UserStep);
