import ModalContainer from '../../containers/ModalContainer';
import MediaPreviewModalContent from '../MediaPreviewModalContent/MediaPreviewModalContent';

function MediaPreviewModal({ url, onClose }: { url: string; onClose: () => void }) {
  return (
    <ModalContainer open basic onClose={onClose}>
      <MediaPreviewModalContent url={url} onClose={onClose} />
    </ModalContainer>
  );
}

export default MediaPreviewModal;
