/* eslint-disable no-console */
import { eventBus } from './eventBus';
import { getTextSync } from './getTextSync';
import Config from '../constants/Config';
import Flags from '../constants/Flags';

const handleSearch = (event) => {
  const { detail } = event;
  const { searchValue, totalCount } = detail;
  // eslint-disable-next-line no-underscore-dangle
  const paq = globalThis._paq;
  if (!paq) {
    console.info('[tracking] paq not available');
    return;
  }
  paq.push(['trackSiteSearch', searchValue, false, totalCount]);
};

export const load = () => {
  if (!Flags.MATOMO || !Config.VITE_CI_MATOMO_ID) {
    console.info('[tracking] matomo is disabled');
    return;
  }
  const script = document.createElement('script');
  const text = getTextSync('/assets/snippets/matomo.txt');
  const fullText = text.replace('%MATOMO_ID%', Config.VITE_CI_MATOMO_ID);
  script.textContent = fullText;
  document.head.append(script);
  eventBus.addEventListener('mikado:search', handleSearch);
};
