import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './Slideshow.module.scss';
import ContentPreview from '../ContentPreview/ContentPreview.js';

function CardSlide({
  title,
  content,
  embedUrl,
  mediaUrl,
  ogDescription,
  ogImage,
  ogTitle,
  isMobile,
  coverUrl,
  MediaPreviewContainer,
}: {
  title: string;
  content: string;
  embedUrl: string;
  mediaUrl: string;
  coverUrl?: string;
  ogDescription: string;
  ogImage: string;
  ogTitle: string;
  isMobile: boolean;
  MediaPreviewContainer: any;
}) {
  const [canScroll, setCanScroll] = useState(false);

  const scrollToRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef(null);

  const updateScrollState = (scrollHeight: number, scrollTop: number, clientHeight: number) => {
    const scrollPositionFromBottom = Math.floor(scrollHeight - (scrollTop + clientHeight));
    setCanScroll(scrollPositionFromBottom > 1);
  };

  useEffect(() => {
    const element = contentRef.current;
    if (!element) {
      return () => {};
    }
    const callback = () =>
      updateScrollState(element.scrollHeight, element.scrollTop, element.clientHeight);
    const options: AddEventListenerOptions = {
      passive: true,
    };
    element.addEventListener('scroll', callback, options);

    updateScrollState(element.scrollHeight, element.scrollTop, element.clientHeight);

    const resizeObserver = new ResizeObserver(() => {
      updateScrollState(element.scrollHeight, element.scrollTop, element.clientHeight);
    });

    resizeObserver.observe(element);
    return () => {
      element.removeEventListener('scroll', callback, options);
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div
      className={classnames(
        'h-full w-full relative flex flex-col lg:flex-row-reverse gap-8 items-center justify-center box-border',
        'pt-12 pb-6 px-0 md:p-8 lg:px-20 lg:py-0 2xl:px-25 2xl:py-0',
      )}
    >
      {(mediaUrl || embedUrl) && (
        <div className="h-1/2 lg:h-full w-full lg:w-1/2 flex justify-center items-center p-0 md:p-8 contain-content">
          <MediaPreviewContainer
            url={coverUrl || mediaUrl || embedUrl}
            ogTitle={ogTitle}
            ogDescription={ogDescription}
            ogImage={ogImage}
            heightOfIframe={500}
            isLarge={!isMobile}
          />
        </div>
      )}

      {(content || title) && (
        <div
          className={classnames(
            'relative box-border flex flex-col justify-center space-y-8 2xl:space-y-12',
            mediaUrl || embedUrl ? 'w-full lg:w-1/2 h-1/2 lg:h-full' : 'w-full h-full',
          )}
        >
          <div>
            <h1
              className={classnames(
                'font-semibold break-words tracking-tight',
                'text-3xl leading-[34px]',
                'md:text-5xl md:leading-[48px]',
                '2xl:text-6xl 2xl:leading-[65px]',
                content ? 'text-start' : 'text-center',
              )}
            >
              {title}
            </h1>
          </div>
          {content && (
            <>
              <div
                ref={contentRef}
                className={classnames(
                  'no-scrollbar overflow-y-auto',
                  canScroll ? styles.fadeOut : '',
                )}
              >
                <div
                  className={classnames(
                    'flex flex-col gap-3 2xl:gap-4',
                    'box-border text-start text-black break-words tracking-tight',
                    'text-lg leading-7',
                    'md:text-2xl md:leading-[38px]',
                    '2xl:text-4xl 2xl:leading-[52px]',
                  )}
                >
                  <ContentPreview value={content} />
                </div>
                <div ref={scrollToRef} />
              </div>
              {canScroll && (
                <div
                  className={classnames('absolute -bottom-8 w-full flex justify-center items-end')}
                >
                  <button
                    aria-label="scroll down"
                    className="hover:cursor-pointer align-bottom border-none bg-transparent"
                    type="button"
                    onClick={() => {
                      scrollToRef.current?.scrollIntoView({
                        block: 'end',
                        inline: 'nearest',
                        behavior: 'smooth',
                      });
                    }}
                  >
                    <img
                      className="h-8 w-8"
                      src="/assets/images/icon-chevron-down.svg"
                      alt="scroll button"
                    />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default React.memo(CardSlide);
