import Embed from '../Embed/Embed';

function MediaPreviewEmbed({ url, heightOfIframe }: { url: string; heightOfIframe?: number }) {
  return (
    <div className="flex w-full h-full max-h-full contain-content" data-testid="MediaPreviewEmbed">
      <Embed url={url} heightOfIframe={heightOfIframe} />
    </div>
  );
}

export default MediaPreviewEmbed;
