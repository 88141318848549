import type { TFunction } from 'i18next';
import type { SortOrder } from '../types/SortOrder';

const translationMap: Record<SortOrder, string> = {
  created_desc: 'common.createdDescending',
  updated_desc: 'common.updatedDescending',
  most_favorited_desc: 'common.mostFavorited',
  most_forked_desc: 'common.mostForked',
};

export const getSortOrderLabel = (t: TFunction<'translation', undefined>, sortOrder: SortOrder) => {
  return t(translationMap[sortOrder]);
};
