import ActionTypes from '../constants/ActionTypes';
import { SortOrder } from '../types/SortOrder.js';

export const handleProfessionFromProjectRemove = (projectProfession) => ({
  type: ActionTypes.PROFESSION_FROM_PROJECT_REMOVE_HANDLE,
  payload: {
    projectProfession,
  },
});

export const handleProfessionToProjectAdd = (projectProfession) => ({
  type: ActionTypes.PROFESSION_TO_PROJECT_ADD_HANDLE,
  payload: {
    projectProfession,
  },
});

export const projectFilterUpdate = (professionIds: string[], sortOrder: SortOrder) => ({
  type: ActionTypes.PROJECT_FILTER_UPDATE,
  payload: {
    professionIds,
    sortOrder,
  },
});
