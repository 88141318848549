import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProjectAddModal from '../components/ProjectAddModal';
import entryActions from '../entry-actions';
import selectors from '../selectors/index.js';

const mapStateToProps = () => {
  return (state) => {
    const {
      ui: {
        projectCreateForm: { data: defaultData, isSubmitting, error },
      },
    } = state;
    const availableProfessions = selectors.selectProfessions(state);
    return {
      defaultData,
      isSubmitting,
      availableProfessions,
      error,
    };
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onCreate: entryActions.createProject,
      onClose: entryActions.closeModal,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddModal);
