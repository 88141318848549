export default {
  translation: {
    common: {
      emailOrUsername: 'E-Mail-Adresse oder Benutzername',
      invalidEmailOrUsername: 'Ungültige E-Mail-Adresse oder Benutzername',
      invalidPassword: 'Ungültiges Passwort',
      logInToPlanka: 'Einloggen',
      noInternetConnection: 'Keine Internetverbindung',
      pageNotFound_title: 'Seite nicht gefunden',
      password: 'Passwort',
      subTitle: 'Digitale Pinnwand für Lern- und Arbeitsaufgaben',
      serverConnectionFailed: 'Serververbindung fehlgeschlagen',
      unknownError: 'Unbekannter Fehler, bitte später erneut versuchen',
    },

    action: {
      logIn: 'Mit lokalem Benutzer einloggen',
      logInWithSSO: 'Einloggen',
      or: 'oder',
      registerWithSSO: 'Registrieren',
      logInWithLocalUser: 'Lokale Benutzer',
    },
  },
};
