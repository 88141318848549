import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { TextArea } from 'semantic-ui-react';
import styles from './NameField.module.scss';
import { useField } from '../../hooks';
import { useDidUpdate, usePrevious } from '../../lib/hooks';

const NameField = React.memo(
  ({ defaultValue, onUpdate, autofocus = false, onKeyDown, canBeEmpty }) => {
    const prevDefaultValue = usePrevious(defaultValue);
    const [value, handleChange, setValue] = useField(defaultValue);

    const isFocused = useRef(false);

    const handleFocus = useCallback(() => {
      isFocused.current = true;
    }, []);

    const handleKeyDown = useCallback(
      (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();

          event.target.blur();
        }
        if (onKeyDown) {
          onKeyDown(event);
        }
      },
      [onKeyDown],
    );

    const handleBlur = useCallback(() => {
      isFocused.current = false;

      const cleanValue = value.trim();

      if (cleanValue || canBeEmpty) {
        if (canBeEmpty || cleanValue !== defaultValue) {
          onUpdate(cleanValue);
        }
      } else {
        setValue(defaultValue);
      }
    }, [defaultValue, onUpdate, value, setValue, canBeEmpty]);

    useDidUpdate(() => {
      if (!isFocused.current && defaultValue !== prevDefaultValue) {
        setValue(defaultValue);
      }
    }, [defaultValue, prevDefaultValue, setValue]);

    const ref = useRef(null);

    useEffect(() => {
      const element = ref.current;
      if (autofocus && element) {
        element.focus();
      }
    }, [autofocus]);

    return (
      <TextArea
        as={TextareaAutosize}
        value={value}
        spellCheck={false}
        className={styles.field}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
      />
    );
  },
);

NameField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
  canBeEmpty: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default NameField;
