import { useTranslation } from 'react-i18next';
import NotFound from '../NotFound/NotFound';

function BoardAccessDenied() {
  const [t] = useTranslation();
  const heading = t('common.boardIsNotPublic');
  const subheading = t('common.canAskAdminToMakeBoardPublicOrPassword');
  const image = '/assets/images/access-denied.svg';
  return <NotFound heading={heading} subheading={subheading} image={image} />;
}

export default BoardAccessDenied;
