/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType';
import Button from '../Button/Button';
import FormBoardUrl from '../FormBoardUrl/FormBoardUrl';
import FormError from '../FormError/FormError';

function BoardImportModalContentDefault({
  handleSubmit,
  data,
  handleFieldChange,
  error,
}: {
  handleSubmit: () => void;
  data: { url: string };
  handleFieldChange: (...args: any[]) => void;
  error?: any;
}) {
  const [t] = useTranslation();
  const buttonMessage = t(error ? 'common.startImportAgain' : 'common.startImport');
  return (
    <Form onSubmit={handleSubmit} className="mt-2 flex flex-col flex-1">
      <FormBoardUrl data={data} handleFieldChange={handleFieldChange} />
      {error && <FormError error={error} />}

      <div className="mt-auto" />
      <div className="flex justify-end mt-5">
        <Button type="submit" variant={ButtonType.Green}>
          {buttonMessage}
        </Button>
      </div>
    </Form>
  );
}

export default React.memo(BoardImportModalContentDefault);
