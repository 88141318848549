import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType';
import { useScrollRestoration } from '../../hooks/useScrollRestoration';
import Button from '../Button/Button';
import Heading from '../Heading/Heading';
import SearchResult from '../SearchResult/SearchResult.js';
import SearchResultsError from '../SearchResultsError/SearchResultsError.js';
import SearchResultsNoResults from '../SearchResultsNoResults/SearchResultsNoResults.js';

function SearchResults({
  items,
  totalItemCount,
  error,
  canLoadMore,
  onLoadMore,
}: {
  items: any[];
  totalItemCount: number;
  error?: any;
  canLoadMore: boolean;
  onLoadMore: () => void;
}) {
  const [t] = useTranslation();

  const scrollRef = useScrollRestoration('searchResults');

  if (error) {
    return <SearchResultsError error={error} />;
  }

  if (items.length === 0) {
    return <SearchResultsNoResults />;
  }

  return (
    <div
      className="bg-bgGray flex-1 contain-content text-blue100 overflow-y-auto p-4 md:px-20 md:py-8 lg:px-44 lg:py-16"
      ref={scrollRef}
    >
      <Heading text={t('common.searchResults')} />
      <h3 className="text-blue50 pt-3">
        {totalItemCount} {t('common.matches')}
      </h3>
      <div className="flex flex-col mt-5 gap-8">
        {items.map((item) => {
          return <SearchResult item={item} key={item.id} />;
        })}
        {canLoadMore && (
          <Button variant={ButtonType.OutlineGreen} onClick={onLoadMore} className="self-start">
            {t('action.loadMore')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
