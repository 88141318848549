import ExternalLink from '../ExternalLink/ExternalLink.js';

function Logos() {
  return (
    <div className="flex gap-4 items-start flex-wrap">
      <ExternalLink url="https://www.bmbf.de" className="h-7 md:h-11 relative mr-2 -mb-2">
        <div className="uppercase text-black text-[6px] leading-normal absolute bottom-full mb-0.5">
          Gefördert vom
        </div>
        <img
          src="/assets/images/bmbf.svg"
          alt="Bundesministerium für Bildung und Forschung"
          className="h-full"
        />
      </ExternalLink>
      <ExternalLink url="https://www.bibb.de" className="h-5 md:h-7">
        <img
          src="/assets/images/bibb.png"
          alt="Bundesinstitut für Berufsbildung"
          className="h-full"
        />
      </ExternalLink>
      <ExternalLink url="https://www.leando.de" className="h-5 md:h-7">
        <img src="/assets/images/leando.svg" alt="Leando" className="h-full" />
      </ExternalLink>
    </div>
  );
}

export default Logos;
