import { useTranslation } from 'react-i18next';
import { useForm } from '../../hooks';
import type { Profession } from '../../types/Profession';
import { sortProfessions } from '../../utils/sortProfessions.js';
import ProfessionsComboBox from '../ProfessionsComboBox/ProfessionsComboBox';
import SubHeading from '../SubHeading/SubHeading.js';

const getId = (profession: Profession) => {
  return profession.id;
};

function ProjectsFilter({
  professionIds,
  updateFilter,
  professions,
}: {
  professionIds: string[];
  updateFilter: (professionIds: string[]) => void;
  professions: readonly Profession[];
}) {
  const [t] = useTranslation();

  const [data, handleFieldChange] = useForm(() => ({
    professions: professions.filter((profession) => professionIds.includes(profession.id)),
    professionsValue: '',
  }));

  const test = (event: any, args: any) => {
    handleFieldChange(event, args);

    if (args.name === 'professions') {
      updateFilter(args.value.map(getId));
    }
  };

  const sortedProfessions = sortProfessions(professions);

  return (
    <div className="flex flex-col max-w-[400px] flex-1">
      <div className="mt-2" />
      <SubHeading text={t('common.professionOrProfessions')} />
      <div className="mt-2" />
      <ProfessionsComboBox
        data={data}
        handleFieldChange={test}
        availableProfessions={sortedProfessions}
        showPlaceholder
      />
    </div>
  );
}

export default ProjectsFilter;
