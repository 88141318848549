/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'semantic-ui-react';
import styles from './ListHeader.module.scss';
import Lengths from '../../constants/Lengths';
import NameEdit from '../List/NameEdit.jsx';

const ListHeader = React.memo(
  ({
    name,
    isPersisted,
    canEdit,
    onDelete,
    dragHandleProps,
    handleHeaderClick,
    ActionsPopup,
    nameEdit,
    handleNameUpdate,
    handleNameEdit,
    handleCardAdd,
    isMobile,
  }) => {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...dragHandleProps}
        className={classNames(
          styles.header,
          canEdit && styles.headerEditable,
          'bg-orange rounded-sm py-3 pr-8 pl-2',
          !isMobile && 'min-h-[72px]',
        )}
        onClick={handleHeaderClick}
        data-test-id="ListHeader"
      >
        <NameEdit
          ref={nameEdit}
          defaultValue={name}
          onUpdate={handleNameUpdate}
          maxLength={Lengths.CARD_TITLE_MAX_LENGTH}
        >
          <div className={classNames(styles.headerName)}>
            <div className="line-clamp-2 hyphens-auto">{name}</div>
          </div>
        </NameEdit>
        {isPersisted && canEdit && (
          <ActionsPopup onNameEdit={handleNameEdit} onCardAdd={handleCardAdd} onDelete={onDelete}>
            <Button className={classNames(styles.headerButton, styles.target, 'right-2 top-2')}>
              <img src="/assets/images/icon-dots-white.svg" alt="" className="w-5 h-5" />
            </Button>
          </ActionsPopup>
        )}
      </div>
    );
  },
);

ListHeader.propTypes = {
  name: PropTypes.string.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  dragHandleProps: PropTypes.object,
  handleHeaderClick: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  ActionsPopup: PropTypes.object.isRequired,
  handleNameUpdate: PropTypes.func.isRequired,
  handleNameEdit: PropTypes.func.isRequired,
  handleCardAdd: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  nameEdit: PropTypes.object.isRequired,
};

export default ListHeader;
