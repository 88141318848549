import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* handleProjectCreate({ id }) {
  let project;
  let users;
  let projectManagers;
  let boards;
  let boardMemberships;
  const plainTextPassword = '';

  try {
    ({
      item: project,
      included: { users, projectManagers, boards, boardMemberships },
    } = yield call(request, api.getProject, id, plainTextPassword));
  } catch (error) {
    return;
  }

  yield put(actions.handleProjectCreate(project, users, projectManagers, boards, boardMemberships));
}
