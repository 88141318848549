import { useTranslation } from 'react-i18next';
import NotFound from '../NotFound/NotFound';

function BoardScheduledForDeletion() {
  const [t] = useTranslation();
  const heading = t('common.boardIsScheduledForDeletion');
  const subheading = t('common.boardIsScheduledForDeletionDetail');
  return <NotFound heading={heading} subheading={subheading} />;
}

export default BoardScheduledForDeletion;
