/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import { useTranslation } from 'react-i18next';
import ComboBox from '../ComboBox/ComboBox.js';
import PillList from '../PillList/PillList.js';

function MultiSelectComboBox<T>({
  options,
  selectedOptions,
  getLabel,
  getId,
  setSelectedOptions,
  placeholder,
  value,
  setValue,
}: {
  options: readonly T[];
  selectedOptions: readonly T[];
  getLabel: (option: T) => string;
  getId: (option: T) => string;
  setSelectedOptions: (options: readonly T[]) => void;
  placeholder?: string;
  value?: string;
  setValue?: (value: string) => void;
}) {
  const [t] = useTranslation();
  return (
    <>
      {selectedOptions.length > 0 && (
        <div className="pt-1 pb-3">
          <PillList
            items={selectedOptions}
            getId={getId}
            getLabel={getLabel}
            setSelectedItems={setSelectedOptions}
            closeLabel={t('common.delete')}
          />
        </div>
      )}
      <ComboBox
        options={options}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        placeholder={placeholder}
        value={value}
        setValue={setValue}
        getId={getId}
        getLabel={getLabel}
      />
    </>
  );
}

export default MultiSelectComboBox;
