import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BoardSettingsModal from '../components/BoardSettingsModal/BoardSettingsModal';
import { boardColors } from '../constants/BoardColors.js';
import * as Paths from '../constants/Paths';
import entryActions from '../entry-actions/index';
import { push } from '../lib/redux-router/index';
import selectors from '../selectors/index';
import { getProjectThumbnailUrl } from '../utils/getProjectThumbnailUrl';

const mapStateToProps = (state) => {
  const {
    ui: {
      projectSettingsForm: { isSubmitting, error, isThumbnailUpdating },
    },
  } = state;
  const { projectId, boardId } = selectors.selectPath(state);
  const project = selectors.selectCurrentProject(state);
  const availableProfessions = selectors.selectProfessions(state);
  const professions = selectors.selectProfessionsByProjectId(state, projectId);
  const defaultData = {
    name: project.name,
    subtitle: project.subtitle ?? '',
    professions,
    background: project.background ?? '',
  };
  const canEdit = selectors.selectCanEditBoard(state);
  const canExport = selectors.selectCanExportBoard(state);
  const colors = boardColors.map((color) => color.value);
  const thumbnailUrl = getProjectThumbnailUrl(project);
  return {
    projectId,
    boardId,
    isBoardAccess: true,
    canEdit,
    canExport,
    defaultData,
    isSubmitting,
    availableProfessions,
    error,
    thumbnailUrl,
    isThumbnailUpdating,
    colors,
    mediaPreviewUrl: thumbnailUrl,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      push,
      onSave: entryActions.updateCurrentProject,
      onBackgroundImageUpdate: entryActions.updateCurrentProjectBackgroundImage,
      onThumbnailUpdate: entryActions.updateCurrentProjectThumbnail,
      onThumbnailDelete: entryActions.deleteCurrentProjectThumbnail,
    },
    dispatch,
  );

const mergeProps = (stateProps, dispatchProps) => ({
  ...stateProps,
  ...dispatchProps,
  onClose: () => dispatchProps.push(Paths.getBoardPath('', stateProps.boardId)),
  onExport: () => {
    // hack
    const a = document.createElement('a');
    a.href = Paths.getExportUrl(stateProps.projectId);
    a.target = '_blank';
    document.body.append(a);
    a.click();
    a.remove();
  },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(BoardSettingsModal);
