/* eslint-disable no-console */
import { call } from 'redux-saga/effects';
import api from '../../../api';
import { getAccessToken } from '../../../utils/access-token-storage';
import mergeRecords from '../../../utils/merge-records';
import request from '../request';

export function* fetchCore() {
  const accessToken = yield call(getAccessToken);
  let user: any;
  let users1: any;
  if (accessToken) {
    ({ item: user } = yield call(request, api.getCurrentUser, true));
    ({ items: users1 } = yield call(request, api.getUsers));
  }

  const {
    items: projects1,
    included: { projectManagers, boards, boardMemberships: boardMemberships1 },
  } = yield call(request, api.getProjects);

  const {
    items: communityProjects,
    included: {
      projectManagers: projectManagersFromCommunityProjects,
      boards: boardsFromCommunityProjects,
      boardMemberships: boardMembershipsFromCommunityProjects,
    },
    canLoadMoreCommunityProjects,
    // just get the first page (loading more pages is done elsewhere)
  } = yield call(request, api.getCommunityProjects, { page: 1 });

  const professions = yield call(request, api.getProfessions);
  const projectUserFavorites = yield call(request, api.getBookmarks);

  let board;
  let users2;
  let projects2;
  let boardMemberships2;
  let lists;
  let cards1;
  let attachments;
  let projectProfessions;

  return {
    user,
    board,
    projectManagers: mergeRecords(projectManagers, projectManagersFromCommunityProjects),
    boards: mergeRecords(boards, boardsFromCommunityProjects),
    lists,
    attachments,
    users: mergeRecords(users1, users2),
    projects: mergeRecords(projects1, projects2),
    communityProjects,
    boardMemberships: mergeRecords(
      boardMemberships1,
      boardMemberships2,
      boardMembershipsFromCommunityProjects,
    ),
    cards: mergeRecords(cards1),
    professions,
    projectProfessions,
    canLoadMoreCommunityProjects,
    projectUserFavorites,
  };
}

export default {
  fetchCore,
};
