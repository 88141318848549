import ActionTypes from '../../constants/ActionTypes';

interface State {
  readonly allowedMediaPreviewTypes: readonly number[];
}

const initialState: State = {
  allowedMediaPreviewTypes: [],
};

// eslint-disable-next-line default-param-last
export default (state: State = initialState, { type, payload }): State => {
  switch (type) {
    case ActionTypes.CONSENT_MEDIA_PREVIEW_ALLOW:
      return {
        ...state,
        allowedMediaPreviewTypes: [...state.allowedMediaPreviewTypes, payload.type],
      };
    default:
      return state;
  }
};
