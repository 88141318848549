import { transformAttachment } from './attachments';
import { transformBoardMembership } from './board-memberships';
import { transformCard } from './cards';
import http from './http';
import socket from './socket';
import { transformUser } from './users';

/* Actions */

export const createBoard = (projectId, data, headers) =>
  socket.post(`/projects/${projectId}/boards`, data, headers).then((body) => ({
    ...body,
    included: {
      ...body.included,
      boardMemberships: body.included.boardMemberships.map(transformBoardMembership),
    },
  }));

export const createBoardWithImport = (projectId, data, requestId, headers) =>
  http.post(`/projects/${projectId}/boards?requestId=${requestId}`, data, headers);

export const getBoard = (
  id: string,
  subscribe: boolean,
  plainTextPassword: string,
  headers: any,
) => {
  return socket
    .get(
      `/boards/${id}`,
      {
        subscribe,
        plainTextPassword: plainTextPassword || '',
      },
      headers,
    )
    .then((body) => ({
      ...body,
      included: {
        ...body.included,
        users: body.included.users.map(transformUser),
        boardMemberships: body.included.boardMemberships.map(transformBoardMembership),
        cards: body.included.cards.map(transformCard),
        attachments: body.included.attachments.map(transformAttachment),
      },
    }));
};

export const updateBoard = (id, data, headers) => socket.patch(`/boards/${id}`, data, headers);

export const deleteBoard = (id, headers) => socket.delete(`/boards/${id}`, undefined, headers);
