/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType';
import type { ProjectDropDownOption } from '../../types/ProjectDropDownOption';
import Button from '../Button/Button';
import Form from '../Form/Form';
import FormError from '../FormError/FormError';
import SelectBox from '../SelectBox/SelectBox';

const getId = (project: any) => {
  return project.id;
};

const getLabel = (project: any) => {
  return project.name;
};

const getProjectOptions = (
  projects: any[],
  projectId: string,
): readonly ProjectDropDownOption[] => {
  const projectOptions = [];
  projectOptions.push({
    name: 'Aktuelles Board',
    id: projectId,
  });
  for (const project of projects) {
    if (project.id === projectId) {
      continue;
    }
    projectOptions.push({
      name: project.name,
      id: project.id,
    });
  }
  return projectOptions;
};

function CardDuplicateModalContent({
  projects,
  currentProject,
  handleSubmit,
  error,
  isSubmitting,
  data,
  handleFieldChange,
}: {
  projects: any[];
  currentProject: any;
  handleSubmit: () => void;
  error?: any;
  isSubmitting: boolean;
  data: {
    targetProjectId: string;
  };
  handleFieldChange: (...args: any[]) => void;
}) {
  const [t] = useTranslation();
  const setSelectedOptionId = (id) => {
    handleFieldChange(null, {
      name: 'targetProjectId',
      value: id,
    });
  };
  const projectOptions = getProjectOptions(projects, currentProject.id);
  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <span className="text-2xl text-blue100 font-bold">{t('common.duplicateCard')}</span>
      </header>

      <Form onSubmit={handleSubmit} className="mt-2">
        <div>{t('common.targetProject')}</div>

        <SelectBox
          options={projectOptions}
          getId={getId}
          getLabel={getLabel}
          selectedOptionId={data.targetProjectId}
          setSelectedOptionId={setSelectedOptionId}
        />
        <FormError error={error} />

        <div className="flex justify-end mt-5">
          <Button type="submit" disabled={isSubmitting} variant={ButtonType.Green}>
            {t('common.copy')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CardDuplicateModalContent;
