import ActionTypes from '../../constants/ActionTypes';
import { eventBus } from '../../utils/eventBus.js';

const initialState = {
  committedSearchValue: '',
  searchValue: '',
  searchResults: [],
  totalCount: 0,
  currentPage: 0,
  error: null,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SEARCH_VALUE_UPDATE:
      return {
        ...state,
        ...payload,
      };
    case ActionTypes.SEARCH__SUCCESS: {
      const newState = {
        ...state,
        ...payload,
        error: null,
        currentPage: 0,
      };
      eventBus.dispatchEvent(
        new CustomEvent('mikado:search', {
          detail: {
            searchValue: newState.searchValue,
            totalCount: newState.totalCount,
          },
        }),
      );
      return newState;
    }
    case ActionTypes.SEARCH_LOAD_MORE__SUCCESS:
      return {
        ...state,
        ...payload,
        searchResults: [...state.searchResults, ...payload.searchResults],
        currentPage: payload.currentPage,
        error: null,
      };
    case ActionTypes.SEARCH__FAILURE:
    case ActionTypes.SEARCH_LOAD_MORE__FAILURE:
      return {
        ...state,
        error: payload.error,
      };
    case ActionTypes.SEARCH_RESTORE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
