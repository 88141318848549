import ActionTypes from '../constants/ActionTypes';

export const updateSearchValue = (searchValue) => ({
  type: ActionTypes.SEARCH_VALUE_UPDATE,
  payload: {
    searchValue,
  },
});

export const searchExpand = () => ({
  type: ActionTypes.HEADER_SEARCH_EXPAND,
  payload: {},
});

export const searchRestore = (payload) => ({
  type: ActionTypes.SEARCH_RESTORE,
  payload,
});

export const searchCollapse = () => ({
  type: ActionTypes.HEADER_SEARCH_COLLAPSE,
  payload: {},
});

export const search = (data) => ({
  type: ActionTypes.SEARCH,
  payload: { data },
});

search.success = (searchResults, totalCount: number, searchValue: string) => ({
  type: ActionTypes.SEARCH__SUCCESS,
  payload: {
    searchResults,
    totalCount,
    searchValue,
  },
});

search.failure = (error) => ({
  type: ActionTypes.SEARCH__FAILURE,
  payload: { error },
});

export const loadMoreSearchResults = (data) => ({
  type: ActionTypes.SEARCH_LOAD_MORE,
  payload: { data },
});

loadMoreSearchResults.success = (searchResults, totalCount: number, currentPage: number) => ({
  type: ActionTypes.SEARCH_LOAD_MORE__SUCCESS,
  payload: {
    searchResults,
    totalCount,
    currentPage,
  },
});

loadMoreSearchResults.failure = (error) => ({
  type: ActionTypes.SEARCH_LOAD_MORE__FAILURE,
  payload: { error },
});
