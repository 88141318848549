import React, { useCallback } from 'react';
import Paths from '../../constants/Paths';
import ModalContainer from '../../containers/ModalContainer';
import { useForm } from '../../hooks';
import BoardImportModalContent from '../BoardImportModalContent/BoardImportModalContent';

function BoardImportModal({
  defaultData,
  isSubmitting,
  onUpdate,
  onClose,
  onCancel,
  boardId,
  isSuccess,
  error,
  successUrl,
  requestId,
}: {
  defaultData: object;
  isSubmitting: boolean;
  onUpdate: (data: any, redirectTo?: string) => void;
  onClose: () => void;
  onCancel: (requestId: string) => void;
  boardId: string;
  error?: any;
  isSuccess: boolean;
  successUrl: string;
  requestId: string;
}) {
  const [data, handleFieldChange] = useForm(() => ({
    isPublic: false,
    ...defaultData,
  }));

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      url: data.url.trim(),
    };
    const redirectTo = Paths.BOARDS.replace(':id', boardId);
    onUpdate(cleanData, redirectTo);
  }, [onUpdate, data, boardId]);

  const handleCancel = useCallback(() => {
    onCancel(requestId);
  }, [onCancel, requestId]);

  return (
    <ModalContainer open basic size="tiny" onClose={onClose}>
      <BoardImportModalContent
        data={data}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handleFieldChange={handleFieldChange}
        error={error}
        isSuccess={isSuccess}
        onClose={onClose}
        onCancel={handleCancel}
        successUrl={successUrl}
      />
    </ModalContainer>
  );
}

export default React.memo(BoardImportModal);
