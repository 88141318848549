/* eslint-disable react/jsx-props-no-spreading */
import Flags from '../../constants/Flags';
import ContentEditorMde from '../ContentEditorMde/ContentEditorMde';
import ContentEditorQuill from '../ContentEditorQuill/ContentEditorQuill';

function ContentEditor(props) {
  const Component = Flags.CONTENT_EDITOR_QUILL ? ContentEditorQuill : ContentEditorMde;
  return <Component {...props} />;
}

export default ContentEditor;
