/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '../../lib/custom-ui/index';

function FormBoardUrl({
  data,
  handleFieldChange,
}: {
  data: {
    url: string;
  };
  handleFieldChange: (...args: any[]) => void;
}) {
  const [t] = useTranslation();

  const handleUrlChange = (event: any) => {
    handleFieldChange(null, {
      name: 'url',
      value: event.target.value,
    });
  };

  return (
    <>
      <div>{t('common.padletBoardUrl')}</div>
      <Input
        type="text"
        value={data.url}
        data-testid="url"
        onChange={handleUrlChange}
        className="w-full"
      />
    </>
  );
}

export default React.memo(FormBoardUrl);
