import attachments from './attachments';
import boardMemberships from './board-memberships';
import * as boards from './boards';
import * as cards from './cards';
import * as consent from './consent';
import core from './core';
import lists from './lists';
import login from './login';
import * as modals from './modals';
import professions from './professions';
import projectManagers from './project-managers';
import * as projects from './projects';
import * as search from './search';
import slideshow from './slideshow';
import socket from './socket';
import users from './users';

export default {
  ...socket,
  ...login,
  ...core,
  ...modals,
  ...users,
  ...projects,
  ...projectManagers,
  ...boards,
  ...boardMemberships,
  ...lists,
  ...cards,
  ...attachments,
  ...professions,
  ...slideshow,
  ...search,
  ...consent,
};
