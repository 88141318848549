import { put } from 'redux-saga/effects';
import actions from '../../../actions';
import entryActions from '../../../entry-actions';
import { SortOrder } from '../../../types/SortOrder.js';

export function* handleProfessionToProjectAdd(projectProfession) {
  yield put(actions.handleProfessionToProjectAdd(projectProfession));
}

export function* handleProfessionFromProjectRemove(projectProfession) {
  yield put(actions.handleProfessionFromProjectRemove(projectProfession));
}

// TODO move to homepage filters
export function* projectFilterUpdate(professionIds: string[], sortOrder: SortOrder, page: number) {
  if (page === 1) {
    yield put(actions.projectFilterUpdate(professionIds, sortOrder));
  }

  yield put(entryActions.loadCommunityProjects(professionIds, sortOrder, page));
}
