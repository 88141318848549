import ActionTypes from '../../constants/ActionTypes';

export const restoreProjectFromTrash = (id) => ({
  type: ActionTypes.PROJECT_RESTORE_FROM_TRASH,
  payload: {
    id,
  },
});

restoreProjectFromTrash.success = (project) => ({
  type: ActionTypes.PROJECT_RESTORE_FROM_TRASH__SUCCESS,
  payload: {
    project,
  },
});

restoreProjectFromTrash.failure = (id, error) => ({
  type: ActionTypes.PROJECT_RESTORE_FROM_TRASH__FAILURE,
  payload: {
    id,
    error,
  },
});
