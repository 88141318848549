import sailsIOClient from 'sails.io.js';
import socketIOClient from 'socket.io-client';
import Config from '../constants/Config';
import Flags from '../constants/Flags';

const io = sailsIOClient(socketIOClient);

io.sails.url = Config.SERVER_HOST_NAME;
io.sails.autoConnect = false;
io.sails.reconnection = true;
io.sails.useCORSRouteToGetCookie = false;
io.sails.environment = import.meta.env.MODE;

const { socket } = io;

socket.path = `${Config.BASE_PATH}/socket.io`;
socket.connect = socket._connect; // eslint-disable-line no-underscore-dangle

const restoreError = (value) => {
  if (value && value.message && value.stack) {
    const error = new Error();
    error.message = value.message;
    error.stack = value.stack;
    error.code = value.code;
    return error;
  }
  return value;
};

['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].forEach((method) => {
  socket[method.toLowerCase()] = async (url, data, headers) => {
    const response = await new Promise((resolve, reject) => {
      socket.request(
        {
          method,
          data,
          headers,
          url: `/api${url}`,
        },
        (_, response) => {
          resolve(response);
        },
      );
    });
    if (response.error) {
      const error = restoreError(response.body);
      throw error;
    }
    return response.body;
  };
});

if (Flags.IS_GOOGLE_BOT) {
  ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'].forEach((method) => {
    socket[method.toLowerCase()] = async (url, data, headers) => {
      const response = await fetch(`/api${url}`, {
        data,
        method,
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error for ${url} status: ${response.status}`);
      }
      const result = await response.json();
      return result;
    };
  });
  // eslint-disable-next-line no-console
  console.info('[socket] using fallback for google bot');
  socket.connect = () => {
    // noop
  };
}

export default socket;
