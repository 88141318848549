import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSteps } from '../../hooks';
import ContextMenu from '../ContextMenu/ContextMenu';
import ContextMenuItem from '../ContextMenuItem/ContextMenuItem';
import DeleteStep from '../DeleteStep';

const StepTypes = {
  DELETE: 'DELETE',
};

const ActionsStep = React.memo(({ onNameEdit, onCardAdd, onDelete, onClose }) => {
  const [t] = useTranslation();
  const [step, openStep, handleBack] = useSteps();

  const handleEditNameClick = useCallback(() => {
    onNameEdit();
    onClose();
  }, [onNameEdit, onClose]);

  const handleAddCardClick = useCallback(() => {
    onCardAdd();
    onClose();
  }, [onCardAdd, onClose]);

  const handleDeleteClick = useCallback(() => {
    openStep(StepTypes.DELETE);
  }, [openStep]);

  if (step && step.type === StepTypes.DELETE) {
    return (
      <DeleteStep
        title="common.deleteList"
        content="common.areYouSureYouWantToDeleteThisList"
        buttonContent="action.deleteList"
        onConfirm={onDelete}
        onBack={handleBack}
      />
    );
  }

  return (
    <ContextMenu>
      <ContextMenuItem onClick={handleEditNameClick}>
        {t('action.editTitle', {
          context: 'title',
        })}
      </ContextMenuItem>
      <ContextMenuItem onClick={handleAddCardClick}>
        {t('action.addCard', {
          context: 'title',
        })}
      </ContextMenuItem>
      <ContextMenuItem onClick={handleDeleteClick} isDelete>
        {t('action.deleteList', {
          context: 'title',
        })}
      </ContextMenuItem>
    </ContextMenu>
  );
});

ActionsStep.propTypes = {
  onNameEdit: PropTypes.func.isRequired,
  onCardAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ActionsStep;
