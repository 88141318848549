import classnames from 'classnames';
import React from 'react';

function ListHeaderSlide({ title }: { title: string }) {
  return (
    <div
      className={classnames(
        'box-border h-full relative flex justify-center items-center py-24 px-8',
        'md:p-24',
        'lg:justify-start lg:items-end lg:ps-10 lg:p-32',
      )}
    >
      <h1
        className={classnames(
          'w-full hyphens-auto font-semibold text-center lg:text-start text-black text-5xl tracking-tight leading-[52px]',
          'md:text-6xl md:tracking-normal md:leading-[75px]',
          'lg:text-8xl lg:tracking-normal lg:leading-[104px]',
        )}
      >
        {title}
      </h1>
    </div>
  );
}

export default React.memo(ListHeaderSlide);
