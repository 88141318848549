import { useTranslation } from 'react-i18next';

function NewBoardIsPublicHint() {
  const [t] = useTranslation();
  const prefix = t('common.newBoardIsPublicPrefix');
  const postfix = t('common.newBoardIsPublicPostfix');

  return (
    <div className="">
      <span>{prefix}</span>
      <span className="w-4 h-4 inline-block bg-white ml-0.5 mr-0.5">
        <img src="/assets/images/icon-lock.svg" alt="" className="w-full h-full mt-0.5" />
      </span>
      <span>{postfix}</span>
    </div>
  );
}

export default NewBoardIsPublicHint;
