import EntryActionTypes from '../constants/EntryActionTypes';

const handleProfessionAddToProject = (id, projectId) => ({
  type: EntryActionTypes.PROFESSION_TO_PROJECT_ADD,
  payload: {
    id,
    projectId,
  },
});

const handleProfessionRemoveFromProject = (projectProfession) => ({
  type: EntryActionTypes.PROFESSION_FROM_PROJECT_REMOVE,
  payload: {
    projectProfession,
  },
});

export default {
  handleProfessionAddToProject,
  handleProfessionRemoveFromProject,
};
