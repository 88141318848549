import OpenGraphInfo from '../OpenGraphInfo/OpenGraphInfo';

function MediaPreviewOpenGraph({
  url,
  ogImage,
  ogTitle,
  ogDescription,
  isLarge,
}: {
  url: string;
  ogImage: string;
  ogTitle: string;
  ogDescription: string;
  isLarge?: boolean;
}) {
  return (
    <div
      className="flex h-full lg:h-auto max-h-full contain-content"
      data-testid="MediaPreviewOpenGraph"
    >
      <OpenGraphInfo
        ogTitle={ogTitle}
        ogDescription={ogDescription}
        ogImage={ogImage}
        ogUrl={url}
        isLarge={isLarge}
      />
    </div>
  );
}

export default MediaPreviewOpenGraph;
