import { combineReducers } from 'redux';
import authenticateForm from './authenticate-form';
import cardDuplicateForm from './card-duplicate-form';
import cardModalForm from './card-modal-form';
import consent from './consent';
import headerForm from './header-form';
import communityProjects from './load-community-projects';
import projectAccessForm from './project-access-form';
import projectCreateForm from './project-create-form';
import projectDuplicateForm from './project-duplicate-form';
import projectFilterForm from './project-filter-form';
import projectImportForm from './project-import-form';
import projectPasswordForm from './project-password-form';
import projectSettingsForm from './project-settings-form';
import projectTrashPage from './project-trash-page';
import searchForm from './search-form';
import userCreateForm from './user-create-form';

export default combineReducers({
  authenticateForm,
  cardDuplicateForm,
  cardModalForm,
  communityProjects,
  headerForm,
  projectAccessForm,
  projectCreateForm,
  projectDuplicateForm,
  projectFilterForm,
  projectImportForm,
  projectPasswordForm,
  projectSettingsForm,
  searchForm,
  userCreateForm,
  projectTrashPage,
  consent,
});
