import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';
import styles from './Header.module.scss';
import ButtonType from '../../constants/ButtonType';
import * as Paths from '../../constants/Paths';
import HeaderSearchContainer from '../../containers/HeaderSearchContainer';
import { usePopup } from '../../lib/popup';
import AccountCircle from '../AccountCircle/AccountCircle';
import BookmarkButton from '../BookmarkButton/BookmarkButton';
import Button from '../Button/Button';
import HeaderLogo from '../HeaderLogo/HeaderLogo.js';
import UserStep from '../UserStep';

const POPUP_PROPS = {
  position: 'bottom right',
};

function HeaderDesktop({
  onLogout,
  openAbout,
  addBookmark,
  removeBookmark,
  isLoggedIn,
  onAuthenticateUsingOidc,
  canEdit,
  showScrollChange,
  toggleScrollMode,
  boardScrollFull,
  handleDelete,
  canDelete,
  project = undefined,
  user = undefined,
  board = undefined,
  showBackButton = false,
  showSearch = false,
  showBookmarks = true,
  isPasswordProtected = false,
}: InferProps<typeof HeaderDesktop.propTypes>) {
  // @ts-ignore
  const UserPopup = usePopup(UserStep, POPUP_PROPS, 'w-[220px]');
  const [t] = useTranslation();

  return (
    <div className={classNames(styles.wrapper, 'bg-white h-20 px-6 contain-strict hidden')}>
      {showBackButton && (
        <Link to={Paths.root()} className={classNames('flex items-center mr-4 text-blue70')}>
          <div className="flex gap-3 border border-solid border-blue10 py-2 px-4 h-12 items-center">
            <img src="/assets/images/icon-chevron-left.svg" alt="" className="h-5" />
            <span>{t('common.back')}</span>
          </div>
        </Link>
      )}

      <HeaderLogo />
      {/* @ts-ignore */}
      {showSearch && <HeaderSearchContainer />}
      <Menu size="large" className={classNames(styles.menu, 'h-full')}>
        <Menu.Menu position="right">
          {board && project && (
            <>
              {showScrollChange && (
                <Menu.Item
                  className={classNames(styles.item, styles.itemHoverable)}
                  title={t('common.showScrollChange')}
                  onClick={toggleScrollMode}
                >
                  <div className="w-7 h-7">
                    <img
                      src={
                        boardScrollFull
                          ? '/assets/images/icon-scroll-all.svg'
                          : '/assets/images/icon-scroll-in-column.svg'
                      }
                      alt=""
                      className="w-full h-full"
                    />
                  </div>
                </Menu.Item>
              )}
              <Menu.Item
                target="_blank"
                className={classNames(styles.item, styles.itemHoverable)}
                as={Link}
                to={Paths.slideShow(project.name, board.id)}
                title={t('common.presentTooltip')}
              >
                <div className="w-7 h-7">
                  <img src="/assets/images/icon-present.svg" alt="" className="w-full h-full" />
                </div>
              </Menu.Item>

              {isLoggedIn && (
                <>
                  {(canEdit || !isPasswordProtected) && (
                    <Menu.Item
                      className={classNames(styles.item, styles.itemHoverable)}
                      as={Link}
                      to={Paths.duplicate(board.id)}
                      title={t('common.duplicateBoard')}
                    >
                      <div className="w-7 h-7">
                        <img
                          src="/assets/images/icon-duplicate.svg"
                          alt=""
                          className="w-full h-full"
                        />
                      </div>
                    </Menu.Item>
                  )}
                  {showBookmarks && (
                    <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                      <div className="w-7 h-7 flex items-center justify-center relative">
                        <BookmarkButton
                          favorite={project.favorite}
                          addBookmark={addBookmark}
                          removeBookmark={removeBookmark}
                          id={project.id}
                        />
                      </div>
                    </Menu.Item>
                  )}

                  {canEdit && (
                    <Menu.Item
                      className={classNames(styles.item, styles.itemHoverable)}
                      as={Link}
                      to={Paths.boardAccess(board.id)}
                      title={t('common.changePermissionTooltip')}
                    >
                      <div className="w-7 h-7">
                        <img src="/assets/images/icon-lock.svg" alt="" className="w-full h-full" />
                      </div>
                    </Menu.Item>
                  )}
                </>
              )}
            </>
          )}
          {isLoggedIn ? (
            <UserPopup
              onLogout={onLogout}
              openAbout={openAbout}
              isLoggedIn={isLoggedIn}
              addBookmark={addBookmark}
              board={board}
              onAuthenticateUsingOidc={onAuthenticateUsingOidc}
              project={project}
              removeBookmark={removeBookmark}
              canEdit={canEdit}
              isMobile={false}
              user={user}
              showScrollChange={showScrollChange}
              toggleScrollMode={toggleScrollMode}
              boardScrollFull={boardScrollFull}
              handleDelete={handleDelete}
              canDelete={canDelete}
              isPasswordProtected={isPasswordProtected}
            >
              <Menu.Item className={classNames(styles.item, styles.itemHoverable)}>
                <AccountCircle />
              </Menu.Item>
            </UserPopup>
          ) : (
            <div className="flex items-center">
              <Button variant={ButtonType.Blue} onClick={onAuthenticateUsingOidc}>
                {t('common.login')}
              </Button>
            </div>
          )}
        </Menu.Menu>
      </Menu>
    </div>
  );
}

HeaderDesktop.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  project: PropTypes.shape({
    name: PropTypes.string,
    favorite: PropTypes.bool,
    id: PropTypes.string,
  }),
  board: PropTypes.shape({ id: PropTypes.string }),
  /* eslint-enable react/forbid-prop-types */
  showSearch: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  onLogout: PropTypes.func.isRequired,
  showBackButton: PropTypes.bool,
  showBookmarks: PropTypes.bool,
  canDelete: PropTypes.bool.isRequired,
  openAbout: PropTypes.func.isRequired,
  addBookmark: PropTypes.func,
  removeBookmark: PropTypes.func,
  onAuthenticateUsingOidc: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.any,
  canEdit: PropTypes.bool.isRequired,
  showScrollChange: PropTypes.bool.isRequired,
  toggleScrollMode: PropTypes.func.isRequired,
  handleDelete: PropTypes.func,
  boardScrollFull: PropTypes.bool.isRequired,
  isPasswordProtected: PropTypes.bool.isRequired,
};

export default React.memo(HeaderDesktop);
