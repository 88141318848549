import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';
import { goToBoard } from '../router';

export function* createProject(data) {
  yield put(actions.createProject(data));

  let project;
  let projectManagers;
  let projectProfessions;
  let board;
  let boardMemberships;

  try {
    ({
      item: project,
      included: { projectManagers, projectProfessions, board, boardMemberships },
    } = yield call(request, api.createProject, data));
  } catch (error) {
    yield put(actions.createProject.failure(error));
    return;
  }

  yield put(actions.createProject.success(project, projectManagers, projectProfessions));

  yield put(actions.createBoard.success(null, board, boardMemberships));

  yield goToBoard(board.id, project.name);
}
