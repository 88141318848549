import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../components/Modal/Modal';

const mapStateToProps = (state) => {
  return {
    isMobile: state.root.isMobile,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
