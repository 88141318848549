/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useTranslation } from 'react-i18next';
import InputCheckbox from '../../lib/custom-ui/components/Input/InputCheckbox';

function FormBoardPassword({
  data,
  handleFieldChange,
}: {
  data: {
    isPublic: boolean;
    hasPassword: boolean;
    plainTextPassword: string;
  };
  handleFieldChange: (...args: any[]) => void;
}) {
  const [t] = useTranslation();

  const handleHasPasswordChange = (event: any) => {
    handleFieldChange(null, {
      name: 'hasPassword',
      value: event.target.checked,
    });
    // also reset password if unchecked
    handleFieldChange(null, {
      name: 'plainTextPassword',
      value: null,
    });
  };

  const handlePasswordChange = (event: any) => {
    // if the event.target.value is empty string, set it to null
    handleFieldChange(null, {
      name: 'plainTextPassword',
      value: event.target.value || null,
    });
  };

  return (
    <>
      <label className="flex gap-1">
        <InputCheckbox
          checked={data.hasPassword}
          aria-label=""
          data-testid="hasPassword"
          onChange={handleHasPasswordChange}
        />
        <span>{t('common.boardIsPasswordProtected')}</span>
      </label>

      {data.hasPassword && (
        <>
          <div>{t('common.password')}</div>
          <input
            type="text"
            value={data.plainTextPassword}
            data-testid="password"
            onChange={handlePasswordChange}
          />
        </>
      )}
    </>
  );
}

export default React.memo(FormBoardPassword);
