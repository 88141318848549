import React, { useMemo } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import CardSlide from './CardSlide';
import ListHeaderSlide from './ListHeaderSlide';
import SlideshowCarousel from './SlideshowCarousel';
import TitleSlide from './TitleSlide';
import { getBoardPath } from '../../constants/Paths';
import DefaultMediaPreviewContainer from '../../containers/MediaPreviewContainer';
import BoardAccessDenied from '../BoardAccessDenied/BoardAccessDenied.js';
import BoardPasswordPage from '../BoardPasswordPage/BoardPasswordPage';
import BoardScheduledForDeletion from '../BoardScheduledForDeletion/BoardScheduledForDeletion.js';

type listProps = {
  id: string;
  name: string;
};

type ProjectProps = {
  id: string;
  name: string;
  thumbnail?: {
    coverUrl?: string;
  };
};

type SlideProps = {
  name: string;
  description: string;
  coverAttachmentId: string | null;
  embedUrl: string;
  mediaUrl: string;
  ogDescription: string;
  ogImage: string;
  coverUrl?: string;
  ogTitle: string;
};

type SlideShowProps = {
  project: ProjectProps;
  board: any;
  lists: listProps[];
  cardIdsByListId: Map<string, string[]>;
  cardContentByCardId: Map<string, SlideProps>;
  goToSlide: (index: number) => void;
  slideNumber: string;
  isMobile: boolean;
  MediaPreviewContainer?: any;
};

function Slideshow({
  project,
  board,
  lists,
  cardIdsByListId,
  cardContentByCardId,
  goToSlide,
  slideNumber,
  isMobile,
  MediaPreviewContainer = DefaultMediaPreviewContainer,
}: SlideShowProps) {
  const [t] = useTranslation();

  const coverUrl = project?.thumbnail?.coverUrl;
  const wrappedSlideShowElements = useMemo(() => {
    const slideShowElements = [
      <TitleSlide title={project?.name} img={coverUrl} />,
      ...lists.flatMap((list) => {
        const cardIds = cardIdsByListId.get(list.id);
        return [
          <ListHeaderSlide title={list.name} key={list.id} />,
          ...cardIds.map((cardId) => {
            const content = cardContentByCardId.get(cardId);
            return (
              <CardSlide
                key={cardId}
                title={content.name}
                content={content.description}
                embedUrl={content.embedUrl}
                mediaUrl={content.mediaUrl}
                ogDescription={content.ogDescription}
                ogImage={content.ogImage}
                ogTitle={content.ogTitle}
                coverUrl={content.coverUrl}
                isMobile={isMobile}
                MediaPreviewContainer={MediaPreviewContainer}
              />
            );
          }),
        ];
      }),
    ];

    return slideShowElements.map((element) => (
      <div
        key={element.key}
        className="h-screen w-full flex flex-col relative box-border px-6 py-12 bg-bgGray"
      >
        {element}
      </div>
    ));
  }, [
    cardContentByCardId,
    cardIdsByListId,
    lists,
    project?.name,
    isMobile,
    MediaPreviewContainer,
    coverUrl,
  ]);

  if (board?.isAccessDenied) {
    return <BoardAccessDenied />;
  }
  if (board?.isPasswordRequired) {
    return <BoardPasswordPage />;
  }

  if (board?.isScheduledForDeletion) {
    return <BoardScheduledForDeletion />;
  }

  if (!project) {
    return <Loader active size="big" />;
  }

  const boardUrl = getBoardPath(project.name, board.id);
  return (
    <SlideshowCarousel
      showThumbs={false}
      autoFocus
      autoPlay={false}
      className="bg-blue10"
      statusFormatter={(currentSlide, totalSlides) =>
        `${t('slideshow.slideOf', { currentSlide, totalSlides })}`
      }
      goToSlide={goToSlide}
      slideNumber={slideNumber}
      boardUrl={boardUrl}
      swipeScrollTolerance={50}
      preventMovementUntilSwipeScrollTolerance
    >
      {wrappedSlideShowElements}
    </SlideshowCarousel>
  );
}

export default React.memo(Slideshow);
