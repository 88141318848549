import React, { useCallback } from 'react';
import Flags from '../../constants/Flags.js';
import Paths from '../../constants/Paths';
import ModalContainer from '../../containers/ModalContainer';
import { useForm } from '../../hooks';
import BoardAccessModalContent from '../BoardAccessModalContent/BoardAccessModalContent';

const showBoardPassword = Flags.BOARD_PASSWORD;

function BoardAccessModal({
  defaultData,
  isSubmitting,
  onUpdate,
  onClose,
  boardId,
  error,
}: {
  defaultData: object;
  isSubmitting: boolean;
  onUpdate: (data: any, redirectTo?: string) => void;
  onClose: () => void;
  boardId: string;
  error?: any;
}) {
  const [data, handleFieldChange] = useForm(() => ({
    ...defaultData,
  }));

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      isPublic: Boolean(data.isPublic || data.plainTextPassword),
      plainTextPassword: data.plainTextPassword ? data.plainTextPassword : null,
    };
    const redirectTo = Paths.BOARDS.replace(':id', boardId);
    onUpdate(cleanData, redirectTo);
  }, [onUpdate, data, boardId]);

  return (
    <ModalContainer open basic size="tiny" onClose={onClose}>
      <BoardAccessModalContent
        data={data}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handleFieldChange={handleFieldChange}
        error={error}
        showBoardPassword={showBoardPassword}
      />
    </ModalContainer>
  );
}

export default React.memo(BoardAccessModal);
