import ActionTypes from '../../constants/ActionTypes';

export const importProjectCancel = (data) => ({
  type: ActionTypes.PROJECT_IMPORT_CANCEL,
  payload: {
    data,
  },
});

importProjectCancel.success = () => ({
  type: ActionTypes.PROJECT_IMPORT_CANCEL__SUCCESS,
  payload: {},
});

importProjectCancel.failure = (error) => ({
  type: ActionTypes.PROJECT_IMPORT_CANCEL__FAILURE,
  payload: {
    error,
  },
});
