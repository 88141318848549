import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import usePopup from '../../lib/popup/use-popup';
import type { Profession } from '../../types/Profession';
import BoardInfoActionsStep from '../BoardInfoActionsStep/BoardInfoActionsStep';
import TruncatedProfessions from '../TruncatedProfessions/TruncatedProfessions';

function BoardInfo({
  image,
  title,
  subtitle,
  professions,
  editable,
  handleSettingsOpen,
  handleDelete,
  canDelete,
}: {
  image: string;
  title: string;
  subtitle: string;
  professions: readonly Profession[];
  editable: boolean;
  handleSettingsOpen: () => void;
  handleDelete: () => void;
  canDelete: boolean;
}) {
  const [t] = useTranslation();
  const BoardInfoActionsPopup = usePopup(BoardInfoActionsStep);
  const professionNames = professions.map((profession) => profession.name);
  return (
    <div className="text-blue100 bg-white p-4 flex overflow-hidden items-center gap-4 w-full rounded-sm">
      {image && (
        <div className={classNames('h-16 aspect-video shrink-0 rounded-sm')}>
          <img src={image} alt="" className="max-w-full max-h-full" />
        </div>
      )}
      <div className="flex flex-col shrink overflow-hidden">
        <TruncatedProfessions professionNames={professionNames} />
        <h1 className="text-2xl m-0 text-ellipsis">{title}</h1>
        <div className="text-blue30">{subtitle}</div>
      </div>
      {editable && (
        <div className="flex ml-auto">
          <BoardInfoActionsPopup
            onSettingsOpen={handleSettingsOpen}
            handleDelete={handleDelete}
            canDelete={canDelete}
          >
            <button
              aria-label={t('common.editBoard_title')}
              type="button"
              className="bg-transparent outline-none border-none w-7 h-7 overflow-hidden hover:bg-gray p-1 cursor-pointer"
            >
              <img src="/assets/images/icon-dots-blue.svg" alt="" className="w-5 h-5" />
            </button>
          </BoardInfoActionsPopup>
        </div>
      )}
    </div>
  );
}

export default React.memo(BoardInfo);
