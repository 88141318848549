import ActionTypes from '../../constants/ActionTypes';

const initialState = {
  searchExpanded: false,
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.HEADER_SEARCH_COLLAPSE:
      return {
        ...state,
        searchExpanded: false,
      };
    case ActionTypes.HEADER_SEARCH_EXPAND:
      return {
        ...state,
        searchExpanded: true,
      };
    default:
      return state;
  }
};
