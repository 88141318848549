import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* updateProjectThumbnail(id, data) {
  yield put(actions.updateProjectThumbnail(id));
  let project;
  try {
    ({ item: project } = yield call(request, api.updateProjectThumbnail, id, data));
  } catch (error) {
    yield put(actions.updateProjectThumbnail.failure(id, error));
    return;
  }
  yield put(actions.updateProjectThumbnail.success(project));
}
