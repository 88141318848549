export const boardColors = [
  {
    name: 'lightgray',
    value: 'rgb(239 242 245)',
    addButtonBackground: 'transparent',
  },
  {
    name: 'darkgray',
    value: '#737577',
    addButtonBackground: 'white',
  },
];
