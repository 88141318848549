import Config from '../constants/Config';

type HttpMethods = 'get' | 'head' | 'post' | 'put' | 'delete' | 'connect' | 'options' | 'trace';
type HttpHandler = (
  url: string,
  data: Record<string, any>,
  headers: Record<string, string>,
) => Promise<any>;
const http = {} as Record<HttpMethods, HttpHandler>;

const methods = [
  'get',
  'head',
  'post',
  'put',
  'delete',
  'connect',
  'options',
  'trace',
] as HttpMethods[];
methods.forEach((method) => {
  http[method] = async (url, data, headers) => {
    const formData =
      data &&
      Object.keys(data).reduce((result, key) => {
        result.append(key, data[key]);

        return result;
      }, new FormData());

    const absoluteUrl = `${Config.SERVER_BASE_URL}/api${url}`;
    const response = await fetch(absoluteUrl, {
      method: method.toUpperCase(),
      headers,
      body: formData,
    });
    let body;
    const clone = response.clone();
    try {
      body = await response.json();
    } catch {
      body = await clone.text();
    }
    const isError = response.status !== 200;
    if (isError) {
      if (typeof body === 'string') {
        throw new Error(body);
      } else {
        throw body;
      }
    }
    return body;
  };
});

export default http;
