import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import styles from './Board.module.scss';
import ListAdd from './ListAdd';
import DroppableTypes from '../../constants/DroppableTypes';
import Flags from '../../constants/Flags';
import DefaultListContainer from '../../containers/ListContainer';
import { closePopup } from '../../lib/popup';
import AddAnotherList from '../AddAnotherList/AddAnotherList';
import Modals from '../Modals/Modals.js';

const parseDndId = (dndId) => dndId.split(':')[1];

function Board({
  listIds,
  modals,
  canEdit,
  onListCreate,
  onListMove,
  onCardMove,
  ListContainer = DefaultListContainer,
  isMobile,
  theme,
  boardScrollFull,
}: {
  listIds: any[];
  modals: any[];
  canEdit: boolean;
  onListCreate: any;
  onListMove: any;
  onCardMove: any;
  ListContainer?: any;
  isMobile: boolean;
  theme: any;
  boardScrollFull: boolean;
}) {
  const [isListAddOpened, setIsListAddOpened] = useState(false);

  const wrapper = useRef(null);
  const prevPosition = useRef(null);

  const handleAddListClick = useCallback(() => {
    setIsListAddOpened(true);
  }, []);

  const handleAddListClose = useCallback(() => {
    setIsListAddOpened(false);
  }, []);

  const handleDragStart = useCallback(() => {
    closePopup();
  }, []);

  const handleDragEnd = useCallback(
    ({ draggableId, type, source, destination }) => {
      if (
        !destination ||
        (source.droppableId === destination.droppableId && source.index === destination.index)
      ) {
        return;
      }

      const id = parseDndId(draggableId);

      switch (type) {
        case DroppableTypes.LIST:
          onListMove(id, destination.index);

          break;
        case DroppableTypes.CARD:
          onCardMove(id, parseDndId(destination.droppableId), destination.index);

          break;
        default:
      }
    },
    [onListMove, onCardMove],
  );

  const handleMouseDown = useCallback(
    (event) => {
      // If button is defined and not equal to 0 (left click)
      if (event.button) {
        return;
      }

      if (event.target !== wrapper.current && !event.target.dataset.dragScroller) {
        return;
      }

      prevPosition.current = event.clientX;
    },
    [wrapper],
  );

  const handleWindowMouseMove = useCallback(
    (event) => {
      if (!prevPosition.current) {
        return;
      }

      event.preventDefault();

      window.scrollBy({
        left: prevPosition.current - event.clientX,
      });

      prevPosition.current = event.clientX;
    },
    [prevPosition],
  );

  const handleWindowMouseUp = useCallback(() => {
    prevPosition.current = null;
  }, [prevPosition]);

  useEffect(() => {
    document.body.style.overflowX = 'auto';

    return () => {
      document.body.style.overflowX = null;
    };
  }, []);

  useEffect(() => {
    if (isListAddOpened) {
      window.scroll(document.body.scrollWidth, 0);
    }
  }, [listIds, isListAddOpened]);

  useEffect(() => {
    window.addEventListener('mouseup', handleWindowMouseUp);
    window.addEventListener('mousemove', handleWindowMouseMove);

    return () => {
      window.removeEventListener('mouseup', handleWindowMouseUp);
      window.removeEventListener('mousemove', handleWindowMouseMove);
    };
  }, [handleWindowMouseUp, handleWindowMouseMove]);

  const hasModal = modals.length > 0;
  const showBackground = !(isMobile && hasModal);

  return (
    <>
      {showBackground && (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          ref={wrapper}
          className={classNames(
            'px-3 flex grow overflow-x-auto max-w-full mt-3 md:mt-0',
            !Flags.BOARD_LAYOUT_LEFT && 'md:mx-auto',
            !boardScrollFull && 'overflow-y-hidden',
          )}
          onMouseDown={handleMouseDown}
          data-test-id="Board"
        >
          <div>
            <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
              <Droppable droppableId="board" type={DroppableTypes.LIST} direction="horizontal">
                {({ innerRef, droppableProps, placeholder }) => (
                  <div
                    {...droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                    data-drag-scroller
                    ref={innerRef}
                    className={styles.lists}
                  >
                    {listIds.map((listId, index) => (
                      <ListContainer
                        key={listId}
                        id={listId}
                        index={index}
                        scrollable={!boardScrollFull}
                      />
                    ))}
                    {placeholder}
                    {canEdit && (
                      <div data-drag-scroller className={styles.list}>
                        {isListAddOpened ? (
                          // @ts-ignore
                          <ListAdd onCreate={onListCreate} onClose={handleAddListClose} />
                        ) : (
                          <AddAnotherList
                            listIds={listIds}
                            handleAddListClick={handleAddListClick}
                            background={theme.addButtonBackground}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}

      <Modals modals={modals} />
    </>
  );
}

export default React.memo(Board);
