/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import './Markdown.scss'; // FIXME: import as styles?

const ABSOLUTE_URL_REGEX = /^(?:https?:)?\/\//i;

function flatten(text, child) {
  return typeof child === 'string'
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

function HeadingRenderer(props) {
  const children = React.Children.toArray(props.children);
  const text = children.reduce(flatten, '');
  const slug = text.toLowerCase().replace(/\W/g, '-');
  return React.createElement(`h${props.level}`, { id: slug }, props.children);
}

function Markdown({ linkStopPropagation = false, className = '', children, ...props }) {
  const handleLinkClick = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const linkRenderer = useCallback(
    ({ node, ...linkProps }) => (
      /* eslint-disable-next-line jsx-a11y/anchor-has-content,
                                  jsx-a11y/click-events-have-key-events,
                                  jsx-a11y/no-static-element-interactions */
      <a
        {...linkProps} // eslint-disable-line react/jsx-props-no-spreading
        rel={
          ABSOLUTE_URL_REGEX.test(linkProps.href) && linkProps.target === '_blank'
            ? 'noreferrer'
            : undefined
        }
        onClick={linkStopPropagation ? handleLinkClick : undefined}
      />
    ),
    [linkStopPropagation, handleLinkClick],
  );

  return (
    <ReactMarkdown
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      components={{
        a: linkRenderer,
        h1: HeadingRenderer,
        h2: HeadingRenderer,
        h3: HeadingRenderer,
        h4: HeadingRenderer,
        h5: HeadingRenderer,
      }}
      remarkPlugins={[remarkGfm, remarkBreaks]}
      className={classNames('markdown-body', className)}
    >
      {children}
    </ReactMarkdown>
  );
}

Markdown.propTypes = {
  linkStopPropagation: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default React.memo(Markdown);
