import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import type { SortOrder } from '../../../../types/SortOrder';
import request from '../../request';

export function* loadCommunityProjects(
  page: number,
  professionIds: string[],
  sortOrder: SortOrder,
) {
  try {
    const {
      items,
      canLoadMoreCommunityProjects,
      included: { projectManagers, boards, boardMemberships },
    } = yield call(request, api.getCommunityProjects, {
      page,
      professionIds,
      sortOrder,
    });
    yield put(
      actions.loadCommunityProjects.success(
        items,
        canLoadMoreCommunityProjects,
        boards,
        projectManagers,
        boardMemberships,
        page,
      ),
    );
  } catch (error) {
    yield put(actions.loadCommunityProjects.failure(error));
  }
}
