/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
import Flags from '../../constants/Flags';
import * as MediaPreviewType from '../../constants/MediaPreviewType';
import { getMediaPreviewComponent } from '../../utils/getMediaPreviewComponent';
import { getMediaPreviewType } from '../../utils/getMediaPreviewType';
import { requiresConsent } from '../../utils/requiresConsent';
import MediaPreviewConsent from '../MediaPreviewConsent/MediaPreviewConsent';

const shouldShowConsentBox = (
  mediaPreviewType: number,
  allowedMediaPreviewTypes: readonly number[],
) => {
  return (
    Flags.EMBED_CONSENT_POPUP &&
    requiresConsent(mediaPreviewType) &&
    !allowedMediaPreviewTypes.includes(mediaPreviewType) &&
    !allowedMediaPreviewTypes.includes(MediaPreviewType.All)
  );
};

function MediaPreview(props: {
  url: string;
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
  interactive?: boolean;
  heightOfIframe?: number;
  isLarge?: boolean;
  onClick?: () => void;
  mediaLinkUrl?: string;
  allowedMediaPreviewTypes: readonly number[];
  onAllowMediaPreview: (mediaPreviewType: number) => void;
}) {
  const mediaPreviewType = getMediaPreviewType(props.url, props.ogTitle);
  const Component = getMediaPreviewComponent(mediaPreviewType);
  if (shouldShowConsentBox(mediaPreviewType, props.allowedMediaPreviewTypes)) {
    return (
      <div>
        <MediaPreviewConsent
          mediaPreviewType={mediaPreviewType}
          onAllow={props.onAllowMediaPreview}
        />
      </div>
    );
  }
  return (
    <div
      className="flex h-full w-full max-h-full max-w-full items-center"
      data-testid="MediaPreview"
    >
      {/* @ts-ignore */}
      <Component {...props} />
    </div>
  );
}

export default MediaPreview;
