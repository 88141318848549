import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Grid, Header, Message } from 'semantic-ui-react';
import isEmail from 'validator/lib/isEmail';
import styles from './Login.module.scss';
import ButtonType from '../../constants/ButtonType';
import { useForm } from '../../hooks';
import { Input } from '../../lib/custom-ui';
import { useDidUpdate, usePrevious, useToggle } from '../../lib/hooks';
import { isUsername } from '../../utils/validator';
import Button from '../Button/Button';

const createMessage = (error) => {
  if (!error) {
    return error;
  }

  switch (error.message) {
    case 'Invalid email or username':
      return {
        type: 'error',
        content: 'common.invalidEmailOrUsername',
      };
    case 'Invalid password':
      return {
        type: 'error',
        content: 'common.invalidPassword',
      };
    case 'Use single sign-on':
      return {
        type: 'error',
        content: 'common.useSingleSignOn',
      };
    case 'Email already in use':
      return {
        type: 'error',
        content: 'common.emailAlreadyInUse',
      };
    case 'Username already in use':
      return {
        type: 'error',
        content: 'common.usernameAlreadyInUse',
      };
    case 'Failed to fetch':
      return {
        type: 'warning',
        content: 'common.noInternetConnection',
      };
    case 'Network request failed':
      return {
        type: 'warning',
        content: 'common.serverConnectionFailed',
      };
    default:
      return {
        type: 'warning',
        content: 'common.unknownError',
      };
  }
};

const Login = React.memo(
  ({
    defaultData,
    isSubmitting,
    isSubmittingUsingOidc,
    error,
    withOidc,
    onAuthenticate,
    onAuthenticateUsingOidc,
    onRegisterUsingOidc,
    onMessageDismiss,
  }: {
    defaultData: { emailOrUsername?: string; password?: string };
    isSubmitting: boolean;
    isSubmittingUsingOidc: boolean;
    error: any;
    withOidc: boolean;
    onAuthenticate: (data: { emailOrUsername: string; password: string }) => void;
    onAuthenticateUsingOidc: () => void;
    onRegisterUsingOidc: () => void;
    onMessageDismiss: () => void;
  }) => {
    const [t] = useTranslation();
    const wasSubmitting = usePrevious(isSubmitting);

    const [data, handleFieldChange, setData] = useForm(() => ({
      emailOrUsername: '',
      password: '',
      ...defaultData,
    }));

    const message = useMemo(() => createMessage(error), [error]);
    const [focusPasswordFieldState, focusPasswordField] = useToggle();

    const emailOrUsernameField = useRef(null);
    const passwordField = useRef(null);

    const handleSubmit = useCallback(() => {
      const cleanData = {
        ...data,
        emailOrUsername: data.emailOrUsername.trim(),
      };

      if (!isEmail(cleanData.emailOrUsername) && !isUsername(cleanData.emailOrUsername)) {
        emailOrUsernameField.current.select();
        return;
      }

      if (!cleanData.password) {
        passwordField.current.focus();
        return;
      }

      onAuthenticate(cleanData);
    }, [onAuthenticate, data]);

    useEffect(() => {
      emailOrUsernameField.current.focus();
    }, []);

    useEffect(() => {
      if (wasSubmitting && !isSubmitting && error) {
        switch (error.message) {
          case 'Invalid email or username':
            emailOrUsernameField.current.select();

            break;
          case 'Invalid password':
            setData((prevData) => ({
              ...prevData,
              password: '',
            }));
            focusPasswordField();

            break;
          default:
        }
      }
    }, [isSubmitting, wasSubmitting, error, setData, focusPasswordField]);

    useDidUpdate(() => {
      passwordField.current.focus();
    }, [focusPasswordFieldState]);

    return (
      <div className={classNames(styles.wrapper, styles.fullHeight)}>
        <Grid
          verticalAlign="middle"
          className={classNames(styles.fullHeightPaddingFix, styles.fullWidthPaddingFix)}
        >
          <Grid.Column widescreen={4} largeScreen={5} computer={6} tablet={16} mobile={16}>
            <Grid verticalAlign="middle" className={styles.fullHeightPaddingFix}>
              <Grid.Column>
                <div className={styles.loginWrapper}>
                  <Header
                    as="h1"
                    textAlign="center"
                    content={t('common.logInToPlanka')}
                    className={styles.formTitle}
                  />
                  <div>
                    {message && (
                      <Message
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...{
                          [message.type]: true,
                        }}
                        visible
                        content={t(message.content)}
                        onDismiss={onMessageDismiss}
                      />
                    )}
                    {withOidc && (
                      <>
                        <Button
                          variant={ButtonType.Green}
                          type="button"
                          className="w-full"
                          loading={isSubmittingUsingOidc}
                          disabled={isSubmitting || isSubmittingUsingOidc}
                          onClick={onAuthenticateUsingOidc}
                          ariaLabel={t('action.logInWithSSO')}
                        >
                          {t('action.logInWithSSO')}
                        </Button>
                        <div className="relative flex justify-center items-center text-green100 py-3 before:absolute before:top-1/2 before:left-0 before:right-0 before:h-[1px] before:bg-green100">
                          <span className="relative px-1.5 bg-white">{t('action.or')}</span>
                        </div>
                        <Button
                          variant={ButtonType.OutlineGreen}
                          type="button"
                          className="w-full"
                          loading={isSubmittingUsingOidc}
                          disabled={isSubmitting || isSubmittingUsingOidc}
                          onClick={onRegisterUsingOidc}
                          ariaLabel={t('action.registerWithSSO')}
                        >
                          {t('action.registerWithSSO')}
                        </Button>
                      </>
                    )}
                    <details>
                      <summary className="block w-full !py-3.5 !mt-8 cursor-pointer text-center px-8 font-semibold rounded-sm relative h-14 text-[17px] border-2 text-green100 !border-none">
                        {t('action.logInWithLocalUser')}
                      </summary>
                      <Form onSubmit={handleSubmit}>
                        <div className={styles.inputWrapper}>
                          <label htmlFor="emailOrUsernameField" className={styles.inputLabel}>
                            {t('common.emailOrUsername')}
                          </label>
                          <Input
                            fluid
                            ref={emailOrUsernameField}
                            id="emailOrUsernameField"
                            name="emailOrUsername"
                            value={data.emailOrUsername}
                            readOnly={isSubmitting}
                            className={styles.input}
                            onChange={handleFieldChange}
                            data-testid="emailOrUsernameField"
                          />
                        </div>
                        <div className={styles.inputWrapper}>
                          <label htmlFor="passwordField" className={styles.inputLabel}>
                            {t('common.password')}
                          </label>
                          <Input.Password
                            // @ts-ignore
                            fluid
                            ref={passwordField}
                            id="passwordField"
                            name="password"
                            value={data.password}
                            readOnly={isSubmitting}
                            className={styles.input}
                            onChange={handleFieldChange}
                          />
                        </div>
                        <Button
                          variant={ButtonType.Green}
                          type="submit"
                          className="w-full"
                          loading={isSubmitting}
                          disabled={isSubmitting || isSubmittingUsingOidc}
                          ariaLabel={t('action.logIn')}
                          onClick={() => {
                            // console.log(123)
                          }}
                        >
                          {t('action.logIn')}
                        </Button>
                      </Form>
                    </details>
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            widescreen={12}
            largeScreen={11}
            computer={10}
            only="computer"
            className="h-full"
          >
            <div className="h-full bg-bgGray absolute top-0 left-0 bottom-0 right-0 flex-col">
              <div className={styles.descriptionWrapper}>
                <Header as="h1" content="MIKA-Do" className={styles.descriptionTitle} />
                <Header
                  as="h2"
                  content={t('common.subTitle')}
                  className={styles.descriptionSubtitle}
                />
              </div>
              <div className="flex justify-center h-[80%]">
                <img src="/assets/images/mikado-laptop-wm.svg" alt="Login cover" />
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  },
);

export default Login;
