import React from 'react';
import highlightText from '../../utils/highlightText';

// Highlights text based on custom start/end tokens.
// This is a safer alternative to using dangerouslySetInnerHTML.
function SearchResultHighlighter({
  children = '',
  highlightStartToken = '__start__',
  highlightEndToken = '__end__',
}: {
  children?: string;
  highlightStartToken?: string;
  highlightEndToken?: string;
}) {
  return (
    <>
      {highlightText(children, highlightStartToken, highlightEndToken).map(
        ({ text, highlight }, index) =>
          highlight ? (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index} className="font-bold text-green100">
              {text}
            </span>
          ) : (
            text
          ),
      )}
    </>
  );
}

export default React.memo(SearchResultHighlighter);
