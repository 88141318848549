import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'semantic-ui-react';

function ContextMenuItem({
  children,
  onClick,
  isDelete,
  to,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  isDelete?: boolean;
  to?: string;
}) {
  return (
    <Menu.Item onClick={onClick} as={to ? Link : undefined} to={to}>
      <span
        className={classNames(isDelete ? 'text-orange' : 'text-blue100', 'w-full font-semibold')}
      >
        {children}
      </span>
    </Menu.Item>
  );
}

export default ContextMenuItem;
