import * as LocalStorage from './localStorage';
import { storagePrefix } from '../constants/StoragePrefix.js';

const key = `${storagePrefix}scroll-full-storage`;

export const toggle = () => {
  const current = LocalStorage.getItem(key);
  const newValue = !current;
  LocalStorage.setItem(key, `${newValue || ''}`);
  return newValue;
};

export const get = () => {
  return Boolean(LocalStorage.getItem(key));
};
