import EntryActionTypes, { SlideShowIndexUpdate } from '../constants/EntryActionTypes';

const goToSlide = (index: number): SlideShowIndexUpdate => ({
  type: EntryActionTypes.SLIDESHOW_INDEX_UPDATE,
  payload: {
    index,
  },
});

export default { goToSlide };
