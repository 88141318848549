import { useTranslation } from 'react-i18next';
import { SortOrder, SortOrderOption } from '../../types/SortOrder';
import { getSortOrderLabel } from '../../utils/getSortOrderLabel';
import Select from '../Select/Select';
import SubHeading from '../SubHeading/SubHeading';

const getText = (option: SortOrderOption) => {
  return option.label;
};

const getId = (option: SortOrderOption) => {
  return option.id;
};

function ProjectsSort({
  sortId,
  updateSort,
  sortOrders,
}: {
  sortId: SortOrder;
  updateSort: (sortOrder: SortOrder) => void;
  sortOrders: readonly SortOrder[];
}) {
  const [t] = useTranslation();

  const options = sortOrders.map((sortOrder) => {
    return {
      id: sortOrder,
      label: getSortOrderLabel(t, sortOrder),
    };
  });

  return (
    <div className="flex flex-col max-w-[400px] flex-1">
      <div className="mt-2" />
      <SubHeading text={t('common.sortOrder')} />
      <div className="mt-2" />
      <Select
        options={options}
        onSelect={updateSort}
        getText={getText}
        getId={getId}
        selectedId={sortId}
      />
    </div>
  );
}

export default ProjectsSort;
