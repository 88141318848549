import { call, put, select } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import core from '../../../../locales/de/core.js';
import selectors from '../../../../selectors';
import { simpleConfirm } from '../../../../utils/simpleAlert.js';
import request from '../../request';
import { goToRoot } from '../router';

export function* deleteProjectNoConfirm(id) {
  const { projectId } = yield select(selectors.selectPath);
  if (id === projectId) {
    yield call(goToRoot);
  }

  yield put(actions.deleteProject(id));

  let project;
  try {
    ({ item: project } = yield call(request, api.deleteProject, id));
  } catch (error) {
    yield put(actions.deleteProject.failure(id, error));
    return;
  }

  yield put(actions.deleteProject.success(project));
}

export function* deleteProject(id) {
  // hack
  const message = core.translation.common.areYouSureYouWantToDeleteThisProject;
  const shouldContinue = simpleConfirm(message);
  if (!shouldContinue) {
    return;
  }
  yield call(deleteProjectNoConfirm, id);
}
