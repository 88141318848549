import { Link } from 'react-router-dom';
import { getSafeUrl } from '../../utils/getSafeUrl';

function ExternalLink({
  url,
  children,
  className,
}: {
  url: string;
  children: React.ReactNode;
  className?: string;
}) {
  const safeUrl = getSafeUrl(url);
  if (safeUrl === '#') {
    return (
      <span className={className} data-testid="ExternalLink">
        {children}
      </span>
    );
  }
  return (
    <Link
      to={safeUrl}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      data-testid="ExternalLink"
    >
      {children}
    </Link>
  );
}

export default ExternalLink;
