import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

function ModalCloseButton({ onClick, isLarge }: { onClick: () => void; isLarge?: boolean }) {
  const [t] = useTranslation();
  return (
    <button
      aria-label={t(`common.back`)}
      type="button"
      onClick={onClick}
      className="border-none outline-none relative"
      style={{
        background: 'transparent',
      }}
    >
      <div
        className={classNames(
          'absolute bg-white mt-1.5 ml-1.5 z-[-1]',
          isLarge ? 'w-7 h-7' : 'w-3 h-3',
        )}
      />
      <img
        src="/assets/images/icon-close.svg"
        alt=""
        className={classNames(isLarge ? 'w-7 h-7' : '')}
      />
    </button>
  );
}

export default React.memo(ModalCloseButton);
