import { all, takeEvery } from 'redux-saga/effects';
import ActionTypes from '../../../constants/ActionTypes';
import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

type ActionType = { payload: any; type: string };

export default function* consentWatchers() {
  yield all([
    takeEvery(EntryActionTypes.CONSENT_ALLOW_MEDIA_PREVIEW, ({ payload: { type } }: ActionType) =>
      services.consentAllowMediaPreview(type),
    ),
    takeEvery(ActionTypes.CORE_INITIALIZE, () => services.consentInitialize()),
  ]);
}
