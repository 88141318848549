import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType.js';
import Button from '../Button/Button.js';

function DescriptionEditConfirmModalContent({
  onDiscardAndClose,
  onCancel,
}: {
  onDiscardAndClose: () => void;
  onCancel: () => void;
}) {
  const [t] = useTranslation();

  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6 flex flex-col flex-1">
      <header className="flex items-center gap-2">
        <span className="text-2xl text-blue100 font-bold">
          {t('common.textChangesAreNotSaved')}
        </span>
      </header>

      <div className="mt-2" />

      <p>{t('common.areYouSureYouWantToDiscardChanges')}</p>

      <div className="flex gap-2 mt-3">
        <Button
          type="button"
          variant={ButtonType.Blue}
          dataTestId="discardAndClose"
          onClick={onDiscardAndClose}
        >
          {t('common.discardAndClose')}
        </Button>
        <Button type="button" variant={ButtonType.Green} dataTestId="cancel" onClick={onCancel}>
          {t('common.cancel')}
        </Button>
      </div>
    </div>
  );
}

export default DescriptionEditConfirmModalContent;
