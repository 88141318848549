import { useTranslation } from 'react-i18next';
import { Menu } from 'semantic-ui-react';
import { MenuItemButton } from '../../types/MenuItemType';
import ContextMenuItemIcon from '../ContextMenuItemIcon/ContextMenuItemIcon';

function ContextMenuItemButton({ translationKey, onClick, icon }: MenuItemButton) {
  const [t] = useTranslation();
  return (
    <Menu.Item onClick={onClick} data-testid="ContextMenuItemButton">
      <div className="flex gap-2 items-center">
        {icon && <ContextMenuItemIcon icon={icon} />}
        <span className="text-blue100 w-full font-semibold">{t(translationKey)}</span>
      </div>
    </Menu.Item>
  );
}

export default ContextMenuItemButton;
