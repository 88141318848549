import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';
import styles from './DescriptionEdit.module.scss';
import ButtonType from '../../constants/ButtonType.ts';
import Button from '../Button/Button';
import ContentEditor from '../ContentEditor/ContentEditor';

const DescriptionEdit = React.forwardRef(
  (
    {
      children,
      defaultValue = undefined,
      onUpdate,
      initialForceOpen,
      descriptionEditOpen,
      openDescriptionEdit,
      closeDescriptionEdit,
    },
    ref,
  ) => {
    const [t] = useTranslation();
    const [value, setValue] = useState(null);

    useEffect(() => {
      if (initialForceOpen) {
        openDescriptionEdit();
      }
    }, [initialForceOpen, openDescriptionEdit]);

    const open = useCallback(() => {
      openDescriptionEdit();
      setValue(defaultValue || '');
    }, [defaultValue, setValue, openDescriptionEdit]);

    const close = useCallback(() => {
      const cleanValue = value ? value.trim() : null;

      if (cleanValue !== defaultValue) {
        onUpdate(cleanValue);
      }

      closeDescriptionEdit();
      setValue(null);
    }, [defaultValue, onUpdate, value, setValue, closeDescriptionEdit]);

    useImperativeHandle(
      ref,
      () => ({
        open,
        close,
      }),
      [open, close],
    );

    const handleChildrenClick = useCallback(() => {
      if (!getSelection().toString()) {
        open();
      }
    }, [open]);

    const handleFieldKeyDown = useCallback(
      (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
          close();
        }
      },
      [close],
    );

    const handleSubmit = useCallback(() => {
      close();
    }, [close]);

    if (!descriptionEditOpen) {
      return React.cloneElement(children, {
        onClick: handleChildrenClick,
      });
    }

    return (
      <Form onSubmit={handleSubmit}>
        <ContentEditor
          value={value}
          placeholder={t('common.enterDescription')}
          className={styles.field}
          onKeyDown={handleFieldKeyDown}
          onChange={setValue}
          initialForceOpen={initialForceOpen}
        />
        <div className={styles.controls}>
          <Button variant={ButtonType.Green}>{t('action.save')}</Button>
        </div>
      </Form>
    );
  },
);

DescriptionEdit.propTypes = {
  children: PropTypes.element.isRequired,
  defaultValue: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  initialForceOpen: PropTypes.number,
  descriptionEditOpen: PropTypes.bool.isRequired,
  openDescriptionEdit: PropTypes.func.isRequired,
  closeDescriptionEdit: PropTypes.func.isRequired,
};

export default React.memo(DescriptionEdit);
