import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* deleteBookmark(projectId) {
  let bookmark;
  try {
    bookmark = yield call(request, api.deleteBookmark, projectId);
  } catch (error) {
    yield put(actions.deleteBookmark.failure(error));
    return;
  }
  yield put(actions.deleteBookmark.success(bookmark));
}
