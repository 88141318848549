import ModalCloseButton from '../ModalCloseButton/ModalCloseButton.js';

function ModalMobile({ children, onClose }: { children: React.ReactNode; onClose?: () => void }) {
  return (
    <div className="w-full h-full contain-content">
      <div className="w-full h-full contain-content overflow-y-auto no-scrollbar">{children}</div>

      <div className="absolute top-2 right-2">
        <ModalCloseButton onClick={onClose} isLarge />
      </div>
    </div>
  );
}

export default ModalMobile;
