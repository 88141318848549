/* eslint-disable no-restricted-syntax */
import { call, put } from 'redux-saga/effects';
import actions from '../../../actions';
import * as MediaPreviewType from '../../../constants/MediaPreviewType';
import * as MediaPreviewConsentStorage from '../../../utils/mediaPreviewConsentStorage';

export function* consentAllowMediaPreview(type: number) {
  yield call(MediaPreviewConsentStorage.allow, type);
  yield put(actions.consentMediaPreviewAllow(type));
}

export function* consentInitialize() {
  const mediaPreviewTypes = Object.values(MediaPreviewType);
  for (const mediaPreviewType of mediaPreviewTypes) {
    const allowed = yield call(MediaPreviewConsentStorage.get, mediaPreviewType);
    if (allowed) {
      yield put(actions.consentMediaPreviewAllow(mediaPreviewType));
    }
  }
}
