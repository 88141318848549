import MediaPreviewFile from '../MediaPreviewFile/MediaPreviewFile.js';
import MediaPreviewImage from '../MediaPreviewImage/MediaPreviewImage.js';

function MediaPreviewPdf({ url, ogImage }: { url: string; ogImage?: string }) {
  if (ogImage) {
    return <MediaPreviewImage url={ogImage} mediaLinkUrl={url} />;
  }
  return <MediaPreviewFile url={url} icon="/assets/images/icon-pdf.svg" iconSize={20} />;
}

export default MediaPreviewPdf;
