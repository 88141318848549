import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TrashActionsStep from './TrashActionsStep';
import Paths from '../../constants/Paths';
import { usePopup } from '../../lib/popup';
import type { IProjectListItem } from '../../types/IProjectListItem';
import { getProjectThumbnailUrl } from '../../utils/getProjectThumbnailUrl';
import BookmarkButton from '../BookmarkButton/BookmarkButton';

function ProjectListItem({
  item,
  showBookmark,
  addBookmark,
  removeBookmark,
  menuItems,
}: {
  item: IProjectListItem;
  showBookmark?: boolean;
  addBookmark?: (projectId: string) => void;
  removeBookmark?: (projectId: string) => void;
  menuItems?: {
    label: string;
    action: (projectId: string) => void;
  }[];
}) {
  const [t] = useTranslation();
  const TrashActionStep = usePopup(TrashActionsStep);

  const thumbnail = getProjectThumbnailUrl(item);

  return (
    <div key={item.id} className="relative" title={item.name}>
      <Link
        to={
          item.firstBoardId
            ? Paths.BOARDS.replace(':id', `${kebabCase(item.name)}-${item.firstBoardId}`)
            : Paths.PROJECTS.replace(':id', item.id)
        }
      >
        <div className="bg-white p-2 hover:shadow-md transform hover:-translate-y-0.5 relative">
          {showBookmark && (
            <BookmarkButton
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              id={item.id}
              favorite={item.favorite}
              className="absolute top-2 right-2"
            />
          )}
          {thumbnail ? (
            <img
              src={thumbnail}
              alt=""
              className="h-full aspect-video w-full overflow-hidden object-cover"
            />
          ) : (
            <div className="bg-bgGray w-full aspect-video flex items-center">
              <img src="/assets/images/logo-gray.svg" alt="" className="mx-auto" />
            </div>
          )}
          <div className="flex items-center">
            <div className="text-blue70 font-sans text-normal font-semibold pt-4 pb-2 truncate flex-grow">
              {item.name}
            </div>
            {menuItems && (
              <div className="h-full">
                <TrashActionStep projectId={item.id} menuItems={menuItems}>
                  <button
                    aria-label={t('action.projectTrashSettings')}
                    type="button"
                    className="bg-transparent outline-none border-none w-7 h-7 overflow-hidden hover:bg-gray p-1 cursor-pointer"
                  >
                    <img src="/assets/images/icon-dots-blue.svg" alt="" className="w-5 h-5" />
                  </button>
                </TrashActionStep>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectListItem;
