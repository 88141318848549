import ActionTypes from '../../constants/ActionTypes';

export const importProjectFromPadlet = (data) => ({
  type: ActionTypes.PROJECT_IMPORT,
  payload: {
    data,
  },
});

importProjectFromPadlet.success = (
  project,
  boards,
  lists,
  cards,
  projectProfessions,
  boardMemberships,
  projectManagers,
) => ({
  type: ActionTypes.PROJECT_IMPORT__SUCCESS,
  payload: {
    project,
    boards,
    lists,
    cards,
    projectProfessions,
    boardMemberships,
    projectManagers,
  },
});

importProjectFromPadlet.failure = (error) => ({
  type: ActionTypes.PROJECT_IMPORT__FAILURE,
  payload: {
    error,
  },
});
