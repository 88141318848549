import ModalContainer from '../../containers/ModalContainer';
import AboutModalContent from '../AboutModalContent/AboutModalContent.js';

function AboutModal({ onClose }: { onClose: () => void }) {
  return (
    <ModalContainer open basic size="tiny" onClose={onClose}>
      <AboutModalContent />
    </ModalContainer>
  );
}

export default AboutModal;
