import { Menu } from 'semantic-ui-react';
import { usePopup } from '../../lib/popup';
import UserStep from '../UserStep';

function HeaderMenu({
  onLogout,
  openAbout,
  isLoggedIn,
  onAuthenticateUsingOidc,
  board,
  project,
  addBookmark,
  removeBookmark,
  user,
  canEdit,
  isMobile,
  toggleScrollMode,
  showScrollChange,
  boardScrollFull,
  handleDelete,
  canDelete,
  isPasswordProtected,
  position = 'bottom right',
}: {
  isLoggedIn: boolean;
  onLogout: () => void;
  openAbout: () => void;
  onAuthenticateUsingOidc: () => void;
  addBookmark: (projectId: string) => void;
  removeBookmark: (projectId: string) => void;
  board: any;
  project: any;
  user: any;
  canEdit: boolean;
  isMobile: boolean;
  toggleScrollMode: () => void;
  showScrollChange: boolean;
  boardScrollFull: boolean;
  handleDelete: () => void;
  canDelete: boolean;
  isPasswordProtected: boolean;
  position?: string;
}) {
  const popupProps = {
    position,
  };
  // @ts-ignore
  const UserPopup = usePopup(UserStep, popupProps, 'w-[220px]');
  return (
    <UserPopup
      onLogout={onLogout}
      openAbout={openAbout}
      isLoggedIn={isLoggedIn}
      onAuthenticateUsingOidc={onAuthenticateUsingOidc}
      board={board}
      project={project}
      addBookmark={addBookmark}
      removeBookmark={removeBookmark}
      user={user}
      canEdit={canEdit}
      isMobile={isMobile}
      toggleScrollMode={toggleScrollMode}
      showScrollChange={showScrollChange}
      boardScrollFull={boardScrollFull}
      handleDelete={handleDelete}
      canDelete={canDelete}
      isPasswordProtected={isPasswordProtected}
    >
      <Menu.Item>
        <img src="/assets/images/icon-menu.svg" alt="" className="h-6" />
      </Menu.Item>
    </UserPopup>
  );
}

export default HeaderMenu;
