import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Board from '../components/Board';
import ModalTypes from '../constants/ModalTypes.js';
import entryActions from '../entry-actions';
import selectors from '../selectors';

const mapStateToProps = (state) => {
  const { cardId, isBoardAccess, isBoardSettings, isBoardDuplicate, isImport } =
    selectors.selectPath(state);
  const listIds = selectors.selectListIdsForCurrentBoard(state);
  const canEdit = selectors.selectCanEditBoard(state);
  const boardScrollFull = selectors.selectBoardScrollFull(state);
  const { isMobile } = state.root;
  const project = selectors.selectCurrentProject(state);
  const theme = selectors.selectProjectTheme(project);
  const modals = [];
  const isCardModalOpened = !!cardId;
  if (isCardModalOpened) {
    modals.push({
      type: ModalTypes.CARD,
    });
  }
  if (isBoardAccess) {
    modals.push({
      type: ModalTypes.BOARD_ACCESS,
    });
  }
  if (isBoardSettings) {
    modals.push({
      type: ModalTypes.BOARD_SETTINGS,
    });
  }
  if (isBoardDuplicate) {
    modals.push({
      type: ModalTypes.BOARD_DUPLICATE,
    });
  }
  if (isImport) {
    modals.push({
      type: ModalTypes.BOARD_IMPORT,
    });
  }
  return {
    listIds,
    canEdit,
    isMobile,
    theme,
    boardScrollFull,
    modals,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onListCreate: entryActions.createListInCurrentBoard,
      onListMove: entryActions.moveList,
      onCardMove: entryActions.moveCard,
      onDelete: entryActions.scheduleCurrentProjectForDeletion,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Board);
