import { call, put, select } from 'redux-saga/effects';
import { goToBoard, goToProject } from './router';
import actions from '../../../actions';
import api from '../../../api';
import selectors from '../../../selectors';
import * as BoardScrollStorage from '../../../utils/boardScrollStorage';
import { createLocalId } from '../../../utils/local-id';
import { getPlainTextPassword } from '../../../utils/plainTextPassword';
import request from '../request';

export function* createBoard(projectId, projectName, { import: boardImport, ...data }) {
  const nextData = {
    ...data,
    position: yield select(selectors.selectNextBoardPosition, projectId),
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createBoard({
      ...nextData,
      projectId,
      id: localId,
    }),
  );

  let board;
  let boardMemberships;

  try {
    ({
      item: board,
      included: { boardMemberships },
    } = yield boardImport
      ? call(
          request,
          api.createBoardWithImport,
          projectId,
          {
            ...nextData,
            importType: boardImport.type,
            importFile: boardImport.file,
          },
          localId,
        )
      : call(request, api.createBoard, projectId, nextData));
  } catch (error) {
    yield put(actions.createBoard.failure(localId, error));
    return;
  }

  yield put(actions.createBoard.success(localId, board, boardMemberships));
  yield call(goToBoard, board.id, projectName);
}

export function* createBoardInCurrentProject(data) {
  const { projectId } = yield select(selectors.selectPath);

  yield call(createBoard, projectId, data);
}

export function* handleBoardCreate(board, requestId) {
  const isExists = yield select(selectors.selectIsBoardWithIdExists, requestId);

  if (!isExists) {
    yield put(actions.handleBoardCreate(board));
  }
}

export function* fetchBoard(id) {
  yield put(actions.fetchBoard(id));

  let board;
  let users;
  let projects;
  let boardMemberships;
  let labels;
  let lists;
  let cards;
  let cardMemberships;
  let cardLabels;
  let tasks;
  let attachments;
  let projectProfessions;
  const plainTextPassword = getPlainTextPassword(id);

  try {
    ({
      item: board,
      included: {
        users,
        projects,
        boardMemberships,
        labels,
        lists,
        cards,
        cardMemberships,
        cardLabels,
        tasks,
        attachments,
        projectProfessions,
      },
    } = yield call(request, api.getBoard, id, true, plainTextPassword));
  } catch (error) {
    yield put(actions.fetchBoard.failure(id, error));
    return;
  }

  yield put(
    actions.fetchBoard.success(
      board,
      users,
      projects,
      boardMemberships,
      labels,
      lists,
      cards,
      cardMemberships,
      cardLabels,
      tasks,
      attachments,
      projectProfessions,
    ),
  );
}

export function* updateBoard(id, data) {
  yield put(actions.updateBoard(id, data));

  let board;
  try {
    ({ item: board } = yield call(request, api.updateBoard, id, data));
  } catch (error) {
    yield put(actions.updateBoard.failure(id, error));
    return;
  }

  yield put(actions.updateBoard.success(board));
}

export function* handleBoardUpdate(board) {
  yield put(actions.handleBoardUpdate(board));
}

export function* moveBoard(id, index) {
  const { projectId } = yield select(selectors.selectBoardById, id);
  const position = yield select(selectors.selectNextBoardPosition, projectId, index, id);

  yield call(updateBoard, id, {
    position,
  });
}

export function* deleteBoard(id) {
  const { boardId, projectId } = yield select(selectors.selectPath);

  if (id === boardId) {
    yield call(goToProject, projectId);
  }

  yield put(actions.deleteBoard(id));

  let board;
  try {
    ({ item: board } = yield call(request, api.deleteBoard, id));
  } catch (error) {
    yield put(actions.deleteBoard.failure(id, error));
    return;
  }

  yield put(actions.deleteBoard.success(board));
}

export function* handleBoardDelete(board) {
  const { boardId, projectId } = yield select(selectors.selectPath);

  if (board.id === boardId) {
    yield call(goToProject, projectId);
  }

  yield put(actions.handleBoardDelete(board));
}

export function* boardtoggleScrollMode() {
  const newValue = yield call(BoardScrollStorage.toggle);
  yield put(actions.boardScrollSet(newValue));
}
