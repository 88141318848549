import ModalContainer from '../../containers/ModalContainer';
import useForm from '../../hooks/use-form.js';
import CardDuplicateModalContent from '../CardDuplicateModalContent/CardDuplicateModalContent';

function CardDuplicateModal({
  onClose,
  projects,
  currentProject,
  onDuplicateCard,
  error,
  isSubmitting,
  card,
  defaultData,
}: {
  projects: any[];
  currentProject: any;
  onDuplicateCard: (card: any, targetProjectId: string) => void;
  error?: any;
  isSubmitting: boolean;
  onClose: () => void;
  card: any;
  defaultData: {
    targetProjectId: string;
  };
}) {
  const [data, handleFieldChange] = useForm(() => ({
    ...defaultData,
  }));
  const handleSubmit = () => {
    onDuplicateCard(card, data.targetProjectId);
  };
  return (
    <ModalContainer open basic size="tiny" onClose={onClose}>
      <CardDuplicateModalContent
        projects={projects}
        currentProject={currentProject}
        handleSubmit={handleSubmit}
        error={error}
        isSubmitting={isSubmitting}
        handleFieldChange={handleFieldChange}
        data={data}
      />
    </ModalContainer>
  );
}

export default CardDuplicateModal;
