import classNames from 'classnames';
import { camelCase, upperFirst } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { ProjectBackgroundTypes } from '../../constants/Enums';
import globalStyles from '../../styles.module.scss';

function Background({ type = undefined, name = undefined, imageUrl = undefined }) {
  return (
    <div
      className={classNames(
        'h-full fixed w-full z-[-1]',
        type === ProjectBackgroundTypes.GRADIENT
          ? globalStyles[`background${upperFirst(camelCase(name))}`]
          : 'bg-bgGray',
      )}
    >
      {type === 'image' && (
        <div
          className="w-full h-full"
          style={{
            background: `url("${imageUrl}") center / cover`,
          }}
        />
      )}
    </div>
  );
}

Background.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default Background;
