import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { handleLocationChange } from './actions';
import * as NavigationStorage from '../../utils/navigationStorage';

function ReduxRouter({ children, history, selector, basename }) {
  const { router: state, ui } = useSelector(selector);
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const unlisten = history.listen((nextState) => {
      dispatch(handleLocationChange(nextState.location, nextState.action));
    });

    dispatch(handleLocationChange(history.location, history.action, true));

    return unlisten;
  }, [history, dispatch]);

  useEffect(() => {
    NavigationStorage.saveState(state.location, {
      searchForm: ui.searchForm,
      projectFilterForm: ui.projectFilterForm,
    });
  }, [state, ui]);

  return (
    <Router
      basename={basename}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}

ReduxRouter.propTypes = {
  children: PropTypes.element.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  selector: PropTypes.func,
  basename: PropTypes.string,
};

ReduxRouter.defaultProps = {
  selector: ({ router, ui }) => {
    return { router, ui };
  },
  basename: undefined,
};

export default ReduxRouter;
