/* eslint-disable react/require-default-props */
import classNames from 'classnames';

function AddAnother({
  disabled,
  onClick,
  ariaLabel,
  className,
  children,
  background,
}: {
  disabled?: boolean;
  onClick?: () => void;
  ariaLabel?: string;
  className?: string;
  children?: React.ReactNode;
  background?: string;
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={classNames(
        className,
        'flex justify-center items-center border border-solid border-blue10 h-12 relative bg-transparent hover:bg-blue10 text-blue50 hover:text-blue70 font-semibold text-base rounded-sm',
      )}
      onClick={onClick}
      data-test-id="AddAnother"
      aria-label={ariaLabel}
      style={{
        background,
      }}
    >
      {children}
    </button>
  );
}

export default AddAnother;
