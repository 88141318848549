import ActionTypes from '../../constants/ActionTypes';

export const handleProjectCreate = (project, users, projectManagers, boards, boardMemberships) => ({
  type: ActionTypes.PROJECT_CREATE_HANDLE,
  payload: {
    project,
    users,
    projectManagers,
    boards,
    boardMemberships,
  },
});
