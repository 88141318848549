import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MediaPreview from '../components/MediaPreview/MediaPreview';
import entryActions from '../entry-actions/index';

const mapStateToProps = (state) => {
  const {
    ui: {
      consent: { allowedMediaPreviewTypes },
    },
  } = state;
  return {
    allowedMediaPreviewTypes,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClose: entryActions.closeModal,
      onAllowMediaPreview: entryActions.allowMediaPreview,
    },
    dispatch,
  );

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MediaPreview);
