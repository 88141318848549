import React, { useCallback, useRef } from 'react';
import Paths from '../../constants/Paths';
import ModalContainer from '../../containers/ModalContainer';
import { useForm } from '../../hooks/index';
import type { Profession } from '../../types/Profession';
import BoardSettingsModalContent from '../BoardSettingsModalContent/BoardSettingsModalContent';

function BoardSettingsModal({
  defaultData,
  isSubmitting,
  onSave,
  onClose,
  boardId,
  availableProfessions,
  error,
  onThumbnailUpdate,
  onThumbnailDelete,
  thumbnailUrl,
  isThumbnailUpdating,
  canEdit,
  colors,
  mediaPreviewUrl,
  canExport,
  onExport,
}: {
  defaultData: object;
  isSubmitting: boolean;
  onSave: (data: any, redirectTo?: string) => void;
  onClose: () => void;
  onExport: () => void;
  boardId: string;
  availableProfessions: readonly Profession[];
  error: any;
  onThumbnailUpdate: (image: any) => void;
  onThumbnailDelete: () => void;
  thumbnailUrl: string;
  mediaPreviewUrl?: string;
  isThumbnailUpdating: boolean;
  canEdit: boolean;
  canExport: boolean;
  colors: readonly string[];
}) {
  const [data, handleFieldChange] = useForm(() => defaultData);

  const nameField = useRef(null);

  const handleSubmit = useCallback(() => {
    const cleanData = {
      ...data,
      professions: data.professions.map((profession) => profession.id),
    };
    if (!cleanData.name) {
      nameField.current.select();
      return;
    }
    const redirectTo = Paths.BOARDS.replace(':id', boardId);
    onSave(cleanData, redirectTo);
  }, [onSave, data, boardId]);

  return (
    <ModalContainer open basic size="small" onClose={onClose}>
      <BoardSettingsModalContent
        data={data}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handleFieldChange={handleFieldChange}
        availableProfessions={availableProfessions}
        nameField={nameField}
        error={error}
        onThumbnailUpdate={onThumbnailUpdate}
        onThumbnailDelete={onThumbnailDelete}
        thumbnailUrl={thumbnailUrl}
        isThumbnailUpdating={isThumbnailUpdating}
        canEdit={canEdit}
        onExport={onExport}
        colors={colors}
        mediaPreviewUrl={mediaPreviewUrl}
        canExport={canExport}
      />
    </ModalContainer>
  );
}

export default React.memo(BoardSettingsModal);
