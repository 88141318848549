/* eslint-disable no-restricted-syntax */
import { createSelector } from 'redux-orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import { boardColors } from '../constants/BoardColors';
import Flags from '../constants/Flags';
import orm from '../orm';
import { isLocalId } from '../utils/local-id';

export const selectCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  ({ Project }, id) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }
    return projectModel.ref;
  },
);

export const makeSelectProfessionsByProjectId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Project }, id) => {
      const projectModel = Project.withId(id);
      if (!projectModel) {
        return [];
      }
      return projectModel.professions.toRefArray();
    },
  );

export const selectProfessionsByProjectId = makeSelectProfessionsByProjectId();

export const selectManagersForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedManagersQuerySet()
      .toModelArray()
      .map((projectManagerModel) => ({
        ...projectManagerModel.ref,
        isPersisted: !isLocalId(projectManagerModel.id),
        user: {
          ...projectManagerModel.user.ref,
          isCurrent: projectManagerModel.user.id === currentUserId,
        },
      }));
  },
);

export const selectBoardsForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return projectModel;
    }

    return projectModel
      .getOrderedBoardsModelArrayAvailableForUser(currentUserId)
      .map((boardModel) => ({
        ...boardModel.ref,
        isPersisted: !isLocalId(boardModel.id),
      }));
  },
);

export const selectIsCurrentUserManagerForCurrentProject = createSelector(
  orm,
  (state) => selectPath(state).projectId,
  (state) => selectCurrentUserId(state),
  ({ Project }, id, currentUserId) => {
    if (!id) {
      return false;
    }

    const projectModel = Project.withId(id);

    if (!projectModel) {
      return false;
    }

    return projectModel.hasManagerForUser(currentUserId);
  },
);

export const selectProjectTheme = (project) => {
  const defaultTheme = boardColors.find((color) => color.name === 'lightgray');
  if (!Flags.BOARD_BACKGROUND || !project || typeof project.background !== 'string') {
    return defaultTheme;
  }
  const theme = boardColors.find(
    (color) => color.name === project.background || color.value === project.background,
  );
  if (!theme) {
    return defaultTheme;
  }
  return theme;
};
