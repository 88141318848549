export const cardColors = [
  {
    name: 'white',
    value: 'white',
  },
  {
    name: 'blue',
    value: '#aab2ce',
  },
  {
    name: 'green',
    value: '#cfe09b',
  },
];
