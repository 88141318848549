enum ButtonType {
  Green = 1,
  LightGreen = 2,
  OutlineGreen = 3,
  LightOutlineGreen = 4,
  Orange = 5,
  Blue = 6,
  LightBlue = 7,
}

export default ButtonType;
