import ActionTypes from '../../constants/ActionTypes';

export const tryAccess = (boardId) => ({
  type: ActionTypes.PROJECT_TRY_ACCESS_PASSWORD,
  payload: {
    id: boardId,
  },
});

tryAccess.success = (boardId) => ({
  type: ActionTypes.PROJECT_TRY_ACCESS_PASSWORD__SUCCESS,
  payload: {
    id: boardId,
  },
});

tryAccess.failure = (error) => ({
  type: ActionTypes.PROJECT_TRY_ACCESS_PASSWORD__FAILURE,
  payload: {
    error,
  },
});
