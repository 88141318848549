import { connect } from 'react-redux';
import LoginWrapper from '../components/LoginWrapper';

const mapStateToProps = (_state) => {
  return {
    isInitializing: true,
  };
};

export default connect(mapStateToProps)(LoginWrapper);
