import { isWhitespace } from './isWhitespace';

export const isQuillEmptyContents = (value: any) => {
  if (!value) {
    return true;
  }
  if (!value.ops) {
    return true;
  }
  if (!Array.isArray(value.ops)) {
    return true;
  }
  if (
    value.ops.every((op) => {
      return 'insert' in op && isWhitespace(op.insert);
    })
  ) {
    return true;
  }
  return false;
};
