import { all, takeEvery } from 'redux-saga/effects';
import EntryActionTypes from '../../../constants/EntryActionTypes';
import services from '../services';

type ActionType = { payload: any; type: string };

export default function* searchWatchers() {
  yield all([
    takeEvery(EntryActionTypes.SEARCH_VALUE_UPDATE, ({ payload: { searchValue } }: ActionType) =>
      services.searchValueUpdate(searchValue),
    ),
    takeEvery(EntryActionTypes.SEARCH, ({ payload: { searchValue } }: ActionType) =>
      services.search(searchValue),
    ),
    takeEvery(EntryActionTypes.SEARCH_LOAD_MORE, ({ payload: { searchValue, page } }: ActionType) =>
      services.loadMoreSearchResults(searchValue, page),
    ),
    takeEvery(EntryActionTypes.SEARCH_EXPAND, () => services.searchExpand()),
    takeEvery(EntryActionTypes.SEARCH_COLLAPSE, () => services.searchCollapse()),
  ]);
}
