import { matchPath } from 'react-router-dom';
import { removeProjectNameFromId } from './removeProjectNameFromId';

export default (pathname: string, paths: string[]) => {
  for (let i = 0; i < paths.length; i += 1) {
    const match = matchPath(
      {
        path: paths[i],
        end: true,
      },
      pathname,
    );

    if (match) {
      (match.params.id as string) = removeProjectNameFromId(match.params.id);
      return match;
    }
  }

  return null;
};
