import { call, put } from 'redux-saga/effects';
import actions from '../../../../actions';
import api from '../../../../api';
import request from '../../request';

export function* restoreProjectFromTrash(id: string) {
  yield put(actions.restoreProjectFromTrash(id));

  let project;
  try {
    ({ item: project } = yield call(request, api.updateProject, id, {
      scheduledForDeletionAt: null,
    }));
  } catch (error) {
    yield put(actions.restoreProjectFromTrash.failure(id, error));
    return;
  }

  yield put(actions.restoreProjectFromTrash.success(project));
}
