import { merge } from 'lodash-es';
import core from './core';
import login from './login';

export default {
  language: 'de',
  country: 'de',
  name: 'Deutsch',
  embeddedLocale: merge(login, core),
};
