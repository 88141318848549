import React, { LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import ButtonType from '../../constants/ButtonType.js';
import Flags from '../../constants/Flags.js';
import Paths from '../../constants/Paths.js';
import { Input } from '../../lib/custom-ui';
import type { ProjectCreateFormState } from '../../reducers/ui/project-create-form';
import type { Profession } from '../../types/Profession';
import Button from '../Button/Button';
import FormError from '../FormError/FormError';
import NewBoardIsPublicHint from '../NewBoardIsPublicHint/NewBoardIsPublicHint.js';
import ProfessionsComboBox from '../ProfessionsComboBox/ProfessionsComboBox.js';

function ProjectAddModalContent({
  isSubmitting,
  handleSubmit,
  nameField,
  data,
  handleFieldChange,
  availableProfessions,
  error,
}: {
  isSubmitting: boolean;
  handleSubmit: () => void;
  nameField: LegacyRef<Input>;
  data: ProjectCreateFormState['data'];
  handleFieldChange: (event: any, args: any) => void;
  availableProfessions: readonly Profession[];
  error: any;
}) {
  const [t] = useTranslation();

  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <img src="/assets/images/icon-add-document-green.svg" alt="" />
        <span className="text-2xl text-blue100 font-bold">
          {t('common.createBoard', {
            context: 'title',
          })}
        </span>
      </header>
      <p className="mt-4 m-0">{t('common.enterBoardTitle')}</p>
      <Form onSubmit={handleSubmit}>
        <Input
          fluid
          inverted
          ref={nameField}
          name="name"
          value={data.name}
          readOnly={isSubmitting}
          onChange={handleFieldChange}
        />

        <p className="mt-4 m-0">{t('common.professionOrProfessions')}</p>

        <ProfessionsComboBox
          data={data}
          handleFieldChange={handleFieldChange}
          availableProfessions={availableProfessions}
        />

        <FormError error={error} />
        <div className="flex mt-5">
          <div className="bg-blue100 text-white flex items-center">
            <NavLink to={Paths.BOARD_IMPORT}>
              <span className="text-white p-2 items-center flex px-4">
                {t('common.boardImport')}
              </span>
            </NavLink>
          </div>
          <Button
            type="submit"
            disabled={isSubmitting}
            variant={ButtonType.Green}
            className="ml-auto"
          >
            {t('action.createBoard')}
          </Button>
        </div>

        {Flags.NEW_BOARD_IS_PUBLIC_HINT && (
          <div className="mt-4">
            <NewBoardIsPublicHint />
          </div>
        )}
      </Form>
    </div>
  );
}

export default React.memo(ProjectAddModalContent);
