import QuillWrapper from '../QuillWrapper/QuillWrapper.js';

function ContentEditorQuill({
  value,
  onChange,
  initialForceOpen,
}: {
  value: string;
  onChange: (value: string) => void;
  initialForceOpen?: boolean;
}) {
  return <QuillWrapper value={value} onChange={onChange} initialForceOpen={initialForceOpen} />;
}

export default ContentEditorQuill;
